// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-fallback__container--rnAYrtpS_H13MxSZpUyC{padding:18px}.error-fallback__reload--rWi1hfu6cRTVglMALPXk{width:150px;margin:18px 18px 18px 0}", "",{"version":3,"sources":["webpack://./packages/ui-components/src/error-fallback/error-fallback.css"],"names":[],"mappings":"AAAA,iDACE,YACF,CAEA,8CACE,WAAY,CAEZ,uBACF","sourcesContent":[".container {\r\n  padding: 18px;\r\n}\r\n\r\n.reload {\r\n  width: 150px;\r\n  margin: 18px;\r\n  margin-left: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "error-fallback__container--rnAYrtpS_H13MxSZpUyC",
	"reload": "error-fallback__reload--rWi1hfu6cRTVglMALPXk"
};
export default ___CSS_LOADER_EXPORT___;
