import { Logo } from '@ui/logo';
import { Tabs } from '@ui/tabs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { redirectToWebClient } from '@lib/redirect-to-app';
import { usePlatformData } from '@lib/usePlatform';
import { useSession } from '@lib/useSession';
import img from '../../assets/logo.png';
import { LoginContextProvider, useLoginContext } from './login.context';
import { Outlet, useParams } from 'react-router-dom';
import { useAuthNavigation, AuthPage, LoginRoutes, LoginPage, AuthRoute } from '../routes-config';
import { Spinner } from '@ui/spinner';
import styles from './login.css';

export enum LoginTabs {
  UserLogin = 'user-login',
  BankId = 'bank-id',
  Ad = 'ad'
}

interface ILoginRouteProps {
  children: React.ReactNode;
}

function LoginRoute(props: ILoginRouteProps) {
  const { t } = useTranslation();
  const { processing, redirectDestination } = useLoginContext();
  const { session } = useSession();

  const navigate = useAuthNavigation();
  const params = useParams();

  const activeRoute = params['*'] ?? '';

  const { platformData, version, webClientUrl } = usePlatformData();

  useEffect(() => {
    if (!session || !platformData) return;

    redirectToWebClient({
      path: 'overview',
      token: session.Authorization,
      host: webClientUrl,
      version,
      redirectDestination
    });
  }, [session?.Authorization, platformData, version, webClientUrl]);

  const content = (
    <>
      {props.children}
      <Outlet />
    </>
  );

  const tabs = [
    {
      id: '',
      route: AuthPage.Login,
      name: t('login:login'),
      content
    },
    {
      id: LoginPage.BankId,
      route: LoginRoutes.BankId,
      name: t('login:bankId:name'),
      content
    },
    {
      id: LoginPage.ActiveDirectory,
      route: LoginRoutes.ActiveDirectory,
      name: t('login:ad'),
      content
    }
  ];

  let activeContent: JSX.Element | null = null;

  switch (activeRoute) {
    case 'confirm':
    case 'success':
    case 'error':
      activeContent = content;
      break;
    default:
      activeContent = (
        <Tabs
          tabs={tabs}
          disabled={processing}
          activeId={activeRoute}
          onClick={item => navigate(item.route as AuthRoute)}
          fallback={<Outlet />}
        />
      );
      break;
  }

  return (
    <div className={styles.root}>
      <div className={styles.loginWrapper}>
        {processing && <Spinner isUnauthenticated={true} />}
        <div className={styles.logo}>
          <Logo src={img} slogan={t('common:slogan')} />
        </div>
        <div className={styles.content}>{activeContent}</div>
        <div className={styles.footer}>
          <footer>
            {t('copyright:copyright')} ©
            <a href="http://www.tiltid.se">{t('copyright:companyName')}</a>&nbsp;
            {new Date().getFullYear()} {t('copyright:rightsReserved')}
          </footer>
        </div>
      </div>
    </div>
  );
}

export default function LoginRouteWrapper(props: any) {
  return (
    <LoginContextProvider>
      <LoginRoute {...props} />
    </LoginContextProvider>
  );
}
