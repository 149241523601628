export enum State {
  Normal = 'Norm',
  Default = 'Def',
  Hover = 'Hover',
  Focused = 'Focus',
  Pressed = 'Press',
  Disabled = 'Disable',
  DragAndDrop = 'DragDrop',
  DragAndDropInvalid = 'DragDropInv',
  Selected = 'Select',
  ReadOnly = 'ReadOnly',

  // Planning specific
  Mirror = 'Mirror',
  ContrastLight = 'ContrLight',
  ContrastDark = 'ContrDark',
  Vacant = 'Vacant',
  KeyValues = 'KeyVal',
  NoPermission = 'NoPerm',
  ExternalData = 'External',
  Locked = 'Lock',
  Used = 'Used',
  HiddenData = 'Hidden',
  Alternative = 'Alt',
  HoverAlternative = 'HoverAlt',
  Absence = 'Absent',
  Assigned = 'Assign',
  AssignedInvalid = 'AssignInv',
  Popup = 'Popup',
  Completed = 'Compl',
  Saved = 'Saved',
  Unsaved = 'Unsaved',
  Free = 'Free',
  Ongoing = 'Ongoing',
  Overdue = 'Overdue',
  Solid = 'Solid',
  Discrete = 'Discrete',
  Attested = 'Attested',
  DeviationPositive = 'DevPos',
  DeviationNegative = 'DevNeg',
  InvalidStart = 'StartInv',
  InvalidEnd = 'EndInv',
  Required = 'Required',
  Created = 'Created',
  Delivered = 'Deliver',
  Undeliverable = 'Undeliv',
  Signed = 'Signed',
  Revoked = 'Revoked',
  Outdated = 'Outdated',
  PersDragAndDrop = 'PersDragDrop',
  Break = 'Break',
  PartiallySigned = 'PartSigned'
}
