export enum Control {
  Dialog = 'Dlg',
  Button = 'Btn',
  CheckBox = 'ChBox',
  RadioButton = 'RdBtn',
  Edit = 'EdtBox',
  ComboBox = 'CmbBox',
  ComboBoxList = 'CmbBoxList',
  Static = 'StatTxt',
  GroupBox = 'GrpBox',
  TabControl = 'TabCtrl',
  ListBox = 'ListBox',
  ListControl = 'ListCtrl',
  HeaderControl = 'HdrCtrl',
  TreeControl = 'TreeCtrl',
  RichEdit = 'REdtBox',
  RichEditEx = 'REdtBoxEx',
  Splitter = 'Splitter',
  ToolBar = 'ToolBar',
  ScrollBar = 'ScrBar',
  Link = 'Link',
  Calendar = 'Cal',
  CalendarHeader = 'CalHdr',
  CalendarWeekDay = 'CalWDay',
  CalendarWeekNumber = 'CalWNr',
  CalendarDay = 'CalDay',
  CalendarTrailingDay = 'CalTrDay',
  CalendarToday = 'CalToday',
  CalendarFooter = 'CalFtr',
  WheelOfTime = 'WhOfTime',
  SliderControl = 'Slider',
  PlanningView = 'PlanView',
  PlanFragment = 'PlanFrag',
  Alert = 'Alert',
  Attest = 'Attest',
  FixedTask = 'FixdTask',
  NeedForStaff = 'NFSView',
  SalesView = 'SalesView',
  ToDoList = 'ToDoList',
  RegEditor = 'RegEditor',
  TimePunch = 'TimePunch',
  BudgetTable = 'BudgTbl',
  DocumentIcon = 'DocIcon',
  LockIcon = 'LockIcon',
  RegTidControl = 'RegTidControl',
  ThemeToggleButton = 'ThemeToggleButton',
  ExtraTimeControl = 'ExtraTimeControl'
}
