import { IUserInfo } from '../api/customer/user';
import { ICostAccountRight } from '../api/auth/session';
import { FunctionalRight, AuthorizationLevel, SpecialRight } from './userRights';
import moment from 'moment';

const ALL_RIGHTS_ROLE_ID = -1;

export default class UserPrivilegesContainer {
  specialRights: number;
  functionalRights: Set<number>;
  organizationalRights: Map<number, Map<number, AuthorizationLevel>>;
  attestLevels: Set<number>;
  minAttestLevel: number;
  maxAttestLevel: number;
  functionalRoles: Set<number>;
  organizationalRoles: Map<number, Set<number>>;

  constructor(data?: {
    userInfo: IUserInfo;
    funcRights: number[];
    orgRights: ICostAccountRight[];
  }) {
    this.specialRights = 0;
    this.functionalRights = new Set<number>();
    this.organizationalRights = new Map<number, Map<number, AuthorizationLevel>>();
    this.attestLevels = new Set<number>();
    this.functionalRoles = new Set<number>();
    this.organizationalRoles = new Map<number, Set<number>>();
    this.minAttestLevel = 0;
    this.maxAttestLevel = 0;

    if (!data) return;

    const { userInfo, funcRights, orgRights } = data;
    if (userInfo.isAdminUser) this.specialRights |= SpecialRight.UserAdmin;
    if (userInfo.canModifyOwnPlanning) this.specialRights |= SpecialRight.ModifyOwnPlanning;
    if (userInfo.canModifyOwnRegistrations)
      this.specialRights |= SpecialRight.ModifyOwnRegistrations;
    if (userInfo.canModifyOwnEmployeeData) this.specialRights |= SpecialRight.ModifyOwnEmployeeData;
    if (userInfo.isImpersonateUser) this.specialRights |= SpecialRight.ImpersonateUser;

    this.functionalRights = new Set<number>(funcRights.filter(right => right < 0));

    this.attestLevels = new Set<number>(funcRights.filter(right => right > 0));
    this.minAttestLevel = this.attestLevels.size > 0 ? Math.min(...this.attestLevels.values()) : 0;
    this.maxAttestLevel = this.attestLevels.size > 0 ? Math.max(...this.attestLevels.values()) : 0;

    for (const orgRight of orgRights) {
      let orgUnitRightsMap = this.organizationalRights.get(orgRight.OrgUnitId);
      if (!orgUnitRightsMap) {
        orgUnitRightsMap = new Map<number, AuthorizationLevel>();
        this.organizationalRights.set(orgRight.OrgUnitId, orgUnitRightsMap);
      }

      orgUnitRightsMap.set(
        orgRight.CostAccountId,
        orgRight.ModifyRight ? AuthorizationLevel.Modify : AuthorizationLevel.Read
      );
    }

    const now = moment();
    this.functionalRoles = new Set<number>(
      userInfo.functionalRoles
        ?.filter(
          role =>
            moment(role.startDate).isSameOrBefore(now) &&
            (!role.endDate || moment(role.endDate).isSameOrAfter(now))
        )
        .map(role => role.roleId)
    );

    const activeOrgRoles =
      userInfo.organizationalRoles?.filter(
        role =>
          moment(role.startDate).isSameOrBefore(now) &&
          (!role.endDate || moment(role.endDate).isSameOrAfter(now))
      ) ?? [];

    for (const orgRole of activeOrgRoles) {
      let orgRolesSet = this.organizationalRoles.get(orgRole.organizationUnitId);
      if (!orgRolesSet) {
        orgRolesSet = new Set<number>();
        this.organizationalRoles.set(orgRole.organizationUnitId, orgRolesSet);
      }

      orgRolesSet.add(orgRole.roleId);
    }
  }

  hasSpecialRight(right: SpecialRight): boolean {
    return (this.specialRights & right) === right;
  }

  hasFunctionalRight(right: FunctionalRight): boolean {
    return this.functionalRights.has(right);
  }

  getOrganizationalRight(orgUnitId: number, dimensionValue: number): AuthorizationLevel {
    const permission = this.organizationalRights.get(orgUnitId)?.get(dimensionValue);
    return permission ?? AuthorizationLevel.None;
  }

  hasAllFunctionalRights(): boolean {
    return this.functionalRoles.has(ALL_RIGHTS_ROLE_ID);
  }

  hasAllOrganizationalRights(orgUnitId: number): boolean {
    return this.organizationalRights.get(orgUnitId)?.has(ALL_RIGHTS_ROLE_ID) ?? false;
  }
}
