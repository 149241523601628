export enum SpecialRight {
  None = 0,
  UserAdmin = 1,
  ModifyOwnPlanning = 2,
  ModifyOwnRegistrations = 4,
  ModifyOwnEmployeeData = 8,
  ImpersonateUser = 16
}

export enum FunctionalRight {
  ShowEmployeeSalary = -1,
  AccessSystemMenu = -2,
  AccessImportMenu = -3,
  AccessExportMenu = -4,
  AccessPayrollMenu = -5,
  AccessDoorMenu = -6,
  EraseStampsInLimbo = -7,
  AdministerScheduleTemplates = -8,
  AccessActivePeriodSettings = -9,
  AccessProgramSettings = -10,
  AccessSpecialDayTypeSettings = -11,
  AdministerEmployeeTemplates = -12,
  AccessUserSettings = -13,
  AdministerTLUCodes = -14,
  AdministerEmployees = -15,
  ChangePaymentTypeOnStamps = -16,
  AccessEmployeeRegister = -17,
  AccessEmployeeRegisterOverview = -18,
  AccessEmployeeRegisterPersonalInformation = -19,
  AccessCorrectPunchesView = -20,
  AccessEmployeeRegisterEmploymentPeriods = -21,
  AccessEmployeeRegisterBaseSchedules = -22,
  AccessEmployeeRegisterTerminalsAndDoors = -23,
  AccessEmployeeRegisterRelatives = -24,
  AccessEmployeeRegisterAdvanced = -25,
  AccessEmployeeRegisterOrganizationUnits = -26,
  AccessProgramSettingsCalculationBasedSettings = -27,
  AccessProgramSettingsSecuritySettings = -28,
  DownloadDesktopClient = -29,
  WebPortal_Authentication = -30,
  DesktopClient_Authentication = -31,
  WebPortal_PunchInOut = -32,
  DesktopClient_PunchInOut = -33,
  UseLongTimeAccessTickets = -34,
  AdministerFlexBalance = -35,
  TimeDevice_AccessDeviceSettings = -36,
  WebPortal_UploadFiles = -37,
  PayrollSystem_Salary_Export = -38,
  Attest_Requirement_Override = -39,
  Alert_Reminder_Override = -40,
  Alert_Information_Override = -41,
  Alert_Warning_Override = -42,
  Alert_Error_Override = -43,
  DocumentPortalMenuOptions = -44,
  AdministerEditReservationLock = -45,
  AccessPrintOuts = -46,
  AccessReports = -47,
  AccessPlan = -48,
  GDPR_AccessPhones = -49,
  GDPR_AccessSocialSecurityNumber = -50,
  GDPR_AccessAddresses = -51,
  GDPR_AccessEmails = -52,
  GDPR_AccessNextOfKins = -53,
  Alert_Access_TrialEmploymentEnds = -54,
  Alert_Access_DoctorNoteIsRequired = -55,
  Alert_Access_SendInfoToInsuranceAgency = -56,
  Alert_Access_TooManySickLeaves = -57,
  Alert_Access_TimeForAttest = -58,
  Alert_Access_ActivePeriodEnding = -59,
  Alert_Access_ProbationaryEmploymentEnds = -60,
  AccessEmployeeRegisterFlex = -61,
  AccessRestoreDeletedPersons = -62,
  AccessSortTerminalLamps = -63,
  Alert_Access_SubstituteEmploymentEnds = -64,
  GDPR_AccessContracts = -65,
  Kivra_Access_SendToKivraBaseEmploymentDoc = -66,
  Kivra_Access_SendToKivraTemporaryEmploymentDoc = -67,
  AccessGlobalEmployeeSearch = -68
}

export enum AuthorizationLevel {
  None = 0,
  Read,
  Modify
}
