export enum Area {
  Background = 'Bkgnd',
  Border = 'Border',
  Padding = 'Padd',
  Text = 'Txt',
  Glyph = 'Glyph',
  GlyphBackground = 'GlyphBkgnd',
  Shadow = 'Shadow',
  GroupText = 'GrpTxt',
  Line = 'Line',
  Thumb = 'Thumb',

  // Planning specific
  BackgroundPanel = 'BkPanel',
  BackgroundMonday = 'BkMon',
  BackgroundTuesday = 'BkTue',
  BackgroundWednesday = 'BkWed',
  BackgroundThursday = 'BkThu',
  BackgroundFriday = 'BkFri',
  BackgroundSaturday = 'BkSat',
  BackgroundSunday = 'BkSun',
  BackgroundWeek = 'BkWeek',
  BackgroundMonth = 'BkMonth',
  TotalsLine = 'TotLine',
  BackgroundOccurrence = 'BkOcc',
  BackgroundFixedTask = 'BkFixdTask',
  SOWBorder = 'BrdrSOW',
  SOWBackground = 'BkSOW',
  BackgroundText = 'BkText',
  BackgroundGradientStart = 'BkGrad1',
  BackgroundGradientEnd = 'BkGrad2',

  CueText = 'CueTxt',
  TextDeviation = 'DevTxt',
  ToggleButtonSlider = 'ToggleBtnSlider'
}
