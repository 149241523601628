import cx from 'classnames';
import React from 'react';
import styles from './alert.module.css';

export enum AlertType {
  warning,
  info
}

interface IAlertProps {
  className?: string;
  type: AlertType;
  stickedToTop?: boolean;
  children: JSX.Element;
}

export function AlertMessage(props: IAlertProps) {
  return (
    <div
      className={cx([
        styles.root,
        {
          [styles.stickedToTop]: props.stickedToTop,
          [styles.warning]: props.type === AlertType.warning,
          [styles.info]: props.type === AlertType.info
        },
        props.className
      ])}
    >
      {props.children}
    </div>
  );
}
