// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-error-module__error--vw5CwuZLOXdfDEd1XGn5{margin-top:0;font-size:80%}.form-error-module__adminError--vicHYfkzKt_NjtgAMRGM{color:rgb(var(--brand-color-danger))}", "",{"version":3,"sources":["webpack://./packages/ui-components/src/form-error/form-error.module.css"],"names":[],"mappings":"AAAA,gDACE,YAAa,CACb,aACF,CAEA,qDACE,oCACF","sourcesContent":[".error {\r\n  margin-top: 0;\r\n  font-size: 80%;\r\n}\r\n\r\n.adminError {\r\n  color: rgb(var(--brand-color-danger));\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "form-error-module__error--vw5CwuZLOXdfDEd1XGn5",
	"adminError": "form-error-module__adminError--vicHYfkzKt_NjtgAMRGM"
};
export default ___CSS_LOADER_EXPORT___;
