import { authHTTPClient, apiUrl1 } from './auth-client';
import { ExtendableError } from 'ts-error';
import { LoginErrorCodes } from './session';
import { AxiosError } from 'axios';

const wrongInstance = 'Wrong Customer instance';

export class InvalidPasswordError extends ExtendableError {}
export class InvalidCustomerError extends ExtendableError {}
export class InvalidResetADPasswordError extends ExtendableError {}
export class InvalidUserError extends ExtendableError {}

export interface IResetRequest {
  CustomerId: string;
  LoginName: string;
}

export interface IPasswordChangeRequest {
  Password: string;
}

export async function resetUserPassword(payload: IResetRequest) {
  return await authHTTPClient.post(`${apiUrl1}/account/reset`, payload);
}

export async function changePassword(payload: IPasswordChangeRequest) {
  return await authHTTPClient.post(`${apiUrl1}/account/password`, payload);
}

export async function getPasswordRules() {
  return await authHTTPClient.get(`${apiUrl1}/account/password/rules`);
}

export async function reset(payload: any) {
  try {
    await resetUserPassword(payload);
  } catch (error) {
    const e = error as AxiosError;
    switch (e?.response?.statusText) {
      case LoginErrorCodes.Forbidden:
        throw new InvalidCustomerError();
      case LoginErrorCodes.BadRequest:
        if (e?.response?.data.Error.Message.includes('active'))
          throw new InvalidResetADPasswordError();

        if (e?.response?.data.Error.Message === wrongInstance) {
          throw new InvalidCustomerError();
        } else {
          throw new InvalidPasswordError();
        }
      case LoginErrorCodes.NotFound:
        throw new InvalidUserError();
      default:
        throw new Error(e?.response?.data.Message);
    }
  }
}
