import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import i18n from './i18n/i18n';

dayjs.locale(i18n.languages[0]);

export function datetime(
  date?: dayjs.ConfigType,
  option?: dayjs.OptionType,
  locale?: string
): dayjs.Dayjs {
  return dayjs(date, option, locale);
}

export function formatDate(date: string | Date) {
  return datetime(date).format('YYYY-MM-DD HH:mm');
}
