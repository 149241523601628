import React from 'react';
import { AlertMessage, AlertType } from '../alert-message/alert-message';
import { formatDate } from '@lib/datetime';
import { IMaintenance } from '@api/auth/session';

interface IMaintainanceMessageProp {
  className?: string;
  maintenance: IMaintenance | null;
}

export function MaintenanceMessage(props: IMaintainanceMessageProp) {
  return props.maintenance ? (
    <AlertMessage type={AlertType.warning} className={props.className ?? ''}>
      <>
        <p>{props.maintenance.Description}</p>
        <p>
          {formatDate(props.maintenance.StartTime)} — {formatDate(props.maintenance.EndTime)}
        </p>
      </>
    </AlertMessage>
  ) : null;
}
