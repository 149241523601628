import React, { createContext, useContext, useEffect } from 'react';
import { IPlatformData, getPlatformData } from '../api/auth/platform';
import { Feature, useFeatureFlagsContext } from './feature-flags-context';
import { useSession } from './useSession';
interface IPlatformDataProvider {
  platformData: IPlatformData | null;
  webClientUrl: string;
  version: string;
  payrollHeaders: object;
  instanceUrl: string;
}

export const PlatformDataContext = createContext<IPlatformDataProvider>(null as any);

export function PlatformDataProvider(props: { children: JSX.Element }) {
  const { session, token } = useSession();
  const { hasFeature } = useFeatureFlagsContext();

  const autoAuth = hasFeature(Feature.AutoAuthenticated);

  useEffect(() => {
    if (autoAuth) return;

    // It turns to be that "F5-proxy" has a requirement to make a call that gets platform information
    // so just keep it here even though we're not interested in response.
    if (token) getPlatformData();
  }, [autoAuth, token]);

  let version = '';
  let webClientUrl = '';
  let payrollHeaders: {
    'TT-ResourceUri'?: string;
    'TT-HostLocalName'?: string;
  } = {};
  let instanceUrl = '';
  let platformData = null;

  if (session) {
    webClientUrl = autoAuth ? session.AppBindingUrl : session.WebClientUri;
    version = webClientUrl.split('se/')[1].split('/')[1];
    payrollHeaders = {
      'TT-ResourceUri': session.ApiBaseUri,
      'TT-HostLocalName': session.TTServiceHostLocalName
    };

    instanceUrl = session.TTServiceInstanceUrl.replace('net.tcp', 'https');

    platformData = { ...session };
  }

  return (
    <PlatformDataContext.Provider
      value={{
        platformData,
        webClientUrl,
        version,
        payrollHeaders,
        instanceUrl
      }}
    >
      {props.children}
    </PlatformDataContext.Provider>
  );
}

export function usePlatformData() {
  const data = useContext(PlatformDataContext);

  if (!data) {
    throw new Error(
      'usePlatformData should be used with PlatformDataProvider and below SessionProvider & StorageProvider'
    );
  }

  return data;
}
