import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ISessionInfo, extendSession } from '../api/auth/session';
import { useSession } from './useSession';
import { redirectToAuthPortal } from './redirect-to-app';
import {
  IAlertInfoProps,
  AlertConfirmation,
  AlertOptions
} from '../ui-components/src/alert-confirmation';

enum SessionState {
  Normal = 0,
  EndOfLifeIsFinal,
  LogoutPending
}

interface IStateHandlingProps {
  state: SessionState;
  sessionInfo: ISessionInfo;
}

const TIMER_INTERVAL_MILLISECONDS = 20 * 1000;
const DEFAULT_EXTEND_THRESHOLD_IN_SECONDS = 300;
const DEFAULT_NOTIFY_SESSION_EXPIRE_THRESHOLD_IN_SECONDS = 300;

function resolveState(sessionInfo?: ISessionInfo) {
  if (!sessionInfo?.FullTimeAssigned) return SessionState.EndOfLifeIsFinal;
  return SessionState.Normal;
}

async function handleNormalState(props: IStateHandlingProps) {
  const { state, sessionInfo } = props;

  const now = moment();
  const sessionEndOfLife = moment(sessionInfo.EndOfLifeTimestamp);
  const nextExtendTime = moment(sessionEndOfLife).add(
    -1 * DEFAULT_EXTEND_THRESHOLD_IN_SECONDS,
    'seconds'
  );

  if (nextExtendTime > now) return { state, sessionInfo };

  try {
    const { data } = await extendSession();
    const resolvedState = resolveState(data);
    return { state: resolvedState, sessionInfo: data };
  } catch (e) {
    // ignore error here and return previous state
    return { state, sessionInfo };
  }
}

function handleEndOfLifeIsFinalState(props: IStateHandlingProps) {
  const { state, sessionInfo } = props;

  const now = moment();
  const notifyTime = moment(sessionInfo.EndOfLifeTimestamp).add(
    -1 * DEFAULT_NOTIFY_SESSION_EXPIRE_THRESHOLD_IN_SECONDS,
    'seconds'
  );

  if (notifyTime > now) return { state, sessionInfo };

  // log out on EndOfLifeTimestamp time
  return { state: SessionState.LogoutPending, sessionInfo };
}

export default function SessionLifeTimeManager(props: { children: JSX.Element }) {
  const { session, updateSessionInfo, logout } = useSession();
  const { t } = useTranslation();
  const [sessionState, setSessionState] = useState<SessionState>(
    resolveState(session?.SessionInfo)
  );
  const [alertInfo, showAlert] = useState<IAlertInfoProps>();
  const extendTokenTimerRef = useRef<number>(-1);
  const deferredLogoutTimerRef = useRef<number>(-1);

  useEffect(() => {
    let unmounted = false;

    async function processSessionState() {
      if (!session) return;

      const sessionInfo = session.SessionInfo;
      let nextState: SessionState;
      let nextSessionInfo = sessionInfo;

      switch (sessionState) {
        case SessionState.Normal:
          {
            const result = await handleNormalState({
              state: sessionState,
              sessionInfo
            });
            if (unmounted) return;

            nextState = result.state;
            nextSessionInfo = result.sessionInfo;

            if (nextState === SessionState.LogoutPending) {
              // Failed to extend session, show alert that user is going to be logged out
              showAlert({
                caption: t('session:alert:extendFail:caption'),
                text: t('session:alert:extendFail:message', {
                  endOfLife: moment(sessionInfo.EndOfLifeTimestamp).format('HH:mm')
                }),
                buttons: AlertOptions.OK
              });
            }
          }
          break;

        case SessionState.EndOfLifeIsFinal:
          {
            const result = handleEndOfLifeIsFinalState({
              state: sessionState,
              sessionInfo
            });

            nextState = result.state;
            nextSessionInfo = result.sessionInfo;

            if (nextState === SessionState.LogoutPending && sessionInfo.MaintenancePlanned) {
              // show information about upcoming maintenance
              showAlert({
                caption: t('session:alert:upcomingMaintenance:caption'),
                text: t('session:alert:upcomingMaintenance:message', {
                  startTime: moment(nextSessionInfo.MaintenanceStartTime).format('HH:mm'),
                  endTime: moment(nextSessionInfo.MaintenanceEndTime).format('HH:mm'),
                  message: nextSessionInfo.MaintenanceMessage
                }),
                buttons: AlertOptions.OK
              });
            }
          }
          break;

        case SessionState.LogoutPending:
          // just waiting for logout, nothing changes here
          nextState = SessionState.LogoutPending;
          break;
      }

      if (nextState === sessionState && nextSessionInfo === sessionInfo) {
        if (sessionState !== SessionState.LogoutPending) {
          // State has not changed, schedule next timer
          extendTokenTimerRef.current = window.setTimeout(
            processSessionState,
            TIMER_INTERVAL_MILLISECONDS
          );
        }
      } else {
        if (nextSessionInfo !== sessionInfo) updateSessionInfo(nextSessionInfo);
        if (nextState !== sessionState) setSessionState(nextState);
      }
    }

    processSessionState();

    return () => {
      unmounted = true;
      if (extendTokenTimerRef.current !== -1) {
        window.clearTimeout(extendTokenTimerRef.current);
        extendTokenTimerRef.current = -1;
      }
    };
  }, [session, sessionState]);

  useEffect(() => {
    if (!session || sessionState !== SessionState.LogoutPending) return;

    const sessionInfo = session?.SessionInfo;
    const remainingTime = moment(sessionInfo.EndOfLifeTimestamp).diff(moment());

    if (remainingTime > 0) {
      // schedule logout
      deferredLogoutTimerRef.current = window.setTimeout(() => {
        logout(redirectToAuthPortal);
      }, remainingTime);
    } else {
      // instant logout
      logout(redirectToAuthPortal);
    }

    return () => {
      if (deferredLogoutTimerRef.current !== -1) {
        window.clearTimeout(deferredLogoutTimerRef.current);
        deferredLogoutTimerRef.current = -1;
      }
    };
  }, [session, sessionState]);

  return (
    <>
      {alertInfo && <AlertConfirmation {...alertInfo} onClose={() => showAlert(undefined)} />}
      {props.children}
    </>
  );
}
