import { apiUrl1, authHTTPClient } from './auth-client';

export interface IPlatformData {
  PlatformVersion: string;
  PackageVersion: string;
  DatabaseInstanceUrl: string;
  PublicDatabaseInstanceUrl: string;
  TTServiceInstanceUrl: string;
  TTServiceHostLocalName: string;
  AppBindingUrl: string;
  ApiCustomerUri: string;
  CustomerId: string;
  CustomerName: string;
  CustomerDescription: string;
  WebClientUri: string;
}

export async function getPlatformData() {
  return authHTTPClient.get<IPlatformData>(`${apiUrl1}/ttclient/platform`);
}
