import React from 'react';
import cx from 'classnames';
import styles from './form-error.module.css';

interface IFormErrorProps {
  text: string;
  className?: string;
}

export function FormError(props: IFormErrorProps) {
  return (
    <p cy-id="form-error" className={cx(styles.error, props.className)}>
      {props.text}
    </p>
  );
}

export function AdminFormError(props: IFormErrorProps) {
  return <p className={styles.adminError}>{props.text}</p>;
}
