import React, { createContext, useContext, useState } from 'react';
import { IIntermediateSession } from '@api/auth/session';
import { useSearchParams } from 'react-router-dom';

export interface IAuthData extends IIntermediateSession {
  username: string;
  customerId: string;
}

interface ILoginContext {
  processing: boolean;
  intermediateSession: IAuthData | null;
  redirectDestination: string | undefined;
  setProcessing(status: boolean): void;
  setIntermediateSession(session: IAuthData): void;
}

export const LoginContext = createContext<ILoginContext>(null as any);

export function LoginContextProvider(props: { children: JSX.Element }) {
  const [processing, setProcessing] = useState(false);
  const [intermediateSession, setIntermediateSession] = useState<IAuthData | null>(null);

  const [searchParams] = useSearchParams();
  const [redirectDestination] = useState(decodeURIComponent(searchParams.get('dest') ?? ''));

  return (
    <LoginContext.Provider
      value={{
        processing,
        setProcessing,
        intermediateSession,
        setIntermediateSession,
        redirectDestination
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
}

export function useLoginContext() {
  const data = useContext(LoginContext);

  if (!data) {
    throw new Error(`You should use useLoginContext only with LoginContextProvider`);
  }

  return data;
}
