import { apiUrl1, authHTTPClient } from './auth-client';

export interface IThemeColors {
  Name: string;
  IsCurrent: boolean;
  Colors: IThemeColor[];
}

export interface IThemeColor {
  Name: string;
  Value: string;
}

export interface ICurrentTheme {
  theme: string;
}

export async function fetchThemeColors() {
  return await authHTTPClient.get<IThemeColors[]>(`${apiUrl1}/users/current/themesColors`);
}

export async function postThemeColors(name: string) {
  return await authHTTPClient.post<ICurrentTheme>(
    `${apiUrl1}/users/current/themesColors?theme=${name}`
  );
}
