import React, { useState } from 'react';
import { LoginRoutes, useAuthNavigation } from '../../routes/routes-config';
import { ForgotForm } from '../../components/forgot-form/forgot-form';
import { LoginUserPassForm } from '../../components/login-user-pass-form';
import styles from './login.css';

export default function LoginUserPass() {
  const [isForgot, setForgot] = useState(false);
  const navigate = useAuthNavigation();

  return (
    <div className={styles.content}>
      {isForgot ? (
        <ForgotForm onBackClick={() => setForgot(false)} />
      ) : (
        <LoginUserPassForm
          onForgotClick={() => setForgot(true)}
          requestConfirm={() => navigate(LoginRoutes.Confirm)}
        />
      )}
    </div>
  );
}
