// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabs-module__root--WktEfcRWDU4qWORwNp2I{margin:20px 0}.tabs-module__root--WktEfcRWDU4qWORwNp2I>nav{display:flex}.tabs-module__content--zhdYws9aO138AoZfbWI1,.tabs-module__root--WktEfcRWDU4qWORwNp2I>nav{margin:0 auto;max-width:360px}.tabs-module__content--zhdYws9aO138AoZfbWI1{background-color:rgba(var(--brand-color-white),.12);color:rgb(var(--brand-color-white));padding:20px;min-height:295px;box-sizing:border-box}.tabs-module__hidden--wXv39ItyEdbbwtAjeAfN{display:none}@media (max-width:414px){.tabs-module__content--zhdYws9aO138AoZfbWI1,.tabs-module__root--WktEfcRWDU4qWORwNp2I>nav{margin:0;max-width:100%}}", "",{"version":3,"sources":["webpack://./packages/ui-components/src/tabs/tabs.module.css"],"names":[],"mappings":"AAAA,yCACE,aACF,CAEA,6CACE,YAGF,CAEA,yFAJE,aAAc,CACd,eAWF,CARA,4CACE,mDAAsD,CACtD,mCAAoC,CAGpC,YAAa,CACb,gBAAiB,CACjB,qBACF,CAEA,2CACE,YACF,CAEA,yBACE,yFAEE,QAAS,CACT,cACF,CACF","sourcesContent":[".root {\r\n  margin: 20px 0;\r\n}\r\n\r\n.root > nav {\r\n  display: flex;\r\n  margin: 0 auto;\r\n  max-width: 360px;\r\n}\r\n\r\n.content {\r\n  background-color: rgba(var(--brand-color-white), 0.12);\r\n  color: rgb(var(--brand-color-white));\r\n  max-width: 360px;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n  min-height: 295px;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.hidden {\r\n  display: none;\r\n}\r\n\r\n@media (max-width: 414px) {\r\n  .root > nav,\r\n  .content {\r\n    margin: 0;\r\n    max-width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "tabs-module__root--WktEfcRWDU4qWORwNp2I",
	"content": "tabs-module__content--zhdYws9aO138AoZfbWI1",
	"hidden": "tabs-module__hidden--wXv39ItyEdbbwtAjeAfN"
};
export default ___CSS_LOADER_EXPORT___;
