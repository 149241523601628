import cx from 'classnames';
import React, { ButtonHTMLAttributes, Ref } from 'react';
import styles from './button.module.css';

export enum ButtonType {
  button = 'button',
  submit = 'submit'
}

export enum ButtonAction {
  primary = 'primary',
  secondary = 'secondary',
  default = 'default',
  cancel = 'cancel'
}

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value?: string;
  type?: ButtonType;
  disabled?: boolean;
  buttonAction?: ButtonAction;
  icon?: React.ReactNode;
  tabIndex?: number;
  className?: string;
  cyId?: string;
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export const Button = React.forwardRef((props: IButtonProps, ref: Ref<HTMLButtonElement>) => {
  const { onClick, buttonAction, className, cyId, ...rest } = props;

  return (
    <button
      ref={ref}
      className={cx([
        styles.root,
        styles.defaultBtn,
        {
          [styles.primary]: buttonAction === ButtonAction.primary,
          [styles.danger]: buttonAction === ButtonAction.cancel,
          [styles.disabled]: rest.disabled
        },
        className
      ])}
      cy-id={props.cyId}
      onClick={onClick}
      {...rest}
    >
      {props.icon ? (
        <div className={cx(styles.icon, { [styles.withValue]: props.value })}>{props.icon}</div>
      ) : null}
      {props.value}
    </button>
  );
});
