import cx from 'classnames';
import React from 'react';
import styles from './link.module.css';

export enum LinkType {
  tab = 'tab',
  link = 'link',
  round = 'round',
  brand = 'brand',
  dropdown = 'dropdown',
  child = 'child',
  roundedLg = 'large',
  roundedSm = 'small'
}

export enum LinkColor {
  WebClient = '#008b8b',
  AuthPortal = '#008b8b',
  PayrollClient = '#0a2472',
  WindowsClient = '#ff7f50',
  Default = '#ccc'
}

export enum Size {
  small = '80px',
  big = '180px'
}

interface ILinkProps {
  id?: string;
  text: string;
  activeId?: string;
  type: LinkType;
  disabled?: boolean;
  background?: LinkColor | string;
  hasIcon?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  size?: Size | string;
  cyId?: string;
  cyTarget?: string;
  tabIndex?: number;
  title?: string;

  onClick(id: string): void;
}

export function Link(props: ILinkProps) {
  const { id, text, activeId, type, disabled, background, size, hasIcon, icon, onClick } = props;
  const isTab = type === LinkType.tab;
  const isLink = type === LinkType.link;
  const isRound = type === LinkType.round;
  const isBrand = type === LinkType.brand;
  const isDropdown = type === LinkType.dropdown;
  const isActive = id === activeId;
  const isChild = type === LinkType.child;

  function onLinkClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();

    onClick(id || '');
  }
  // TODO separate as several components
  const s = cx({
    [styles.tab]: isTab,
    [styles.active]: isTab && isActive,
    [styles.link]: isLink || isDropdown,
    [styles.root]: isLink && isActive,
    [styles.disabled]: disabled,
    [styles.round]: isRound,
    [styles.brand]: isBrand,
    [styles.child]: isChild,
    [styles.roundedSm]: size === Size.small,
    [styles.roundedLg]: size === Size.big
  });

  return (
    <div
      className={cx([s, props.className || ''])}
      style={{ backgroundColor: background }}
      onClick={disabled ? undefined : onLinkClick}
      cy-id={props.cyId}
      cy-target={props.cyTarget}
      title={props.title}
    >
      {hasIcon ? <span className={styles.icon}>{icon}</span> : null}
      {text && (
        <a href="#" role="button" tabIndex={props?.tabIndex}>
          {text}
        </a>
      )}
      {isDropdown ? <span className={styles.caret}>{props.children}</span> : null}
    </div>
  );
}
