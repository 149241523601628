import { HEX } from '@utils/color-utils';

export enum BrandColor {
  Main1 = 'brandColorMain1',
  Main2 = 'brandColorMain2',
  Frame1 = 'brandColorFrame1',
  Frame2 = 'brandColorFrame2',
  DialogBorder = 'brandColorDialogBorder',
  BottomBar1 = 'brandColorBottomBar1',
  BottomBar2 = 'brandColorBottomBar2',
  BottomBarText = 'brandColorBottomBarText',
  Secondary1 = 'brandColorSecondary1',
  Secondary2 = 'brandColorSecondary2',
  Danger = 'brandColorDanger',
  Success = 'brandColorSuccess',
  White = 'brandColorWhite',
  LighterGray = 'brandColorLighterGray',
  LightGray = 'brandColorLightGray',
  Gray = 'brandColorGray',
  DarkGray = 'brandColorDarkGray',
  DarkerGray = 'brandColorDarkerGray',
  CaptionText = 'brandColorCaptionText',
  MenuText = 'brandColorMenuText',
  MenuHover = 'brandColorMenuHover',
  MenuSelection = 'brandColorMenuSelection',
  SystemButtons = 'brandColorSystemButtons',
  SystemButtonsHi = 'brandColorSystemButtonsHi',
  SystemButtonsHiBackground = 'brandColorSystemButtonsHiBackground',
  Background = 'brandColorBackground',
  WeatherColorClear1 = 'brandColorWeatherColorClear1',
  WeatherColorClear2 = 'brandColorWeatherColorClear2',
  WeatherColorClearNight1 = 'brandColorWeatherColorClearNight1',
  WeatherColorClearNight2 = 'brandColorWeatherColorClearNight2',
  EmployeePopUp1 = 'brandColorEmployeePopUp1',
  EmployeePopUp2 = 'brandColorEmployeePopUp2',
  Text = 'brandColorText',
  LoginDialogBackground1 = 'brandColorLoginDialogBackground1',
  LoginDialogBackground2 = 'brandColorLoginDialogBackground2'
}

export const BrandColorDefaults: { [key: string]: HEX } = {
  [BrandColor.Main1]: '#00469f',
  [BrandColor.Main2]: '#1584cc',
  [BrandColor.Frame1]: '#00469f',
  [BrandColor.Frame2]: '#1584cc',
  [BrandColor.DialogBorder]: '#0078d2',
  [BrandColor.BottomBar1]: '#f3f8fb',
  [BrandColor.BottomBar2]: '#d8eefc',
  [BrandColor.BottomBarText]: '#000000',
  [BrandColor.Secondary1]: '#28c8dc',
  [BrandColor.Secondary2]: '#c80a00',
  [BrandColor.Danger]: '#c80a00',
  [BrandColor.Success]: '#286e00',
  [BrandColor.White]: '#ffffff',
  [BrandColor.LighterGray]: '#f2f2f2',
  [BrandColor.LightGray]: '#e2e2e2',
  [BrandColor.Gray]: '#afafaf',
  [BrandColor.DarkGray]: '#676767',
  [BrandColor.DarkerGray]: '#323232',
  [BrandColor.CaptionText]: '#ffffff',
  [BrandColor.MenuText]: '#ffffff',
  [BrandColor.MenuHover]: '#ffc80f',
  [BrandColor.MenuSelection]: '#3278c8',
  [BrandColor.SystemButtons]: '#86b1d3',
  [BrandColor.SystemButtonsHi]: '#86b1d3',
  [BrandColor.SystemButtonsHiBackground]: '#c85051',
  [BrandColor.Background]: '#e2e2e2',
  [BrandColor.WeatherColorClear1]: '#26c6de',
  [BrandColor.WeatherColorClear2]: '#7fddeb',
  [BrandColor.WeatherColorClearNight1]: '#1977bb',
  [BrandColor.WeatherColorClearNight2]: '#2593e2',
  [BrandColor.EmployeePopUp1]: '#e6e6e6',
  [BrandColor.EmployeePopUp2]: '#fafafa',
  [BrandColor.Text]: '#505050',
  [BrandColor.LoginDialogBackground1]: '#0173d0',
  [BrandColor.LoginDialogBackground2]: '#43a7fa'
};
