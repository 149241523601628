import React, { useCallback, useEffect, useId, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactFocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import cx from 'classnames';
import styles from './modal.module.css';

export interface IModalProps {
  /** Indicates whether modal should be shown */
  show: boolean;
  /** Callback fired when modal gets closed */
  onHide(): void;
  /**
   * Callback fired before modal gets closed.
   * Provides possibility to control whether modal can be closed or not.
   * Should return `true` when modal can be closed, `false` - otherwise
   */
  onHiding?(): boolean | Promise<boolean>;

  /** Indicates that modal should not have a header, `false` by default */
  headless?: boolean;
  /** Modal title string to put in header */
  title?: string;

  /** Component that should be set in body  */
  body: JSX.Element;

  /** Component that should be set in footer */
  footer?: JSX.Element;

  /** Controls whether modal should be closed on click outside the modal, `true` by default */
  hidesOnBackdropClick?: boolean;
  /** Controls whether modal should be closed on `Escape` key press, `true` by default */
  hidesOnEscape?: boolean;
  /** Controls whether backdrop should be visible or not, `false` by default */
  noBackdrop?: boolean;

  /** Provides possibility to limit max-width of modal. By default `md` is used. */
  size?: 'sm' | 'md' | 'lg' | 'xl';
  /**
   * Controls whether modal should be rendered in full screen mode.
   * Specifying a breakpoint will render modal as fullscreen below the breakpoint size
   */
  fullscreen?: true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down';

  /** Cypress attribute */
  cyId?: string;

  /** Object that contains class names to be added to different parts of modal  */
  className?: {
    modal?: string;
    backdrop?: string;
    content?: string;
    header?: string;
    title?: string;
    closeButton?: string;
    bodyWrapper?: string;
    body?: string;
    footer?: string;
  };
}

const ANIMATION_DURATION = 150;

export function Modal(props: IModalProps) {
  const {
    show,
    onHiding,
    onHide,
    headless,
    title,
    body,
    footer,
    hidesOnBackdropClick = true,
    hidesOnEscape = true,
    noBackdrop,
    size = 'md',
    fullscreen,
    cyId,
    className
  } = props;
  const [visible, setVisible] = useState(false);
  const titleId = useId();

  useEffect(() => {
    if (show) {
      setVisible(true);
      return;
    } else {
      let timeoutId = window.setTimeout(() => {
        setVisible(false);
        timeoutId = 0;
      }, ANIMATION_DURATION);

      return () => {
        if (timeoutId) {
          window.clearTimeout(timeoutId);
          timeoutId = 0;
        }
      };
    }
  }, [show]);

  const handleHide = useCallback(async () => {
    const canHide = !onHiding || (await onHiding());
    if (canHide) onHide();
  }, [onHiding, onHide]);

  if (!show && !visible) return null;

  const fullscreenStyles = cx({
    [styles.fullscreen]: fullscreen === true,
    [styles['sm-fullscreen']]: fullscreen === 'sm-down',
    [styles['md-fullscreen']]: fullscreen === 'md-down',
    [styles['lg-fullscreen']]: fullscreen === 'lg-down',
    [styles['xl-fullscreen']]: fullscreen === 'xl-down'
  });

  return createPortal(
    <>
      <div
        onClick={e => {
          if (!hidesOnBackdropClick) return;
          if (e.target !== e.currentTarget) return;
          handleHide();
        }}
        className={cx(
          styles.fade,
          styles.backdrop,
          {
            [styles.show]: !noBackdrop && show && visible
          },
          className?.backdrop ?? ''
        )}
        cy-id={cyId ? `${cyId}-backdrop` : undefined}
      />

      <ReactFocusLock crossFrame={false}>
        <RemoveScroll className={styles.scrollLock}>
          <div
            className={cx(
              styles.fade,
              styles.modal,
              fullscreenStyles,
              {
                [styles.show]: show && visible,
                [styles.sm]: size === 'sm',
                [styles.md]: size === 'md',
                [styles.lg]: size === 'lg',
                [styles.xl]: size === 'xl'
              },
              className?.modal ?? ''
            )}
            role="dialog"
            tabIndex={-1}
            aria-modal={true}
            aria-labelledby={titleId}
            cy-id={cyId}
            onKeyDown={e => {
              if (!hidesOnEscape) return;
              if (e.code !== 'Escape' || e.defaultPrevented) return;
              e.stopPropagation();
              e.preventDefault();
              handleHide();
            }}
          >
            <div
              className={cx(
                styles.content,
                fullscreenStyles,
                {
                  [styles.show]: show && visible
                },
                className?.content ?? ''
              )}
              cy-id={cyId ? `${cyId}-content` : undefined}
            >
              {headless ? null : (
                <div
                  id={titleId}
                  className={cx(styles.header, fullscreenStyles, className?.header ?? '')}
                  cy-id={cyId ? `${cyId}-header` : undefined}
                >
                  <h3
                    className={cx(styles.title, className?.title ?? '')}
                    cy-id={cyId ? `${cyId}-header-title` : undefined}
                  >
                    {title ?? ''}
                  </h3>
                  <button
                    onClick={handleHide}
                    className={cx(styles.closeIcon, className?.closeButton ?? '')}
                    tabIndex={-1}
                    cy-id={cyId ? `${cyId}-header-close` : undefined}
                  >
                    &times;
                  </button>
                </div>
              )}

              <div
                className={cx(
                  styles.bodyWrapper,
                  fullscreenStyles,
                  {
                    [styles.headless]: !!headless
                  },
                  className?.bodyWrapper ?? ''
                )}
                cy-id={cyId ? `${cyId}-body-wrapper` : undefined}
              >
                <div
                  className={cx(styles.body, 'scrollable', className?.body ?? '')}
                  cy-id={cyId ? `${cyId}-body` : undefined}
                >
                  {body}
                </div>
                {footer ? (
                  <div
                    className={cx(styles.footer, fullscreenStyles, className?.footer ?? '')}
                    cy-id={cyId ? `${cyId}-footer` : undefined}
                  >
                    {footer}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </RemoveScroll>
      </ReactFocusLock>
    </>,
    document.body
  );
}
