import axios from 'axios';
import axiosRetry from 'axios-retry';

export const retryOnStartPage = {
  'axios-retry': { retries: 3 }
}

export const customerHTTPClient = axios.create({
  baseURL: process.env.AUTH_API_BASE_URL
});

axiosRetry(customerHTTPClient, { 
  retries: 0,
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 2000;
  }
});
