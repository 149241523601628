import { customerHTTPClient } from './customer-client';
import { Moment } from 'moment';

export interface IUserInfo {
  personId: string;
  loginName: string;
  email: string;
  isAdminUser: boolean;
  canModifyOwnPlanning: boolean;
  canModifyOwnRegistrations: boolean;
  canModifyOwnEmployeeData: boolean;
  isImpersonateUser: boolean;
  functionalRoles?: IFunctionalRole[];
  organizationalRoles?: IOrganizationalRole[];
  firstName: string;
  lastName: string;
  zipCode: string;
  streetAddress: string;
  city: string;
  country: string;
  isSevSupportUser: string;
}

export interface IFunctionalRole {
  roleId: number;
  startDate: Moment;
  endDate: Moment;
}

export interface IOrganizationalRole {
  roleId: number;
  organizationUnitId: number;
  startDate: Moment;
  endDate: Moment;
  includeSubOrgUnits: boolean;
  modifyRight: boolean;
}

export async function getUsers() {
  const { data } = await customerHTTPClient.get<IUserInfo[]>(`/users`);
  return data;
}

export async function getUser(userId: string) {
  const { data } = await customerHTTPClient.get<IUserInfo>(`/users/${userId}`);
  return data;
}
