// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alert-module__root--ZX6khfaamuOb4uks2mAH{padding:4px 10px;margin:20px 0;border:1px solid transparent;border-radius:6px}.alert-module__stickedToTop--oLw_mutmxTs_3sBLg6ei{top:50px;position:fixed;left:0;right:0}.alert-module__root--ZX6khfaamuOb4uks2mAH>p{margin:0}.alert-module__root--ZX6khfaamuOb4uks2mAH>p+p{margin-top:5px}.alert-module__warning--gG5ynJLDKijIHsH_g31R{color:rgb(var(--brand-color-bottom-bar-text));background-color:rgb(var(--brand-color-menu-hover));border-color:rgb(var(--brand-color-menu-hover))}.alert-module__info--Zor387s3Wx0Ym4Z_7S_y{color:rgb(var(--brand-color-white));background-color:rgb(var(--brand-color-main2));border-color:rgb(var(--brand-color-main2))}", "",{"version":3,"sources":["webpack://./packages/ui-components/src/alert-message/alert.module.css"],"names":[],"mappings":"AAAA,0CACE,gBAAiB,CACjB,aAAc,CACd,4BAA6B,CAC7B,iBACF,CAEA,kDACE,QAAS,CACT,cAAe,CACf,MAAO,CACP,OACF,CAEA,4CACE,QACF,CAEA,8CACE,cACF,CAEA,6CACE,6CAA8C,CAC9C,mDAAoD,CACpD,+CACF,CAEA,0CACE,mCAAoC,CACpC,8CAA+C,CAC/C,0CACF","sourcesContent":[".root {\r\n  padding: 4px 10px;\r\n  margin: 20px 0;\r\n  border: 1px solid transparent;\r\n  border-radius: 6px;\r\n}\r\n\r\n.stickedToTop {\r\n  top: 50px;\r\n  position: fixed;\r\n  left: 0;\r\n  right: 0;\r\n}\r\n\r\n.root > p {\r\n  margin: 0;\r\n}\r\n\r\n.root > p + p {\r\n  margin-top: 5px;\r\n}\r\n\r\n.warning {\r\n  color: rgb(var(--brand-color-bottom-bar-text));\r\n  background-color: rgb(var(--brand-color-menu-hover));\r\n  border-color: rgb(var(--brand-color-menu-hover));\r\n}\r\n\r\n.info {\r\n  color: rgb(var(--brand-color-white));\r\n  background-color: rgb(var(--brand-color-main2));\r\n  border-color: rgb(var(--brand-color-main2));\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "alert-module__root--ZX6khfaamuOb4uks2mAH",
	"stickedToTop": "alert-module__stickedToTop--oLw_mutmxTs_3sBLg6ei",
	"warning": "alert-module__warning--gG5ynJLDKijIHsH_g31R",
	"info": "alert-module__info--Zor387s3Wx0Ym4Z_7S_y"
};
export default ___CSS_LOADER_EXPORT___;
