import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import LoginRoute from './routes/login/login';
import LoginUserPass from './routes/login/login-userpass';
import { useSession } from '@lib/useSession';
import { Spinner } from '@ui/spinner';
import { suspendable } from '@lib/suspendable';
import {
  AuthNavigate,
  AuthPage,
  LoginRoutes,
  LoginPage,
  ActiveDirectoryCallbackPage,
  AuthRoutes
} from './routes/routes-config';

const LoginBankIDRoute = suspendable(
  () => import(/* webpackChunkName: "@route-login-bankid" */ './routes/login/login-bankid')
);
const LoginADRoute = suspendable(
  () => import(/* webpackChunkName: "@route-login-ad" */ './routes/login/login-active-directory')
);
const LoginConfirmRoute = suspendable(
  () => import(/* webpackChunkName: "@route-login-confirm" */ './routes/login/login-confirm')
);
const TechnicalInfoRoute = suspendable(
  () => import(/* webpackChunkName: "@route-technical-info" */ '@lib/technical-info')
);
const ActiveDirectoryCallbackSuccess = suspendable(
  () =>
    import(
      /* webpackChunkName: "route-active-directory-callback-success" */ './routes/login/callbacks/active-directory-callback-success'
    )
);

const ActiveDirectoryCallbackError = suspendable(
  () =>
    import(
      /* webpackChunkName: "route-active-directory-callback-error" */ './routes/login/callbacks/active-directory-callback-error'
    )
);

export function App() {
  const { isLoading } = useSession();
  if (isLoading) {
    return <Spinner isOverlapped={true} />;
  }

  let basePath = process.env.BASE_URL;
  if (basePath.startsWith('/')) basePath = basePath.slice(1);
  if (basePath.endsWith('/')) basePath = basePath.slice(0, -1);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={`${basePath}/*`} element={<Outlet />}>
          <Route path={`${AuthPage.Login}/*`} element={<LoginRoute />}>
            <Route index element={<LoginUserPass />} />
            <Route path={LoginPage.BankId} element={LoginBankIDRoute} />
            <Route path={LoginPage.ActiveDirectory} element={LoginADRoute} />
            <Route path={`${LoginPage.ActiveDirectoryCallback}/*`} element={<Outlet />}>
              <Route
                path={ActiveDirectoryCallbackPage.Success}
                element={ActiveDirectoryCallbackSuccess}
              />
              <Route
                path={ActiveDirectoryCallbackPage.Error}
                element={ActiveDirectoryCallbackError}
              />
              <Route path="*" element={<AuthNavigate to={LoginRoutes.ActiveDirectory} replace />} />
            </Route>
            <Route path={LoginPage.Confirm} element={LoginConfirmRoute} />

            <Route path="*" element={<AuthNavigate to={AuthRoutes.Login} replace />} />
          </Route>

          <Route path="__technical-info" element={TechnicalInfoRoute} />
          <Route path="*" element={<AuthNavigate to={AuthRoutes.Login} replace />} />
        </Route>

        <Route path="*" element={<AuthNavigate to={AuthRoutes.Login} replace />} />
      </Routes>
    </BrowserRouter>
  );
}
