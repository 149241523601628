export const CAN_DOWNLOAD_DESKTOP_CLIENT = -29;
export const CAN_WEBPORTAL_AUTH = -30;
export const CAN_PUNCH_IN_OUT = -32;
export const CAN_UPLOAD_FILES = -37;
export const CAN_EXPORT_TO_PAYROLL = -38;
export const CAN_OVERRIDE_REQUIREMENT = -39;

export const FUNCTIONAL_RIGHTS = [
  {
    id: -1,
    name: 'ShowEmployeeSalary'
  },
  {
    id: -2,
    name: 'DesktopClient_AccessSystemMenu'
  },
  {
    id: -3,
    name: 'DesktopClient_AccessImportMenu'
  },
  {
    id: -4,
    name: 'DesktopClient_AccessExportMenu'
  },
  {
    id: -5,
    name: 'DesktopClient_AccessPayrollMenu'
  },
  {
    id: -6,
    name: 'DesktopClient_AccessDoorMenu'
  },
  {
    id: -7,
    name: 'EraseStampsInLimbo'
  },
  {
    id: -8,
    name: 'AdministerScheduleTemplates'
  },
  {
    id: -9,
    name: 'DesktopClient_AccessActivePeriodSettings'
  },
  {
    id: -10,
    name: 'DesktopClient_AccessProgramSettings'
  },
  {
    id: -11,
    name: 'DesktopClient_AccessSpecialDayTypeSettings'
  },
  {
    id: -12,
    name: 'AdministerEmployeeTemplates'
  },
  {
    id: -13,
    name: 'DesktopClient_AccessUserSettings'
  },
  {
    id: -14,
    name: 'AdministerTLUCodes'
  },
  {
    id: -15,
    name: 'AdministerEmployees'
  },
  {
    id: -16,
    name: 'ChangePaymentTypeOnStamps'
  },
  {
    id: -17,
    name: 'DesktopClient_AccessEmployeeRegister'
  },
  {
    id: -18,
    name: 'DesktopClient_AccessEmployeeRegisterOverview'
  },
  {
    id: -19,
    name: 'DesktopClient_AccessEmployeeRegisterPersonalInformation'
  },
  {
    id: -20,
    name: 'DesktopClient_AccessEmployeeRegisterBaseValues'
  },
  {
    id: -21,
    name: 'DesktopClient_AccessEmployeeRegisterEmploymentPeriods'
  },
  {
    id: -22,
    name: 'DesktopClient_AccessEmployeeRegisterBaseSchedules'
  },
  {
    id: -23,
    name: 'DesktopClient_AccessEmployeeRegisterTerminalsAndDoors'
  },
  {
    id: -24,
    name: 'DesktopClient_AccessEmployeeRegisterRelatives'
  },
  {
    id: -25,
    name: 'DesktopClient_AccessEmployeeRegisterAdvanced'
  },
  {
    id: -26,
    name: 'DesktopClient_AccessEmployeeRegisterOrganisationUnits'
  },
  {
    id: -27,
    name: 'DesktopClient_AccessProgramSettingsCalculationBasedSettings'
  },
  {
    id: -28,
    name: 'DesktopClient_AccessProgramSettingsSecuritySettings'
  },
  {
    id: CAN_DOWNLOAD_DESKTOP_CLIENT,
    name: 'DownloadDesktopClient'
  },
  {
    id: CAN_WEBPORTAL_AUTH,
    name: 'WebPortal_Authentication'
  },
  {
    id: -31,
    name: 'DesktopClient_Authentication'
  },
  {
    id: CAN_PUNCH_IN_OUT,
    name: 'WebPortal_PunchInOut'
  },
  {
    id: -33,
    name: 'DesktopClient_PunchInOut'
  },
  {
    id: -34,
    name: 'UseLongTimeAccessTickets'
  },
  {
    id: -35,
    name: 'AdministerFlexBalance'
  },
  {
    id: CAN_UPLOAD_FILES,
    name: 'WebPortal_UploadFiles'
  },
  {
    id: CAN_EXPORT_TO_PAYROLL,
    name: 'PayrollSystem_Salary_Export'
  },
  {
    id: CAN_OVERRIDE_REQUIREMENT,
    name: 'Attest_Requirement_Override'
  }
];
