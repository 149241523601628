import React from 'react';
import { Button } from '../button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import ReactJson from 'react-json-view';
import styles from './error-fallback.css';

export function ErrorFallback(props: {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}) {
  const { error, componentStack, eventId } = props;

  return (
    <div className={styles.container}>
      <h2>Application crashed on uncaught exception</h2>

      <ReactJson
        name="Additional info"
        collapsed
        displayDataTypes={false}
        src={{
          error: { name: error.name, message: error.message, stack: error.stack },
          componentStack,
          eventId
        }}
      />

      <Button
        className={styles.reload}
        value="Reload page"
        icon={<FontAwesomeIcon icon={faRotateRight} />}
        onClick={() => location.reload()}
      />
    </div>
  );
}
