import { apiUrl1, authHTTPClient } from './auth-client';

export interface IBrandColor {
  Name: string;
  Value: string;
}

export async function fetchBrandColors() {
  return await authHTTPClient.get<IBrandColor[]>(`${apiUrl1}/users/current/brandColors`);
}
