import {
  CAN_DOWNLOAD_DESKTOP_CLIENT,
  CAN_EXPORT_TO_PAYROLL,
  CAN_WEBPORTAL_AUTH
} from './functional-rights';
import moment, { Moment, MomentInput } from 'moment';

export const SERVER_ERRORS_REGEXP = new RegExp(`401 Not authorized|404 Not Found`, 'mi');

export const CAN_FAST_FORWARD = 1;
export const WINDOWS_PLATFORM_VALUES = ['Win32', 'Win64', 'Windows', 'WinCE'];
export const SESSION_CACHE_NAME = 'tiltid_auth_portal-session';
export const SERVICE_INSTANCE_NAME = 'TILTid_WindowsService';

export const ROLE_TYPES = ['user', 'support', 'admin'];
export const SYSTEM_ID = -1;

export default {
  ROLE_TYPES,
  SYSTEM_ID
};

export enum Step {
  ChooseDates = 1,
  SetOrgUnits = 2,
  MakeConfirm = 3,
  ProcessExport = 4
}

export const DATE_FORMAT = 'yyyy-MM-dd';
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const REVERTED_DATE_FORMAT = 'DD MMMM YYYY';
export const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const DATETIMESECS_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const PERIOD_DATE_FORMAT = 'MMMM yyyy';
export const TIME_FORMAT = 'HH:mm';
export const SHORT_TIME_FORMAT = 'H:mm';
export const ERROR_CODE_EXPORT_STARTED = 429;
export const FULL_DATE_FORMAT = 'D MMMM YYYY';
export const PARENT_SELECT_ID = '-1';
export const DEFAULT_TIME = '00:00';
export const TIME_MASK = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
export const SERVER_TIMEZONE = 'Europe/Stockholm';

export function checkFuncRights(funcRights: number[]) {
  const showPayroll = funcRights.includes(CAN_EXPORT_TO_PAYROLL);
  const showWebClient = funcRights.includes(CAN_WEBPORTAL_AUTH);

  const canDownloadDesktopClient = funcRights.includes(CAN_DOWNLOAD_DESKTOP_CLIENT);
  const showDownload =
    window && WINDOWS_PLATFORM_VALUES.includes(navigator.platform) && canDownloadDesktopClient;
  const permissions = [showPayroll, showWebClient, showDownload];

  const hasAccessOnlyToOneApp = permissions.filter(i => i === true).length === 1;

  return {
    showPayroll,
    showWebClient,
    showDownload,
    hasAccessOnlyToOneApp
  };
}

export function getFilename(disposition: string) {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  let matches = null;

  if (disposition && disposition.indexOf('attachment') !== -1) {
    matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }

  return '';
}

export function camalize(str: string) {
  if (!str?.length) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function normalizeObject(data: any) {
  let key,
    keys = Object.keys(data);
  let n = keys.length;
  const normalized: any = {};

  while (n--) {
    key = keys[n];
    normalized[key.charAt(0).toLowerCase() + key.slice(1)] = data[key];
  }

  return normalized;
}

export function normalizeArray(data: any) {
  const normalized: any[] = [];

  data.forEach((el: any) => {
    const n: { [key: string]: number } = {};
    Object.keys(el).forEach(key => (n[key.charAt(0).toLowerCase() + key.slice(1)] = el[key]));
    normalized.push(n);
  });

  return normalized;
}

const BASE32_ENCODE_CHAR = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567'.split('');

export function encodeBase32(str: string) {
  let result = '';
  const length = str.length;
  const count = parseInt((length / 5).toString()) * 5;
  let i = 0;
  let v1, v2, v3, v4, v5;

  while (i < count) {
    v1 = str.charCodeAt(i++);
    v2 = str.charCodeAt(i++);
    v3 = str.charCodeAt(i++);
    v4 = str.charCodeAt(i++);
    v5 = str.charCodeAt(i++);
    result +=
      BASE32_ENCODE_CHAR[v1 >>> 3] +
      BASE32_ENCODE_CHAR[((v1 << 2) | (v2 >>> 6)) & 31] +
      BASE32_ENCODE_CHAR[(v2 >>> 1) & 31] +
      BASE32_ENCODE_CHAR[((v2 << 4) | (v3 >>> 4)) & 31] +
      BASE32_ENCODE_CHAR[((v3 << 1) | (v4 >>> 7)) & 31] +
      BASE32_ENCODE_CHAR[(v4 >>> 2) & 31] +
      BASE32_ENCODE_CHAR[((v4 << 3) | (v5 >>> 5)) & 31] +
      BASE32_ENCODE_CHAR[v5 & 31];
  }

  switch (length - count) {
    case 1:
      v1 = str.charCodeAt(i);
      result += BASE32_ENCODE_CHAR[v1 >>> 3] + BASE32_ENCODE_CHAR[(v1 << 2) & 31];
      break;
    case 2:
      v1 = str.charCodeAt(i++);
      v2 = str.charCodeAt(i);
      result +=
        BASE32_ENCODE_CHAR[v1 >>> 3] +
        BASE32_ENCODE_CHAR[((v1 << 2) | (v2 >>> 6)) & 31] +
        BASE32_ENCODE_CHAR[(v2 >>> 1) & 31] +
        BASE32_ENCODE_CHAR[(v2 << 4) & 31];
      break;
    case 3:
      v1 = str.charCodeAt(i++);
      v2 = str.charCodeAt(i++);
      v3 = str.charCodeAt(i);
      result +=
        BASE32_ENCODE_CHAR[v1 >>> 3] +
        BASE32_ENCODE_CHAR[((v1 << 2) | (v2 >>> 6)) & 31] +
        BASE32_ENCODE_CHAR[(v2 >>> 1) & 31] +
        BASE32_ENCODE_CHAR[((v2 << 4) | (v3 >>> 4)) & 31] +
        BASE32_ENCODE_CHAR[(v3 << 1) & 31];
      break;
    case 4:
      v1 = str.charCodeAt(i++);
      v2 = str.charCodeAt(i++);
      v3 = str.charCodeAt(i++);
      v4 = str.charCodeAt(i);
      result +=
        BASE32_ENCODE_CHAR[v1 >>> 3] +
        BASE32_ENCODE_CHAR[((v1 << 2) | (v2 >>> 6)) & 31] +
        BASE32_ENCODE_CHAR[(v2 >>> 1) & 31] +
        BASE32_ENCODE_CHAR[((v2 << 4) | (v3 >>> 4)) & 31] +
        BASE32_ENCODE_CHAR[((v3 << 1) | (v4 >>> 7)) & 31] +
        BASE32_ENCODE_CHAR[(v4 >>> 2) & 31] +
        BASE32_ENCODE_CHAR[(v4 << 3) & 31];
      break;
  }

  return result;
}

export function toMomentOrUndefined(input?: MomentInput) {
  return input ? moment(input) : undefined;
}

export function isDateRangeInvalid(startDate: Moment, endDate: Moment) {
  return (
    !startDate ||
    !startDate.isValid() ||
    !endDate ||
    !endDate.isValid() ||
    startDate.isAfter(endDate)
  );
}

export interface IAdminPageFilter {
  [x: string]: any[];
}

export function compareByAsc(key: any) {
  return (a: any, b: any) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };
}

export function compareByDesc(key: any) {
  return (a: any, b: any) => {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  };
}
