import React, { ComponentType, lazy, Suspense } from 'react';

export function suspendable<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  props?: any
) {
  const Component = lazy(factory);
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
}
