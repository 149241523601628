import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { APP_NAME } from './constants/storage-keys';

type WatchCallbackFn<T> = (data: T | null) => void;
type WatchUnsubscribeFn = () => void;

interface IStorageContext {
  getItem<T>(key: string): T | null;
  setItem<T>(key: string, value: T): void;
  removeItem(key: string): void;
  getFullKey(key: string): string;
  watch<T>(key: string, callback: WatchCallbackFn<T>): WatchUnsubscribeFn;
}

export const StorageContext = createContext<IStorageContext>(null as any);

export function StorageProvider(props: any) {
  const t = new Set<() => void>();
  const [watchItems, setWatchItems] = useState(new Map<string, Set<WatchCallbackFn<any>>>());

  useEffect(() => {
    const onStorageUpdated = (e: StorageEvent) => {
      if (!e.key) {
        // Storage was cleared
        for (const [key, listeners] of watchItems) {
          for (const callback of listeners) {
            callback(null);
          }
        }
      } else {
        // Value was modified
        const listeners = watchItems.get(e.key);
        if (listeners) {
          for (const callback of listeners) {
            callback(e.newValue ? JSON.parse(e.newValue) : null);
          }
        }
      }
    };

    window.addEventListener('storage', onStorageUpdated);
    return () => {
      window.removeEventListener('storage', onStorageUpdated);
    };
  }, [watchItems]);

  const storage = useMemo(() => {
    const getFullKey = (key: string): string => `${APP_NAME}:${key}`;

    const getItem = <T,>(key: string): T | null => {
      const existingItem = window.localStorage.getItem(getFullKey(key));
      return existingItem ? JSON.parse(existingItem).data : null;
    };

    const setItem = <T,>(key: string, value: T): void => {
      const data = JSON.stringify({ data: value });
      window.localStorage.setItem(getFullKey(key), data);
    };

    const removeItem = (key: string): void => window.localStorage.removeItem(getFullKey(key));

    const watch = <T,>(key: string, callback: WatchCallbackFn<T>): WatchUnsubscribeFn => {
      const fullKey = getFullKey(key);

      setWatchItems(current => {
        const result = new Map(current);
        const listeners = result.get(fullKey);
        if (!listeners) {
          result.set(fullKey, new Set([callback]));
        } else {
          listeners.add(callback);
        }

        return result;
      });

      return () => {
        setWatchItems(current => {
          const result = new Map(current);
          result.get(fullKey)?.delete(callback);
          return result;
        });
      };
    };

    return {
      getFullKey,
      getItem,
      setItem,
      removeItem,
      watch
    };
  }, []);

  return <StorageContext.Provider value={storage}>{props.children}</StorageContext.Provider>;
}

export function useStorage() {
  return useContext(StorageContext);
}
