import React, { createContext, useContext } from 'react';

export enum Feature {
  Navbar = 'ENABLE_NAVBAR',
  AutoAuthenticated = 'ENABLE_AUTO_AUTHENTICATION'
}

type EnabledFeatures = { [key in Feature]: boolean };

interface IFeatureFlagsContext {
  features: EnabledFeatures;
  hasFeature(feature: Feature): boolean;
  hasFeatures(features: Feature[]): boolean;
}

const env = {
  ENABLE_NAVBAR: process.env.ENABLE_NAVBAR,
  ENABLE_AUTO_AUTHENTICATION: process.env.ENABLE_AUTO_AUTHENTICATION
};

const FeatureFlagsContext = createContext<IFeatureFlagsContext>(null as any);

export function FeatureFlagsContextProvider(props: { children: JSX.Element }) {
  const enabledFeatures: any = {};

  Object.values(Feature).forEach(value => {
    enabledFeatures[value] = env[value] === 'true';
  });

  function hasFeature(feature: Feature) {
    return enabledFeatures[feature];
  }

  function hasFeatures(fs: Feature[]) {
    const enabledFlags = fs.map(flag => enabledFeatures[flag]);
    return enabledFlags.every(Boolean);
  }

  return (
    <FeatureFlagsContext.Provider value={{ features: enabledFeatures, hasFeature, hasFeatures }}>
      {props.children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlagsContext() {
  const data = useContext(FeatureFlagsContext);

  if (!data) {
    throw new Error(`You should use useFeatureFlagsContext only with FeatureFlagsContextProvider`);
  }

  return data;
}
