export default {
  common: {
    slogan: 'Effective time management. Increased profitability.',
    TilTid: 'TIL-Tid',
    tryLater: 'Try again later.',
    TTPC_client: 'TIL-Tid PC client',
    startTT: 'Start TIL-Tid',
    send: 'Send',
    select: 'Select',
    selectAll: 'Select all',
    orgUnit: 'Organization unit',
    orgUnits: 'Organization units',
    presenceTable: 'Presence table',
    back: 'Back',
    toggleNavigation: 'Toggle navigation',
    all: 'All',
    changePassword: 'Change password',
    profile: 'Profile',
    save: 'Save',
    upload: 'Upload',
    pleaseWait: 'Please, wait...',
    processing: 'Processing...',
    loading: 'Loading...',
    id: 'Id',
    name: 'Name',
    city: 'City',
    country: 'Country',
    search: 'Search',
    description: 'Description',
    close: 'Close',
    confirm: 'Confirm',
    nothingToShow: 'Nothing to show',
    greeting: 'Hello',
    yes: 'Yes',
    no: 'No',
    thereIsNothing: 'There is nothing to show',
    delete: 'Delete',
    create: 'Create',
    saved: 'Saved',
    dontAskAgain: "Don't ask again",
    ok: 'OK',
    cancel: 'Cancel',
    from: 'From',
    to: 'To'
  },
  cookies: {
    siteUsesCookies:
      'This site uses cookies to provide the necessary ' +
      'functionality and provide a good user experience.',
    assumeCookiesUsage:
      'By continuing to use, or log on to this site assumes that ' +
      'you accept the use of cookies on this site.',
    iUnderstand: 'I understand'
  },
  error: {
    UnexpectedError: 'Unexpected error',
    AuthorizationError: 'Authorization error',
    ValidationError: 'Validation error',
    AuthenticationError: 'Authentication error.',
    tryAgain: 'Please try later',
    notFound: '404 Not Found',
    couldntFindPage: `Sorry, we couldn't find that page.`
  },
  copyright: {
    copyright: 'Copyright',
    companyName: 'SEV Tidsystem AB',
    rightsReserved: 'All rights reserved.'
  },
  login: {
    signIn: 'Sign in',
    logIn: 'Log in',
    login: 'Login',
    bankId: {
      name: 'Bank ID',
      mobileAuth: 'Mobile BankID',
      authOnOtherDevice: 'Mobile BankID on other device',
      cancel: 'Cancel login',
      tryAgain: 'Try again',
      noInstances: 'You are not allowed to log into any customer instance using BankID.',
      RFA1: 'Start the BankID app and press the Scan QR button. Then scan the QR code above.',
      RFA2: 'The BankID app is not installed. Please contact your bank.',
      RFA3: 'Action cancelled. Please try again.',
      RFA4: 'An identification or signing for this personal number is already started. Please try again.',
      RFA5: 'Internal error. Please try again.',
      RFA6: 'Action cancelled.',
      RFA8:
        "The BankID app is not responding. Please check that it's started and that you have internet access. " +
        "If you don't have a valid BankID you can get one from your bank. Try again.",
      RFA9: 'Enter your security code in the BankID app and select Identify or Sign.',
      RFA13: 'Trying to start your BankID app.',
      RFA14_A:
        'Searching for BankID, it may take a little while...\r\nIf a few seconds have passed and still no ' +
        "BankID has been found, you probably don't have a BankID which can be used for this identification/signing " +
        "on this computer. If you have a BankID card, please insert it into your card reader. If you don't have " +
        'a BankID you can get one from your bank. If you have a BankID on another device you can start the BankID app on that device.',
      RFA14_B:
        'Searching for BankID, it may take a little while...\r\nIf a few seconds have passed and still no ' +
        "BankID has been found, you probably don't have a BankID which can be used for this identification/signing " +
        "on this device. If you don't have a BankID you can get one from your bank. If you have a BankID on another " +
        'device you can start the BankID app on that device.',
      RFA15_A:
        'Searching for BankID:s, it may take a little while...\r\nIf a few seconds have passed and still no ' +
        "BankID has been found, you probably don't have a BankID which can be used for this identification/signing " +
        "on this computer. If you have a BankID card, please insert it into your card reader. If you don't have " +
        'a BankID you can get one from your bank.',
      RFA15_B:
        'Searching for BankID, it may take a little while\r\nIf a few seconds have passed and still no ' +
        "BankID has been found, you probably don't have a BankID which can be used for this identification/signing " +
        "on this device. If you don't have a BankID you can get one from your bank.",
      RFA16:
        'The BankID you are trying to use is blocked or too old. Please use another BankID or get a new one from your bank.',
      RFA17_A:
        "The BankID app couldn't be found on your computer or mobile device. Please install it and get a BankID from your bank. " +
        'Install the app from your app store or <a href="https://install.bankid.com" target="_blank">https://install.bankid.com</a>.',
      RFA17_B:
        'Failed to scan the QR code. Start the BankID app and scan the QR code. Check that the BankID app is up to date. ' +
        "If you don't have the BankID app, you need to install it and get a BankID from your bank. " +
        'Install the app from your app store or <a href="https://install.bankid.com" target="_blank">https://install.bankid.com</a>.',
      RFA18: 'Start the BankID app.',
      RFA19:
        'Would you like to identify yourself or sign with a BankID on this computer, or with a Mobile BankID?',
      RFA20:
        'Would you like to identify yourself or sign with a BankID on this device, or with a BankID on another device?',
      RFA21: 'Identification or signing in progress.',
      RFA22: 'Unknown error. Please try again.',
      RFA23: 'Process your machine-readable travel document using the BankID app.'
    },
    ad: 'AD',
    customerId: 'Customer-id',
    username: 'Username',
    password: 'Password',
    forgotPassword: 'I forgot my password',
    personalNumber: 'Personal Number',
    webClient: 'TIL-Tid Web',
    windowsClient: 'Windows Client',
    payrollExport: 'Payroll Export',
    authPortal: 'Start Page',
    insertSecurityCode: 'Please insert Security Code at your BankID app',
    redirectBackTo: 'Redirecting back to {{ to }}...',
    updateTemporaryPassword: 'The current password is temporary and a new one must now be entered.',
    passwordRecovery: {
      forgotPassword: 'Have you forgotten your password?',
      enterDetails: `Enter the details below and we'll send a new password to your email address.`,

      newPassSent: 'A new password has been sent',
      newPassSentMsg1:
        'The password has been sent to your e-mail address ' + 'associated with your User account.',
      newPassSentMsg2:
        'If no email has arrived, wait a few minutes and check your email again. ' +
        'Also check that the email is not deleted as spam.',
      newPassSentMsg3: 'If you need help or have questions, please contact our support.'
    },
    errors: {
      customerIdEmpty: 'Customer ID is required',
      usernameEmpty: 'Enter your username or e-mail',
      passwordEmpty: 'Enter your password',
      personalNumber: 'Enter your personal number',
      confirmationCodeEmpty: 'Enter the confirmation code',
      unexpectedError: 'An unexpected problem arose.',
      invalidCredentials: 'The user with specified credentials was not found.',
      blocked: 'Too many unsuccessful attempts.',
      banned: 'The user is banned. Please, contact your administrator.',
      invalidConfirmationCode: 'Entered confirmation code is not valid.',
      incorrectCredentials:
        'The entered information is not valid login information. Please try again.',
      wrongUser: 'User is not found',
      unableToResetPassword: 'This password can not be reseted.',
      unableToResetADPassword: "Can't reset password for active directory login",
      invalidCustomer: 'This Customer is not valid. Please try again.',
      invalidUser: 'This User is not valid. Please try again.',
      invalidDevice: 'This device is not valid. Please choose another device',
      noAccessToWebclient: 'Incorrect user information'
    },
    twoFactor: {
      enterCode: 'Please enter the confirmation code that was sent to {{ phone }}',
      confirmationCode: 'Confirmation code',
      codeExpired: 'Confirmation code has expired',
      selectCustomerInstance: 'Please select the desired customer instance to continue.',
      enterPin: 'Please enter the confirmation code from your authenticator app',
      selectDevice: 'Please select the device which you are using to obtain a code:',
      sendSms: 'Alternatively, get a code via a message to {{ phone }}'
    },

    logOut: 'Log out'
  },
  clientDownload: {
    downloadStarted: 'Beginning download...'
  },
  orgUnits: {
    selectOrgUnitTitle: 'Organization unit',
    selectOrgUnit: 'Please select an organization unit you would like to use:',
    onlyOne: 'There is only one organization unit in your system.',
    orgUnitRequired: 'Select an organization unit to proceed.',
    thereIsNothing: 'There is nothing to show',
    thereIsNoData: 'There is no data for the current organization unit',
    showHiddenUnits: 'Show hidden units'
  },
  presenceTable: {
    costAccount: 'Cost Account',
    statuses: {
      working: 'Working',
      resting: 'Resting',
      away: 'Away'
    },
    sum: 'Sum',
    notChecked: 'Not checked',
    showAbsent: 'Show absent employees',
    lastUpdatedAt: 'Last updated at',
    showAll: 'Show all',
    collapse: 'Collapse'
  },
  models: {
    employee: {
      noPhoneNumber: 'No phone numbers.'
    },
    employeePresence: {
      onBreak: 'On break',
      absent: 'Absent',
      outsideSchedule: 'Working (not scheduled)',
      onSchedule: 'Working'
    }
  },
  statuses: {
    youArePunchedIn: 'You are punched in',
    youAreNotPunchedIn: 'You are punched out',
    punchIn: 'Punch in',
    punchOut: 'Punch out',
    punchedIn: 'Punched in',
    punchedOut: 'Punched out',
    pleaseConfirm: 'Please confirm',
    success: 'Success',
    punchOutToABreak: 'Punch out to a break',
    errorNoTerminalUnit:
      "You can't punch in because this organization unit doesn't have an active terminal unit."
  },
  installationGuide: {
    downloadingStarted: 'Downloading has started. Your file will be available shortly.',
    toContinueFollowTheseSteps: 'To continue the installation, please follow these steps:',
    pressSaveEdgeIe: 'Press Save in the popup in the bottom of the screen, when prompted;',
    pressSaveMozilla: 'Press Save File in the popup window when prompted;',
    pressSaveOpera: 'Save the installer to the desired directory when prompted;',
    runInstallerEdge:
      'Press Run when you are notified that ' +
      'the downloading has finished after the security scan is performed;',
    runInstallerIe: 'Press Run when you are notified that the downloading has finished;',
    runInstallerChrome:
      'After your installer has been downloaded, ' +
      'click on the button in the bottom of your screen to run the .exe file;',
    runInstallerMozillaOpera:
      'Click on the downloaded .exe file ' +
      'in the list of your downloads in the upper right part of your screen;',
    chooseDirectory:
      '(Optional) Choose a desired directory to install ' +
      'TIL-Tid client application. We recommend leaving the default directory;',
    installAndStart: 'Press "Start TIL-Tid".',
    congratulations:
      'After this, your installer will fetch the latest ' +
      'available TIL-Tid PC client, and will start automatically afterwards. ' +
      'Congratulations, you are all set now!',
    back: 'Go back to main page'
  },
  password: {
    newPassword: 'New password',
    newPassword2: 'New password one more time',
    yourPasswordWasReset: 'Your password was reset. Please set a new one now.',
    passwordHasBeenChanged: 'Your password has been changed.',
    save: 'Save',
    areYouSure: "You haven't entered a new password, exit the application?",
    errors: {
      passwordRequired: 'The password is required.',
      password2Required: 'Please enter the password one more time.',
      passwordsNotMatch: 'The passwords not match',
      passwordInvalidFormat: 'Please match the requested format.'
    },
    passwordRulesText:
      'Password should be a minimum of {{ length }} characters in length, contain at least {{ capLetters}} ' +
      ' uppercase letters (A-Z), {{ lowLetters}} lowercase letters (a-z), {{ numbers}} digits (0-9), ' +
      '{{ chars }} special characters.'
  },
  session: {
    updating: 'Updating...',
    maintenanceWindow: 'Next maintenance window',
    maintenanceMessage: 'Description',
    alert: {
      upcomingMaintenance: {
        caption: 'Information about maintenance work',
        message:
          '\nThe maintenance of TIL-Tid will begin at {{startTime}}.\nSave your work and log out of TIL-Tid as ' +
          'soon as possible.\n\n\tMaintenance work is scheduled between {{startTime}} - {{endTime}}.\n\tMessage: {{message}}' +
          '\n\nEfforts is constantly underway to keep TIL-Tid updated to ensure that the experience is as good as possible.' +
          '\nThank you in advance.'
      },
      extendFail: {
        caption: 'Failed to extend session',
        message:
          "\nAn error occurred during session extending.\n\nYou'll be logged out at {{endOfLife}}. " +
          'Please save all your work to prevent any data loss.'
      }
    }
  },
  fileUpload: {
    upload: 'Upload file',
    chooseFile: 'Choose file',
    noFileChosen: 'No file chosen',
    fileUploaded: 'The file was successfully uploaded'
  },
  attested: {
    attestedTitle: 'Attested',
    orgUnitId: 'Org Unit ID',
    orgNumber: 'Org number',
    unitNumber: 'Unit number',
    orgUnits: 'Org units',
    name: 'Name',
    city: 'City',
    selected: 'Selected',
    btnDelete: 'Delete',
    filterList: 'Filter list',
    dateFrom: 'from',
    dateTo: 'to',
    filterStatusAll: 'All',
    filterStatusUpToSelected: 'Attested up to ',
    filterStatusUpToNotSelected: 'Not attested up to ',
    askPayrollCreationTitle: 'Do you really want to start?',
    askPayrollCreationContent:
      'Payroll creation operation may take a long time. Please, be carefull.',
    askPayrollCreationConfirm: 'Confirm',
    askPayrollCreationCancel: 'Cancel',
    noPeriods: 'No periods created',
    description: 'Description',
    exportedFilter: 'Exported',
    notExportedFilter: 'Not exported',
    exportedTooltipHeader: 'Salary basis has been exported for',
    advancedDetails: 'Details / Advanced',
    advanceDetailsHeader: 'Period',
    fileName: 'Filename',
    bundleNum: 'Bundle no',
    companyNum: 'Company no',
    unitNum: 'Unit no',
    selectEmployees: 'Choice of persons',
    selectAllEmployees: 'All employees',
    selectEmployeesByFilter: 'Employees according to filter',
    level: 'Certification level',
    attestName: 'Certification level',
    attestDate: 'Attest date',
    attestStatus: 'Show',
    noResults: 'No results',
    attestedBy: 'Certified by',
    attestTime: 'Time for certification',
    attestedUpTo: 'Certified up to',
    attestReport: 'Attest report',
    search: 'Search',
    searchByOrgunit: 'Search by org unit',
    all: 'All'
  },
  navigation: {
    logout: 'Logout',
    exit: 'Exit',
    authPortal: 'Start Page',
    webClient: 'Til-Tid Web',
    changePassword: 'Change password',
    customerInstances: 'Customers instances',
    maintenanceWindows: 'Maintenance windows',
    apiDocumentation: 'API Documentation',
    admin: {
      indexPage: 'Index page',
      userManagement: 'User man<u>{{key}}</u>gement',
      instanceManagement: '<u>{{key}}</u>nstance management',
      customerInstances: 'Customer insta<u>{{key}}</u>ces',
      maintenanceWindows: 'Main<u>{{key}}</u>enance windows',
      backups: 'Backups',
      customerInstancesStatuses: 'Customer instances statuses',
      terminalStatuses: 'Terminal statuses',
      conversions: 'Conversions',
      serverManagement: 'S<u>{{key}}</u>rver management',
      hosts: 'Hosts',
      ports: 'Ports',
      scripts: 'Scripts',
      packageManagement: 'Pac<u>{{key}}</u>age management',
      packages: 'Packages',
      filePaths: 'File paths',
      bootstrap: 'Bootstrap',
      sessions: 'Sessi<u>{{key}}</u>ns',
      hostSessions: 'Host sessions',
      systemSessions: 'System sessions',
      customerInstanceSessions: 'Customer instance sessions',
      logs: 'Logs',
      logout: '<u>{{key}}</u>ogout'
    },
    webShop: {
      title: 'Web Shop',
      tooltipText:
        'Feel free to visit our new web shop where you can read more about and order both services and consumables',
      tooltipButton: 'Thanks, now I know'
    }
  },
  notFound: {
    notFoundTitle: 'Not found',
    pageHeader: 'Oops!',
    pageSubHeader: 'Page is not found.'
  },
  payrollWizard: {
    payrollWizard: 'Payroll Creation Wizard',
    wizardStepDates: 'Select date range',
    wizardStepUnits: 'Select org units',
    wizardStepConfirm: 'Confirm',
    wizardStepProcess: 'Process',
    back: 'Back',
    next: 'Next',
    wizardReset: 'Create another payroll',
    currentStores:
      'The creation of payroll system files will be created for the <b cy-id="confirm-orgUnits-sum">{{ stores }}</b>',
    currentStoreName: 'selected stores - ',
    currentPeriod: 'The chosen period is ',
    currentPayTypes: 'The chosen pay types - ',
    pressNext: 'Press NEXT to start the export',
    pressBack: 'Press BACK',
    orgUnitsHasWarnings:
      'Data for the following orgunits is not allowed to be exported due to violation of required attest level',
    suppres: 'Suppres',
    send: 'Send',
    cancel: 'Cancel',
    ok: 'OK',
    start: 'Start',
    specifyReason: 'Please specify the reason',
    errorOccurred: 'Error occurred',
    errorRequestAxfoodPayrollExport:
      // tslint:disable-next-line:quotemark
      "The export couldn't be started since an export started earlier is still running",
    errorStartPayrollExport: 'Error occured when start payroll export',
    downloadExportFiles: 'Download payroll export files',
    errorNothingToDownload: 'There are no files to download',
    download: 'Download',
    checkDownload: 'Please select what you want to download',
    // tslint:disable-next-line:quotemark
    payrollFormatIsEmpty: "You don't have payroll format, so you can't export payroll",
    payrollCrashNetwork:
      'The contact with the service has been broken due to an unknown reason. Attempts are done to reconnect',
    payrollCrashJobNotQueued: 'The export could not start for unknown reasons',
    payrollCrashInvalidParameters: 'The export could not start due to incorrect call',
    payrollCrashUnauthorized: 'The export could not start as login is missing',
    selectAll: 'Select all files'
  },
  payrollLoading: {
    payrollCreation: 'Payroll creation progress',
    payrollStatus: 'Payroll creation status',
    progressStarted: 'Export started',
    progressInProgress: 'In progress...',
    progressFinished: 'Creation of payroll transaction file (s) succeeded',
    progressCrashed:
      'Something goes wrong. Please press Back to proceed to the previous step and try again',
    exportStuck: 'Please reload this page',
    reload: 'Reload'
  },
  payperiods: {
    confirmationContent: 'Please confirm delete',
    agree: 'Agree',
    disagree: 'Disagree',
    period: 'Period',
    description: 'Description',
    btnAdd: 'Create a new payroll file',
    errors: {
      cantCreatePeriod: 'Error when creating pay pariod',
      chooseAnotherPeriod:
        'Chosen dates are overlapped to existing pay periods. Please choose another period',
      cantCreateExport: 'Error when creating export'
    }
  },
  birthdays: {
    reminders: 'Reminders',
    noReminders: 'No reminders',
    birthday: 'Birthday',
    specialDay: 'Special day',
    years: 'years',
    remember: 'Remember',
    campaign: 'Campaign'
  },
  menu: {
    planningView: 'Planning view',
    regEditorView: 'Registration editor',
    messages: 'Messages'
  },
  forecast: {
    clearSky: 'Clear sky',
    nearlyClearSky: 'Nearly clear sky',
    variableCloudiness: 'Variable cloudiness',
    halfclearSky: 'Halfclear sky',
    cloudySky: 'Cloudy sky',
    overcast: 'Overcast',
    fog: 'Fog',
    lightRainShowers: 'Light rain showers',
    moderateRainShowers: 'Moderate rain showers',
    heavyRainShowers: 'Heavy rain showers',
    thunderstorm: 'Thunderstorm',
    lightSleetShowers: 'Light sleet showers',
    moderateSleetShowers: 'Moderate sleet showers',
    heavySleetShowers: 'Heavy sleet showers',
    lightSnowShowers: 'Light snow showers',
    moderateSnowShowers: 'Moderate snow showers',
    heavySnowShowers: 'Heavy snow showers',
    lightRain: 'Light rain',
    moderateRain: 'Moderate rain',
    heavyRain: 'Heavy rain',
    thunder: 'Thunder',
    lightSleet: 'Light sleet',
    moderateSleet: 'Moderate sleet',
    heavySleet: 'Heavy sleet',
    lightSnowfall: 'Light snowfall',
    moderateSnowfall: 'Moderate snowfall',
    heavySnowfall: 'Heavy snowfall'
  },
  alert: {
    btnOk: 'OK',
    btnCancel: 'Cancel',
    btnAbort: 'Abort',
    btnRetry: 'Retry',
    btnIgnore: 'Ignore',
    btnYes: 'Yes',
    btnNo: 'No'
  },
  dimension: {
    timeAccount: 'Time Account',
    department: 'Department',
    costAccount: 'Cost Account',
    legalPerson: 'Legal Person'
  },
  dimensionHierarchy: {
    caption: 'Dimensions hierarchy manager',
    selectDimension: 'Select dimension',
    hierarchy: 'Hierarchy',
    buttons: {
      save: 'Save changes',
      discard: 'Discard changes'
    },
    alert: {
      unsavedChanges: {
        caption: 'Unsaved changes',
        message:
          'You have unsaved changes which will be lost.\nAre you sure you want to discard these changes?'
      },
      discardChanges: {
        caption: 'Discarding changes',
        message: 'All unsaved changes will be lost. Do you want to continue?'
      },
      invalidTemplate: {
        caption: 'Invalid aggregation template',
        emptyName:
          'Template name is a required field and cannot be empty. Please specify template name.',
        nameIsTaken:
          'Template with the name "{{name}}" already exists. Please specify another name.',
        emptyDimensionValues:
          'Aggregation template must contain at least one dimension value. Please specify aggregated dimensions or fully remove the template.'
      },
      affectedTemplates: {
        caption: 'Aggregation templates are affected',
        message:
          'Dimension "{{name}}" is linked in the next aggregation templates:\n\n\t{{templateNames}}\n\n' +
          'Changing its position in hierarchy will lead to erasing it from templates listed above.\nDo you want to continue?'
      },
      emptyTemplates: {
        caption: 'Some aggregation templates are empty',
        message:
          'Next aggregation templates:\n\n\t{{templateNames}}\n\ndo not contain any aggregation rules and will be fully removed.\nDo you want to continue?'
      }
    }
  },
  aggregationTemplates: {
    caption: 'Aggregation templates',
    name: 'Name',
    description: 'Description',
    rulesList: 'Aggregation rules:',
    setupRulesTip: 'Set up aggregation rules by selecting dimensions in a tree',
    buttons: {
      add: 'Add template',
      edit: 'Edit template',
      delete: 'Delete template',
      ok: 'OK',
      cancel: 'Cancel'
    }
  },
  tluCodes: {
    showExtCode: 'Show cost account',
    showName: 'Show name only',
    showSpeedCode: 'Show speed code'
  },
  sort: {
    sorting: 'Sorting',
    sortBySpeedCode: 'Speed code',
    sortByFirstNameAndLastName: 'First name, Last name',
    sortByLastNameAndFirstName: 'Last name, First name',
    sortByEmployeeNumber: 'Employee number',
    sortByDepartmentAndSpeedCode: 'Department, Speed code',
    sortByCostAccountAndSpeedCode: 'Cost account, Speed code',
    sortByHourlyWage: 'Hourly wage',
    sortByDepartmentAndFirstName: 'Department, First name',
    sortByDepartmentAndLastName: 'Department, Last name',
    sortByDepartmentAndEmployeeNumber: 'Department, Employee number',
    sortByDepartmentAndCostAccountAndSpeedCode: 'Department, Cost account, Speed code',
    sortByDepartmentAndCostAccountAndName: 'Department, Cost account, name',
    sortByDepartmentAndCostAccountAndEmployeeNumber: 'Department, Cost account, Employee number',
    sortByCostAccountAndLastName: 'Cost account, Last name',
    sortByCostAccountAndFirstName: 'Cost account, First name',
    sortByCostAccountAndEmployeeNumber: 'Cost account, Employee number',
    sortByCostAccountAndDepartmentAndSpeedCode: 'Cost account, Department, Speed code',
    sortByCostAccountAndDepartmentAndName: 'Cost account, Department, Name',
    sortByCostAccountAndDepartmentAndEmployeeNumber: 'Cost account, Department, Employee number',
    sortByBirthday: 'Birthday',
    sortByBirthdayNoYear: 'Birthday day'
  },
  planning: {
    ok: 'OK',
    nav: 'Planning',
    modalHeader: 'Planning, filter',
    date: 'Date',
    numberOfWeeks: 'Number of weeks',
    selection: 'Selection',
    allEmployees: 'All employees',
    dimensionCodes: 'Time account type',
    dimensionValues: 'Time account',
    allDimensionCodes: 'All time account types',
    allDimensionValues: 'All time accounts',
    employee: 'Person',
    all: 'Everyone',
    sortingScheme: 'Sorting scheme',
    cardNumber: 'Card number',
    from: 'From',
    to: 'To',
    sortAgain: 'Sort again',
    changeWeek: 'Change week',
    employeeSubTitle: 'Work: {{ workTime }} Rest: {{ breakTime }} Paid time: {{ paidTime }}',
    back: 'Back',
    next: 'Next',
    scheduleSettingHeader: 'Schedule setting',
    interval: 'Interval',
    min: 'min',
    zoom: 'Zoom',
    searchPerson: 'Search person',
    costAccounts: 'Cost accounts',
    allCostAccounts: 'All cost accounts',
    weeklyTime: 'Weekly work time',
    chooseDate: 'Choose day',
    week: `Week: {{ weekNumber }}`,
    showEmployeeDetails: 'Show employee details',
    address: 'Address',
    postNumber: 'Post number',
    county: 'County',
    telephone: 'Telephone number',
    mobile: 'Mobile number',
    email: 'Email',
    employment: 'Form of employment',
    employmentDate: 'Date of employment',
    hourWeek: 'Hour/week',
    relatives: 'Closest relatives',
    showStaffingTable: 'Show staffing table',
    staffingTable: 'Staffing tables',
    fullTimeEmployment: 'Full time',
    partTimeEmployment: 'Part time',
    totalForUnit: 'Total for unit',
    totalPaidTime: 'Total paid time',
    actualTable: 'Actual table',
    wantedTable: 'Wanted table',
    contextMenu: {
      fullAbsence: 'Full-day absence',
      partAbsence: 'Part-time absence',
      displaySettings: 'Display settings',
      showApprovedTime: 'Show approved extra time',
      temporaryEmployment: 'Temporary employment contract',
      manageDocuments: 'Manage documents',
      showContractsInfo: 'Show contract information',
      hideContractsInfo: 'Hide contract information',
      planLock: 'Planning lock',
      insertPlanLock: 'Insert planning lock',
      changePlanLock: 'Change planning lock',
      deletePlanLock: 'Delete planning lock',
      planLockLog: 'Planning lock log',
      getEmployees: 'Get employees',
      cut: 'Cut',
      copy: 'Copy',
      paste: 'Paste',
      undo: 'Undo',
      redo: 'Redo',
      showPasteOptions: 'Show options when pasting',
      hideCreatedMessages: 'Hide automatically created messages',
      deleteMessages: 'Delete messages',
      setDynamicBorder: 'Use dynamic border'
    },
    settings: {
      startTime: 'Start time',
      endTime: 'End time',
      sorting: 'Sorting',
      costCenterOrder: 'Cost center order',
      nameOrder: 'Name order',
      cardNumberOrder: 'Card number scheme',
      externalIdOrder: 'Order according to External ID',
      earlyStartTime: 'Early start times first',
      withoutSchedule: 'People without a schedule last',
      alphabeticalOrder: 'Alphabetical order',
      hideUnscheduled: 'Hide unscheduled people',
      hideAbsence: 'Hide absence coded people',
      extraTimeAccount: 'View extra time account',
      weather: 'Show weather'
    },
    errors: {
      saveFailed: 'Failed to save changes: {{ reason }}',
      lockFailed: 'Failed to lock schedule for update: {{ reason }}'
    },
    alert: {
      undoFailedDueAuth: {
        caption: 'Undo/Redo operation failed',
        message: 'The Undo/Redo operation is impossible due to insufficient authorization level'
      },
      undoFailedDueLock: {
        caption: 'Undo/Redo operation failed',
        message:
          'Unable to create locks as some of the schedules have been locked by other users.\n\n' +
          'To retry - answer No to the question below, then try to Undo/Redo.\nTo delete this restore ' +
          'point and open the option to try to restore other - answer Yes to the question below ' +
          'and then try again Undo/Redo.\n\nShould the restore point be deleted?'
      }
    },
    staffing: {
      totals: 'Totals',
      actual: 'Actual',
      orgUnitTotals: 'Total for the unit',
      totalTime: 'Tot time',
      totalPaidTime: 'Tot time incl PR'
    },
    paste: {
      title: 'Paste options',
      selectOption: 'Select paste option',
      mergeOverride: 'Merge schedule from clipboard with existing schedule',
      mergeExtend: 'Fill in the existing schedule with the schedule from the clipboard',
      replace: 'Replace existing schedule',
      swap: 'Swap schedules',
      copyMessage: '* Work shift copied from',
      mergeMessage: '* Work shift merged with'
    }
  },
  widgets: {
    add: 'Add widget',
    edit: 'Edit/move/remove widgets',
    ok: 'OK',
    chooseWidgets: 'Choose widgets',
    attendanceTable: 'Presence table',
    punchInOut: 'Punch in',
    weather: 'Weather',
    calendar: 'Calendar',
    higher: 'Higher',
    orgUnitLogo: 'Org unit logotype',
    attendanceTableDescription: 'Shows information about employees attendance status',
    weatherDescription: 'Shows current weather and forecast',
    calendarDescription: 'Shows calendar along with notifications and reminders',
    higherDescription:
      'Higher is the next generation recruitment tool where the candidate applies for a job directly in the mobile via tests and video instead of CV and personal letter.',
    orgUnitLogoDescription: 'Shows logotype of the current organization unit',
    readOnlyMode: 'Dashboard view',
    editWidget: 'Drag or resize widget',
    deleteWidget: 'Delete widget',
    restoreDefaults: 'Restore defaults',
    saveChanges: 'Save changes',
    discardChanges: 'Discard changes',
    dashboardConfiguration: 'Dashboard configuration',
    alert: {
      restoreDefaults: {
        caption: 'Restore defaults',
        message: 'Current layout will be replaced with the default one. Continue?'
      },
      discardChanges: {
        caption: 'Discard changes',
        message: 'Are you sure you want to discard changes?'
      },
      removeWidget: {
        message: 'Are you sure you want to permanently remove the {{ name }} widget?'
      }
    }
  },
  admin: {
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    customerId: 'Customer ID',
    id: 'ID',
    fullName: 'Full name',
    customerName: 'Customer name',
    customerDescription: 'Customer description',
    userName: 'Username',
    phone: 'Phone',
    email: 'E-mail',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    description: 'Description',
    from: 'From',
    to: 'To',
    search: 'Search',
    channel: 'Channel',
    timeFormat: 'HH:MM',
    areYouSure: 'Are you sure you want to delete?',
    addNew: 'Add new',
    disable: 'Disable',
    enable: 'Enable',
    message: 'Message',
    status: 'Status',
    itsYou: "it's you",
    total: 'Total',
    deviceName: 'Device name',
    deviceId: 'Device ID',
    timestamp: 'Timestamp'
  },
  errors: {
    pleaseSelectChannelLevel: 'Please select a channel level',
    pleaseSelectPlatformVersion: 'Please select a platform version',
    pleaseSelectHostPort: 'Please select a host port',
    pleaseSelectCurrency: 'Please, select currency',
    pleaseSelectClientType: 'Please, select client type',
    pleaseSelectFilePath: 'Please, select file path',
    pleaseSelectCustomerInstance: 'Please, select customer instance',
    invalidDate: 'Invalid date'
  },
  sessions: {
    systemHost: '[System Host]',
    systemInstance: '[System instance]',
    sessionStart: 'Session start',
    sessionEnd: 'Session end',
    sessionLogs: 'Session logs',
    host: 'Host',
    hosts: 'Hosts',
    userSessions: 'User sessions',
    supportSessions: 'Support sessions',
    adminSessions: 'Admin sessions',
    clientType: 'Client type'
  },
  userManagement: {
    users: 'Users',
    admin: 'Admin',
    password: 'Password',
    devices: 'Devices',
    deviceName: 'Device name',
    deactivateUser: 'Deactivate',
    userWasDeactivated: 'User was deactivated',
    noDevices: 'This user has no devices',
    deviceWasDeleted: 'This device was successfully removed',
    scanCode: 'Please scan the code with your device and set a desired device name',
    adminUsers: 'Admin users',
    externalSystems: 'External systems',
    accessKey: 'Access key',
    generateNewAccessKey: 'Generate new access key',
    confirmOperation: 'Confirm operation',
    confirmKeyGenerationMessage: 'Existing access key will be replaced. Continue?',
    confirmExtSysRemovalMessage: 'External system <b>{{name}}</b> will be removed. Continue?',
    usersCount: 'Users count',
    accessOverview: 'Access Overview',
    expirationDate: 'Expiration Date',
    extend: 'Extend',
    noActiveUsers: "There's no active external system users",
    statuses: {
      externalSystemAdded: 'External system added',
      externalSystemUpdated: 'External system updated',
      externalSystemDeleted: 'External system was deleted',
      expirationDateExtended: 'External system user expiration date was extended'
    }
  },
  serverManagement: {
    hosts: 'Host sessions',
    localPcName: 'Local PC name',
    customerInstancesCount: 'Customer instances count',
    number: 'Number'
  },
  packageManagement: {
    path: 'Path',
    invalidFilePath: 'This path is wrong.',
    bootstrap: 'Bootstrap (TIL-Tid-Starter.exe)',
    file: 'File',
    filePath: 'File path',
    clientVersion: 'Client version',
    requireAuth: 'Require authorization',
    chooseHosts: 'Choose hosts',
    uploadWasFailed:
      'Uploading of the file <b>{{fileName}}</b> to server  <b>{{server}}</b> was failed because of the errors:',
    errors: {
      notAllRequiredParams: 'Please fill all required fields and try again',
      directoryNotFound: 'We can not found directory specified in file path',
      versionAndPathIdAreNotUnique:
        'TTClient with this version and file extension already exists. Please change client version or file extension and try again'
    }
  },
  customerInstances: {
    noData: 'There are no customer instances',
    id: 'Customer instance ID',
    instanceName: 'Instance name',
    platformVersion: 'Platform version',
    channelLevel: 'Channel level',
    selectHosts: 'Select hosts',
    selectPlatforms: 'Select platforms',
    selectPlatformVersions: 'Select platform versions',
    selectVersions: 'Select version channels',
    selectReleaseVersions: 'Select release versions',
    taskName: 'Task name',
    lastRun: 'Last run',
    maitenanceMessage: 'Message',
    maitenanceMessageText: 'Maintenance message',
    requireTwoFactorAuth: 'Two-factor authentication',
    port: 'Port',
    manageOrgUnits: 'Manage org units',
    manageServices: 'Manage services',
    instanceWasPublished: 'This customer instance was successfully published',
    instanceWasDeleted: 'This customer instance was successfully deleted',
    services: 'Services',
    dataphor: 'Dataphor',
    tTService: 'TTService',
    noInfo: 'Information not available',
    serviceStart: 'Start',
    serviceStop: 'Stop',
    refreshServices: 'Refresh services',
    dataphorServiceIsStopped:
      'Dataphor Service for this instance is stopped. You can start it in Manage services',
    chooseToDelete: 'Please choose what do you want to delete:',
    deleteInCustomerDB: 'CustomerDB Dataphor',
    deleteInPostgreDB: 'Postgre DB',
    enterPasswordToDelete: 'Please enter your password to verify this action',
    scripting: 'Scripting',
    noScripts: 'There are no scripts for {{ version }} version',
    maintenanceWasDeleted: 'This maintenance window was successfully deleted',
    orgUnits: {
      orgNumber: 'Organization number',
      currency: 'Currency',
      manageHierarchy: 'Manage hierarchy',
      hierarchy: 'Hierarchy',
      street: 'Street',
      zipCode: 'Zip code',
      pyramidNo: 'PyramidNo',
      orgUnitWasDeleted: 'This organization unit was successfully deleted'
    },
    publishLog: {
      notPublished: 'Not published',
      status: 'Publish status',
      publish: 'Publish',
      publishing: 'Publishing',
      published: 'Published',
      publishingLog: 'Publishing log'
    },
    restore: {
      restoreAndConvert: 'Restore & Convert',
      restoreFromTemplate: 'Restore from template',
      convertFromTT1: 'Convert from TT1',
      restoreFromBackup: 'Restore from backup',
      startTime: 'Start time in format HH:MM',
      backup: 'Backup path',
      restoreLog: 'Restore log',
      templateRestoreLog: 'Template restore log',
      convertLog: 'Convert log',
      restored: 'Restored',
      converted: 'Converted',
      template: 'Template',
      pleaseEnterDataForTemplate:
        'Please choose template and enter your password to verify restore',
      pleaseEnterDataForRestoreBackup:
        'Please enter start time, path and your password to verify restore'
    },
    statuses: {
      success: 'Success',
      failed: 'Failed',
      running: 'Running',
      unknown: 'Unknown',
      inaccessible: 'Inaccessible',
      refresh: 'Refresh',
      seeStatus: 'See status',
      status: 'Customer instance status'
    },
    backups: {
      timeForExecution: 'Time of execution (in minutes)',
      backupsShowAll: 'Show All',
      showWithBackups: 'Show only with backup setup',
      showWithoutBackups: 'Show only without backup setup',
      ttBackups: 'Only TT Backups',
      postgreBackups: 'Only PostgreSQL Backups',
      archive: 'Archive',
      replace: 'Replace',
      repeatDaily: 'Repeat daily',
      isPostgre: 'PostgreSQL Database',
      backupTTTask: 'Backup TT Task',
      backupPostgreTask: 'Backup PostGre Task',
      noRunTime: 'This backup task was not run yet'
    },
    scripts: {
      status: 'Script status',
      runScript: 'Run',
      script: 'Choose script',
      noPathError: "Can't run the script without a path. Please choose the script"
    },
    conversions: {
      taskName: 'Task name',
      taskState: 'Job status',
      conversionState: 'Conversion status',
      conversionStart: 'Completed',
      conversionDescription: 'Conversion description',
      lastRun: 'Last run',
      nextRun: 'Next run',
      lastTaskResult: 'Last run result',
      log: 'Log',
      logFor: 'Log for a {{ name }}',
      statusUnknown: 'Unknown',
      statusCanceled: 'Canceled',
      statusDisabled: 'Disabled',
      statusQueued: 'Queued',
      statusReady: 'Ready',
      statusRunning: 'Running',
      statusStart: 'Starts the conversion job',
      statusReadyForBackUp: 'Creates backup',
      statusReadyForCopy: 'Copies backup',
      statusReadyForPublish: 'Publishes instance',
      statusReadyForRestore: 'Loading backup',
      statusReadyToComplete: 'Restores',
      statusReadyToStartServices: 'Starts services',
      statusReadyToCleanUp: 'Clean up',
      statusConversionFinished: 'The conversion job was successful',
      dateAndTime: 'Conversion start time',
      taskDeleted: 'The conversion job has been deleted',
      tasksDeleted: 'The conversion jobs have been deleted',
      taskWasCreated: 'The conversion job was created',
      errorMessageReason:
        ' The {{id}} conversion job could not be deleted because it is too close or already running ({{error}})',
      renameInstance: 'Rename customer instance',
      enterInstanceName: 'Enter instance name'
    }
  },
  dateMessages: {
    caption: 'Day messages',
    edit: 'Edit',
    access: 'Access',
    delete: 'Delete',
    save: 'Save',
    cancel: 'Cancel',
    noMessages: 'No messages yet',
    messageType: 'Type',
    add: 'Add message',
    close: 'Close',
    visibleTo: 'Visible to',
    everyone: 'Everyone',
    restrictedAccess: 'Restricted access',
    note: 'Note',
    campaign: 'Campaign',
    reminder: 'Reminder',
    campaignDescription:
      ' - will be displayed and available in the planning view at the right time and will also appear in the calendar on the home page.',
    reminderDescription:
      ' - will be displayed and available in the planning view, the correct times and will also be available under "Reminders" on the home page.',
    messagePlaceholder: 'Enter message here',
    addFailed: 'Failed to add new message',
    updateFailed: 'Failed to update message',
    deleteFailed: 'Failed to delete message',
    accessBlock: {
      header: 'Messages must be visible to',
      everyone: 'All within the current org unit',
      restrictedAccess: 'Restricted access',
      costAccounts: 'According to cost account',
      users: 'Specific users',
      back: 'Back',
      ok: 'OK',
      cancel: 'Cancel'
    },
    alert: {
      caption: 'Confirm action',
      deleteConfirmation: 'Selected message will be deleted. Continue?'
    }
  },
  higher: {
    higherKPI: 'Higher KPI:s',
    kpi: 'KPI',
    allActiveJobs: 'All jobs',
    aggregates: {
      newestApplications: 'New applications',
      unhandledApplications: 'Unhandled applications',
      totalApplications: 'Total applications',
      totalJobs: 'Total jobs',
      numberOfHiredCandidates: 'Hired',
      numberOfCandidatesExportedToTilTid: 'Exported to TIL-Tid'
    },
    period: {
      week: 'Last 7 days',
      twoWeeks: 'Last 14 days',
      month: 'Last 30 days',
      twoMonths: 'Last 60 days',
      allTime: 'All time'
    },
    slogan: 'The recruitment tool of the future',
    signUp: 'Click to find out more and to sign up for Higher',
    connect: 'Sign in into Higher to display information for your organization',
    signIn: 'Sign in to Higher',
    selectCompany: 'Select company from the list below',
    settings: {
      caption: 'Configure display settings',
      aggregationPeriod: 'Aggregation period',
      visibleAggregates: 'Select values to display',
      company: 'Company'
    }
  },
  employee: {
    title: 'TIL-Tid Employee',
    header1: 'Completion of personal data',
    addHeader: 'Personal data regarding a new next of kin',
    editHeader: 'Editing personal data regarding a next of kin',
    firstName: 'First name',
    lastName: 'Last name',
    ssn: 'Social security number',
    birthdate: 'Birthday',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    none: 'None',
    city: 'City',
    country: 'Country',
    address: 'Address',
    zipCode: 'Zip code',
    description: 'Description',
    emails: 'Emails',
    enterEmail: 'Email address',
    addMore: 'Add more',
    phones: 'Phone numbers',
    enterPhoneNumber: 'Phone number',
    photo: 'Photo',
    thankYou: 'Thank you for filling out your information!',
    addRelative: 'Add a next of kin',
    deleteRelative: 'Remove a next of kin ',
    relative: 'Next of kin',
    add: 'Add',
    phone: 'Phone',
    mobile: 'Mobile',
    areYouSure: 'Is it safe to remove this person?',
    additionalInfo: 'Additional information',
    enterData: 'Text',
    accessWillBeInvalidated:
      'When storing data, the link used to access this page will be inactivated. It will therefore not be possible to use it again to add more information.',
    errors: {
      invalidSsn: 'The social security number is invalid',
      invalidPhone: 'The phone number {{ phone }} is invalid',
      invalidEmail: 'Email {{ email }} is invalid',
      invalidAccessKey:
        'The access key has expired and is no longer valid. Ask the administrator to send a new link if there is a need to change personal information'
    },
    display: {
      showFirstLastName: 'Show First name Last name',
      showLastFirstName: 'Show Last name, First name ',
      showSpeedCode: 'Show speed code',
      showEmployeeNo: 'Show employment number',
      showCostAccount: 'Show cost account',
      showDepartment: 'Show department'
    }
  }
};
