import React, { useCallback } from 'react';
import { INavigateConfig, useNavigation } from '@lib/useNavigation';
import { createRoutes } from '@utils/route-utils';
import { Navigate } from 'react-router-dom';

export enum AuthPage {
  Login = 'login'
}

export enum LoginPage {
  BankId = 'bank-id',
  ActiveDirectory = 'active-directory',
  ActiveDirectoryCallback = 'active-directory-callback',
  Confirm = 'confirm'
}

export enum ActiveDirectoryCallbackPage {
  Success = 'success',
  Error = 'error'
}

export const AuthRoutes = createRoutes(null, AuthPage);
export const LoginRoutes = createRoutes(AuthPage.Login, LoginPage);
export const ActiveDirectoryRoutes = createRoutes(
  LoginRoutes.ActiveDirectoryCallback,
  ActiveDirectoryCallbackPage
);

export type AuthRoute =
  | typeof AuthRoutes[keyof typeof AuthRoutes]
  | typeof LoginRoutes[keyof typeof LoginRoutes]
  | typeof ActiveDirectoryRoutes[keyof typeof ActiveDirectoryRoutes];

export function useAuthNavigation() {
  const navigate = useNavigation();

  const webClientNavigate = useCallback(
    (route: AuthRoute, config?: INavigateConfig) => {
      const basePath = process.env.BASE_URL ?? '';
      navigate({ basePath, route, config });
    },
    [navigate]
  );

  return webClientNavigate;
}

export function AuthNavigate(props: { to: AuthRoute; replace?: boolean; state?: any }) {
  const { to, replace, state } = props;

  // make sure we have absolute base path
  let basePath = process.env.BASE_URL ?? '';
  if (!basePath.startsWith('/')) {
    basePath = `/${basePath}`;
  }
  if (!basePath.endsWith('/')) {
    basePath = `${basePath}/`;
  }

  // Keep search and hash on router redirects
  // for now I assume that `replace === true` means a redirect
  const path = {
    pathname: `${basePath}${to}`,
    search: replace ? window.location.search : undefined,
    hash: replace ? window.location.hash : undefined
  };

  return <Navigate to={path} replace={replace} state={state} />;
}
