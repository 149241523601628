import { UserLanguage } from '../../api/auth/users';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import sv from './sv';

i18n.use(initReactI18next).init(
  {
    lng: process.env.NODE_ENV === 'development' ? UserLanguage.EN : UserLanguage.SV,
    fallbackLng: UserLanguage.SV,

    interpolation: {
      escapeValue: false
    },

    resources: {
      en,
      sv
    }
  },
  () => {
    if (i18n.language == UserLanguage.SV) {
      require('moment/locale/sv.js');
    }
  }
);

export default i18n;
