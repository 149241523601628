export default {
  common: {
    slogan: 'Effektiv tidsplanering. Ökad lönsamhet.',
    TilTid: 'TIL-Tid',
    tryLater: 'Försök igen om en liten stund.',
    TTPC_client: 'TIL-Tid PC-klient',
    startTT: 'Starta TIL-Tid',
    send: 'Skicka',
    select: 'Välj',
    selectAll: 'Välj alla',
    orgUnit: 'Organisationsenhet',
    orgUnits: 'Organisationsenheter',
    presenceTable: 'Närvarotablå',
    back: 'Tillbaka',
    toggleNavigation: 'Toggle navigering',
    all: 'Alla',
    changePassword: 'Ändra lösenordet',
    profile: 'Profil',
    save: 'Spara',
    upload: 'Uppladdning',
    pleaseWait: 'Vänta...',
    processing: 'Bearbetning...',
    loading: 'Läser in...',
    id: 'Id',
    name: 'Namn',
    city: 'Stad',
    country: 'Land',
    search: 'Sök',
    description: 'Beskrivning',
    close: 'Stänga',
    confirm: 'Bekräfta',
    nothingToShow: 'Inget att visa',
    greeting: 'Hej',
    yes: 'Ya',
    no: 'Nej',
    thereIsNothing: 'Det finns inget att visa',
    delete: 'Radera',
    create: 'Skapa',
    saved: 'Sparade',
    dontAskAgain: 'Fråga inte igen',
    ok: 'OK',
    cancel: 'Avbryt',
    from: 'Från',
    to: 'Till'
  },
  cookies: {
    siteUsesCookies:
      'Den här webbplatsen använder cookies för att tillhandahålla ' +
      'nödvändig funktionalitet och ge dig en bra användarupplevelse.',
    assumeCookiesUsage:
      'Genom att fortsätta använda eller logga in på den här ' +
      'webbplatsen förutsätts du godkänna användning av cookies på den här webbplatsen.',
    iUnderstand: 'Jag förstår'
  },
  error: {
    UnexpectedError: 'Oväntat fel.',
    AuthorizationError: 'Behörighetsfel.',
    ValidationError: 'Valideringsfel.',
    AuthenticationError: 'Authentication error.',
    tryAgain: 'Vänligen försök igen',
    notFound: '404 Not Found',
    couldntFindPage: `Sorry, we couldn't find that page.`
  },
  copyright: {
    copyright: 'Copyright',
    companyName: 'SEV Tidsystem AB',
    rightsReserved: 'Alla rättigheter reserverade.'
  },
  login: {
    signIn: 'Inloggning',
    logIn: 'Logga in',
    login: 'Direktinloggning',
    bankId: {
      name: 'Bank ID',
      mobileAuth: 'Mobilt BankID',
      authOnOtherDevice: 'Mobilt BankID på annan enhet',
      cancel: 'Avbryt inloggning',
      tryAgain: 'Försök igen',
      noInstances: 'Du får inte logga in på någon kundinstans med BankID.',
      RFA1: 'Starta BankID-appen och tryck på knappen Skanna QR. Skanna sedan QR-koden ovan.',
      RFA2: 'Du har inte BankID-appen installerad. Kontakta din bank.',
      RFA3: 'Åtgärden avbruten. Försök igen.',
      RFA4: 'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.',
      RFA5: 'Internt tekniskt fel. Försök igen.',
      RFA6: 'Åtgärden avbruten.',
      RFA8:
        'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. ' +
        'Om du inte har något giltigt BankID kan du skaffa ett hos din bank. Försök sedan igen.',
      RFA9: 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.',
      RFA13: 'Försöker starta BankID-appen.',
      RFA14_A:
        'Söker efter BankID, det kan ta en liten stund...\r\nOm det har gått några sekunder och inget ' +
        'BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella ' +
        'identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. ' +
        'Om du inte har något BankID kan du skaffa ett hos din bank. Om du har ett BankID på en ' +
        'annan enhet kan du starta din BankID-app där.',
      RFA14_B:
        'Söker efter BankID, det kan ta en liten stund...\r\nOm det har gått några sekunder och inget ' +
        'BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella ' +
        'identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du skaffa ' +
        'ett hos din bank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.',
      RFA15_A:
        'Söker efter BankID, det kan ta en liten stund...\r\nOm det har gått några sekunder och inget ' +
        'BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella ' +
        'identifieringen/underskriften i den här datorn. Om du har ett BankID-kort, sätt in det i kortläsaren. ' +
        'Om du inte har något BankID kan du skaffa ett hos din bank.',
      RFA15_B:
        'Söker efter BankID, det kan ta en liten stund...\r\nOm det har gått några sekunder och inget ' +
        'BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella ' +
        'identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du skaffa ett hos din bank.',
      RFA16:
        'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din bank.',
      RFA17_A:
        'BankID-appen verkar inte finnas i din dator eller mobil. Installera den och skaffa ett BankID hos din bank. ' +
        'Installera appen från din appbutik eller <a href="https://install.bankid.com" target="_blank">https://install.bankid.com</a>.',
      RFA17_B:
        'Misslyckades att läsa av QR-koden. Starta BankID-appen och läs av QR-koden. Kontrollera att BankID-appen ' +
        'är uppdaterad. Om du inte har BankID-appen måste du installera den och skaffa ett BankID hos din bank. ' +
        'Installera appen från din appbutik eller <a href="https://install.bankid.com" target="_blank">https://install.bankid.com</a>.',
      RFA18: 'Starta BankID-appen.',
      RFA19:
        'Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?',
      RFA20:
        'Vill du identifiera dig eller skriva under med ett BankID på den här enheten eller med ett BankID på en annan enhet?',
      RFA21: 'Identifiering eller underskrift pågår.',
      RFA22: 'Okänt fel. Försök igen.',
      RFA23: 'Fotografera och läs av din ID-handling med BankID-appen.'
    },
    ad: 'AD',
    customerId: 'Kund ID',
    username: 'Användarnamn',
    password: 'Lösenord',
    forgotPassword: 'Jag har glömt mitt lösenord',
    personalNumber: 'Personnummer',
    webClient: 'Närvaro tablå',
    windowsClient: 'TIL-Tid Klient',
    payrollExport: 'Skapa Lönefiler',
    authPortal: 'Start Sida',
    insertSecurityCode: 'Vänligen fyll i säkerhetskoden från Mobilt BankID',
    redirectBackTo: 'Omdirigerar tillbaka till {{ to }}...',
    passwordRecovery: {
      forgotPassword: 'Har du glömt ditt lösenord?',
      enterDetails:
        'Ange uppgifterna nedan, så skickar vi ett nytt lösenord ' + 'till din e-postadress.',

      newPassSent: 'Ett nytt lösenord har skickats',
      newPassSentMsg1:
        'Lösenordet har skickats till den e-postadress ' +
        'som är kopplad till ditt användarkonto.',
      newPassSentMsg2:
        'Om inget mail har anlänt, vänta ett par minuter och ' +
        'kontrollera din e-post igen. Kontrollera även att mailet inte tagits bort som skräppost.',
      newPassSentMsg3: 'Om du behöver hjälp eller har frågor, kontakta gärna vår support.'
    },
    updateTemporaryPassword: 'Det nuvarande lösenordet är tillfälligt och ett nytt måste nu anges.',
    errors: {
      customerIdEmpty: 'Ett kund-id måste anges',
      usernameEmpty: 'Ange ditt användarnamn eller e-postadress',
      passwordEmpty: 'Ett lösenord måste anges',
      personalNumber: 'Enter your personal number',
      confirmationCodeEmpty: 'Vänligen mata in bekräftelsekoden',
      unexpectedError: 'Ett oväntat problem uppstod.',
      invalidCredentials: 'Inloggningen misslyckades. Kontrollera dina inloggningsuppgifter.',
      blocked: 'Kontot låst p.g.a. för många misslyckade inloggningsförsök.',
      banned: 'Konto är förbjudet. Kontakta administratören.',
      invalidConfirmationCode: 'Bekräftelsekoden är inte giltig.',
      incorrectCredentials:
        'De inmatade uppgifterna är inte giltiga inloggningsuppgifter. Vänligen försök igen.',
      wrongUser: 'Användaren hittades inte',
      unableToResetPassword: 'Det här lösenordet kan inte återställas.',
      unableToResetADPassword: 'Det går inte att återställa lösenordet för aktiv kataloginloggning',
      invalidCustomer: 'Denna kund är inte giltig. Var god försök igen.',
      invalidUser: 'Den här användaren är inte giltig. Var god försök igen.',
      invalidDevice: 'Den här enheten är inte giltig. Välj en annan enhet',
      noAccessToWebclient: 'Felaktiga användaruppgifter'
    },
    twoFactor: {
      enterCode: 'Mata in bekräftelsekoden som skickats till {{ phone }}',
      confirmationCode: 'Bekräftelsekoden',
      codeExpired: 'Tiden för bekräftelsekoden har löpt ut.',
      selectCustomerInstance: 'Vänligen välj en kund',
      enterPin: 'Mata in bekräftelsekoden från din autentiseringsapp',
      selectDevice: 'Välj mobil enhet',
      sendSms: 'eller få en kod skickad via ett SMS till {{ phone }}'
    },

    logOut: 'Logga ut'
  },
  clientDownload: {
    downloadStarted: 'Nedladdning startar...'
  },
  orgUnits: {
    selectOrgUnitTitle: 'Organisationsenhet',
    selectOrgUnit: 'Vänligen välj en organisationsenhet:',
    onlyOne: 'Det finns bara en organisationsenhet i ditt system',
    orgUnitRequired: 'En organisationsenhet är obligatorisk',
    thereIsNothing: 'Det finns inget att visa',
    thereIsNoData: 'Det finns inga data för den aktuella organisationsenheten',
    showHiddenUnits: 'Visa dolda enheter'
  },
  presenceTable: {
    costAccount: 'Kostnadsställe',
    statuses: {
      working: 'Arbetar',
      resting: 'På rast',
      away: 'Saknas'
    },
    sum: 'Summa',
    notChecked: 'Ej instämplade',
    showAbsent: 'Visa frånvarande',
    lastUpdatedAt: 'Uppdaterades senast',
    showAll: 'Visa allt',
    collapse: 'Kollaps'
  },
  models: {
    employee: {
      noPhoneNumber: 'Inga telefonnummer.'
    },
    employeePresence: {
      onBreak: 'På rast',
      absent: 'Saknas',
      outsideSchedule: 'Arbetar (utanför schema)',
      onSchedule: 'Arbetar'
    }
  },
  statuses: {
    youArePunchedIn: 'Du är för närvarande instämplad',
    youAreNotPunchedIn: 'Du är för närvarande utstämplad',
    punchIn: 'Stämpla in',
    punchOut: 'Stämpla ut',
    punchedIn: 'Instämplad',
    punchedOut: 'Utstämplad',
    pleaseConfirm: 'Bekräfta stämpling',
    success: 'Stämpling utförd',
    punchOutToABreak: 'Stämpla ut till en rast',
    errorNoTerminalUnit:
      'Du kan inte slå in eftersom den här organisationsenheten inte har en aktiv terminalenhet'
  },
  installationGuide: {
    downloadingStarted:
      'Nedladdning har påbörjats och filen kommer att finnas tillgänglig inom kort',
    toContinueFollowTheseSteps: 'För att starta TIL-Tid, vänligen följ dessa steg:',
    pressSaveEdgeIe: 'Tryck på Spara i notifieringsfältet längst ned på skärmen när så begärs',
    pressSaveMozilla: 'Tryck på Spara i notifieringsfönstret när så begärs',
    pressSaveOpera: 'Spara filen till önskad filarea när så begärs',
    runInstallerEdge:
      'Tryck Kör vid notifiering om att nedladdningen har ' +
      'utförts och att säkerhetskontrollen har genomförts',
    runInstallerIe: 'Tryck Kör vid notifiering om att nedladdningen har utförts',
    runInstallerChrome:
      'Efter att nedladdningen av filen slutförts, ' +
      'klicka på filen längst ner på skärmen för att köra filen',
    runInstallerMozillaOpera:
      'Klicka på den nedladdade filen, vilken finns i listan ' +
      'över nedladdade filer i det övre högra hörnet av skärmen',
    chooseDirectory:
      'Välj den mapp där filerna avseende TIL-Tid önskas läggas. ' +
      'Vi rekommenderar att den på förhand angivna mappen används',
    installAndStart: 'Tryck [Starta TIL-Tid]',
    congratulations:
      'Efter detta kommer det senaste programpaketet att laddas ' +
      'ner och packas upp. TIL-Tid klienten kommer att köras igång och logga in automatiskt',
    back: 'Tillbaka'
  },
  password: {
    newPassword: 'Nytt lösenord',
    newPassword2: 'Bekräfta nytt lösenord',
    yourPasswordWasReset: 'Det nuvarande lösenordet är tillfälligt och ett nytt måste ny anges.',
    passwordHasBeenChanged: 'Lösenordet har återställts.',
    save: 'Lagra',
    areYouSure: 'Du har inte angett ett nytt lösenord, avsluta applikationen?',
    errors: {
      passwordRequired: 'Ett lösenord måste anges',
      password2Required: 'Vanligen ange lösenordet andra gången',
      passwordsNotMatch: 'Lösenorden matchar inte',
      passwordInvalidFormat: 'Vänligen följ det begärda formatet.'
    },
    passwordRulesText:
      'Lösenordet bör vara minst {{length}} tecken i längd, innehålla minst {{capLetters}}' +
      ' stora bokstäver (A-Z), {{lowLetters}} små bokstäver (a-z), {{numbers}} siffror (0-9), ' +
      '{{chars}} specialtecken.'
  },
  session: {
    updating: 'Uppdatering...',
    maintenanceWindow: 'Nästa servicefönster',
    maintenanceMessage: 'Beskrivning',
    alert: {
      upcomingMaintenance: {
        caption: 'Information om underhållsarbete',
        message:
          '\nEtt underhållsarbete av TIL-Tid kommer att påbörjas {{startTime}}.\nSpara ditt arbete och logga ut från TIL-Tid ' +
          'så fort som möjligt.\n\n\tUnderhållsarbetet är schemalagt mellan {{startTime}} - {{endTime}}.\n\tMeddelande: {{message}}' +
          '\n\nArbetet pågår ständigt med att hålla TIL-Tid uppdaterat för att säkerställa att upplevelsen blir så bra som möjligt.' +
          '\nTack på förhand.'
      },
      extendFail: {
        caption: 'Förlängning av sessionen misslyckades',
        message:
          '\nEtt fel har uppstått i samband med förlängning av sessionen.\n\nEn utloggning kommer att ske {{endOfLife}}. ' +
          'Vänligen spara eventuella förändringar för att förhindra att de går förlorade.'
      }
    }
  },
  fileUpload: {
    upload: 'Ladda upp',
    chooseFile: 'Välj fil',
    noFileChosen: 'Ingen fil vald',
    fileUploaded: 'Filen har laddats upp'
  },
  attested: {
    attestedTitle: 'Intygas',
    orgUnitId: 'EnhetsID',
    filterList: 'Filterlista',
    askPayrollCreationTitle: 'Ska exporten startas?',
    askPayrollCreationContent: 'Skapande av lönegrund kan ta tid vid stora datamängder.',
    askPayrollCreationConfirm: 'Godkänn',
    askPayrollCreationCancel: 'Avbryt',
    orgNumber: 'Företagsnummer',
    unitNumber: 'Enhetsnummer',
    name: 'Namn',
    city: 'Stad',
    selected: 'Vald(a)',
    btnDelete: 'Radera',
    dateFrom: 'från',
    dateTo: 'till',
    filterStatusAll: 'Alla',
    filterStatusUpToSelected: 'Attesterade till och med ',
    filterStatusUpToNotSelected: 'Ej attesterade till och med ',
    orgUnits: 'Organisationsenheter',
    noPeriods: 'Inga perioder skapade',
    description: 'Beskrivning',
    exportedFilter: 'Redan exporterad(e)',
    notExportedFilter: 'Ej exporterad(e)',
    exportedTooltipHeader: 'Lönegrund har exporterats för',
    advancedDetails: 'Detaljer / Avancerat',
    advanceDetailsHeader: 'Periodinmatning',
    fileName: 'Filnamn',
    bundleNum: 'Buntnummer',
    companyNum: 'Företagsnr',
    unitNum: 'Enhetsnr',
    selectEmployees: 'Val av personer',
    selectAllEmployees: 'Samtliga personer',
    selectEmployeesByFilter: 'Valda lonekategorier',
    orgunitStatus: 'Organisationsenhetsstatus',
    level: 'Attestering nivå',
    attestDate: 'Attesteringar gjorda t.o.m',
    attestName: 'Attesteringsnivå',
    attestStatus: 'Visa',
    noResults: 'Inga resultat',
    attestedBy: 'Attesterat av',
    attestTime: 'Tid för attestering',
    attestedUpTo: 'Attesterat t.o.m',
    attestReport: 'Attesteringsöversikt',
    search: 'Önskad attesteringsnivå',
    searchByOrgunit: 'Sök efter organisationsenhetsnamn',
    all: 'Allt'
  },
  navigation: {
    logout: 'Logga ut',
    exit: 'Avbryt',
    authPortal: 'Start Sida',
    webClient: 'Närvaro tablå',
    changePassword: 'Ändra lösenord',
    customerInstances: 'Kundinstanser',
    maintenanceWindows: 'Servicefönster',
    apiDocumentation: 'API Dokumentering',
    admin: {
      indexPage: 'Indexsida',
      userManagement: 'Använd<u>{{key}}</u>rhantering',
      instanceManagement: '<u>{{key}}</u>nstanshantering',
      customerInstances: 'Kundinsta<u>{{key}}</u>ser',
      maintenanceWindows: 'Serviceföns<u>{{key}}</u>er',
      backups: 'Backuphantering',
      customerInstancesStatuses: 'Kundinstansstatus',
      terminalStatuses: 'Terminalstatus',
      conversions: 'Konverteringar',
      serverManagement: 'S<u>{{key}}</u>rverhantering',
      hosts: 'Server',
      ports: 'Portar',
      scripts: 'Skript',
      packageManagement: 'Pa<u>{{key}}</u>ethantering',
      packages: 'Paket',
      filePaths: 'Sökvägar',
      bootstrap: 'Bootstrap',
      sessions: 'Sessi<u>{{key}}</u>ner',
      hostSessions: 'Serversessioner',
      systemSessions: 'Systemsessioner',
      customerInstanceSessions: 'Kundinstanssessioner',
      logs: 'Loggar',
      logout: '<u>{{key}}</u>ogga ut'
    },
    webShop: {
      title: 'Webshop',
      tooltipText:
        'Besök gärna vår nya webshop där man kan läsa mer om och beställa såväl tjänster som förbrukningsmaterial',
      tooltipButton: 'Tack, då vet jag'
    }
  },
  notFound: {
    notFoundTitle: 'Titel saknas',
    pageHeader: 'Fel',
    pageSubHeader: 'Sidan kunde inte hittas'
  },
  payrollWizard: {
    payrollWizard: 'Export av lönegrund',
    wizardStepDates: 'Välj period',
    wizardStepUnits: 'Välj organisationsenheter',
    wizardStepConfirm: 'Bekräfta',
    wizardStepProcess: 'Exportera',
    back: 'Föregående',
    next: 'Nästa',
    wizardReset: 'Förbered ny export',
    currentStores: 'Export av lönegrund till lönesystemet kommer att göras för {{ stores }}',
    currentStoreName: 'vald(a) enhet(er) - ',
    currentPeriod: 'Vald period för exporten är ',
    currentPayTypes: 'De valda lönetyperna - ',
    pressNext: 'Tryck på NÄSTA för att starta exporten',
    pressBack: 'Tryck Föregående',
    orgUnitsHasWarnings:
      'Data för följande enheter får inte exporteras på grund av brott mot krav på attestering',
    suppres: 'Överrid',
    send: 'Skicka',
    cancel: 'Avbryt',
    ok: 'OK',
    start: 'Start',
    specifyReason: 'Var vänlig ange orsaken',
    errorOccurred: 'Ett fel inträffade',
    errorRequestAxfoodPayrollExport:
      'Exporten kunde inte startas då en tidigare startad export fortfarande pågår',
    errorStartPayrollExport: 'Fel uppstod när startlönelistan exporterades',
    downloadExportFiles: 'Ladda ner skapade lönefiler',
    errorNothingToDownload: 'Det finns inga filer att ladda ner',
    download: 'Ladda ner',
    checkDownload: 'Var god välj vad du vill ladda ner',
    payrollFormatIsEmpty: 'Du har inget löneformat, så du kan inte exportera lön',
    payrollCrashNetwork:
      'Kontakten med servertjänsten har brutits av okänd anledning. Återanslutningsförsök pågår',
    payrollCrashJobNotQueued: 'Exporten kunde inte starta av okänd anledning',
    payrollCrashInvalidParameters: 'Exporten kunde inte starta på grund av felaktigt anrop',
    payrollCrashUnauthorized: 'Exporten kunde inte starta då inloggning saknas',
    selectAll: 'Välj alla filer'
  },
  payrollLoading: {
    payrollCreation: 'Beräknar lön',
    progressStarted: 'Skapande av lönegrund påbörjas',
    progressInProgress: 'Skapande av lönegrund pågår...',
    progressFinished: 'Skapande av lönetransaktionsfil/er lyckades',
    progressCrashed:
      'Någonting går fel. Tryck på Tillbaka för att gå vidare till föregående steg och försök igen',
    exportStuck: 'Vänligen ladda om den här sidan',
    reload: 'Ladda om\n'
  },
  payperiods: {
    confirmationContent: 'Ska perioden raderas?',
    agree: 'Radera',
    disagree: 'Avbryt',
    period: 'Period',
    description: 'Beskrivning',
    btnAdd: 'Skapa ny lönefil',
    errors: {
      cantCreatePeriod: 'Fel vid skapande av löneperiod',
      chooseAnotherPeriod:
        'Valda datum överlappas till befintliga löneperioder. Välj en annan period',
      cantCreateExport: 'Fel vid export'
    }
  },
  birthdays: {
    reminders: 'Påminnelser',
    noReminders: 'Inga påminnelser',
    birthday: 'Födelsedag',
    specialDay: 'Speciell dag',
    years: 'år',
    remember: 'Kom ihåg',
    campaign: 'Kampanj'
  },
  menu: {
    planningView: 'Personalplanering',
    regEditorView: 'Rätta tider',
    messages: 'Meddelanden'
  },
  forecast: {
    clearSky: 'Klart',
    nearlyClearSky: 'Slöjmoln',
    variableCloudiness: 'Växlande molnighet',
    halfclearSky: 'Halvklart',
    cloudySky: 'Molnigt',
    overcast: 'Mulet',
    fog: 'Dimma',
    lightRainShowers: 'Lätta regnskurar',
    moderateRainShowers: 'Måttliga regnskurar',
    heavyRainShowers: 'Kraftiga regnskurar',
    thunderstorm: 'Åskväder',
    lightSleetShowers: 'Lätta snöblandade regnskurar',
    moderateSleetShowers: 'Måttliga snöblandade regnskurar',
    heavySleetShowers: 'Kraftiga snöblandade regnskurar',
    lightSnowShowers: 'Lätta snöbyar',
    moderateSnowShowers: 'Måttliga snöbyar',
    heavySnowShowers: 'Kraftiga snöbyar',
    lightRain: 'Lätt regn',
    moderateRain: 'Måttligt regn',
    heavyRain: 'Kraftigt regn',
    thunder: 'Åska',
    lightSleet: 'Lätt snöblandat regn',
    moderateSleet: 'Måttligt snöblandat regn',
    heavySleet: 'Kraftigt snöblandat regn',
    lightSnowfall: 'Lätt snöfall',
    moderateSnowfall: 'Måttligt snöfall',
    heavySnowfall: 'Kraftigt snöfall'
  },
  alert: {
    btnOk: 'OK',
    btnCancel: 'Avbryt',
    btnAbort: 'Avbryt',
    btnRetry: 'Försök igen',
    btnIgnore: 'Hoppa över',
    btnYes: 'Ja',
    btnNo: 'Nej'
  },
  dimension: {
    timeAccount: 'Arbetsnummer',
    department: 'Avdelning',
    costAccount: 'Kostnadsställe',
    legalPerson: 'Juridisk person'
  },
  dimensionHierarchy: {
    caption: 'Hantera hierarkier för tidkonton',
    selectDimension: 'Välj tidkontotyp',
    hierarchy: 'Hierarki',
    buttons: {
      save: 'Spara ändringar',
      discard: 'Ångra ändringar'
    },
    alert: {
      unsavedChanges: {
        caption: 'Ändringar har inte sparats',
        message:
          'Det finns gjorda ändringar som ännu inte sparats.\nSka ändringarna ångras och därmed försvinna?'
      },
      discardChanges: {
        caption: 'Ångra gjorda ändringar',
        message: 'Alla förändringar som ännu inte sparats kommer att försvinna. Fortsätt?'
      },
      invalidTemplate: {
        caption: 'Ogiltig Grupperingsmall',
        emptyName:
          'Namn på grupperingsmallen är ett obligatoriskt fält och det kan inte vara tomt. Vänligen ange ett namn.',
        nameIsTaken:
          'Det finns redan en grupperingsmall med namnet "{{name}}". Vänligen ange ett annat namn.',
        emptyDimensionValues:
          'Grupperingsmallen måste innehålla åtminstone ett tidkonto. Vänligen ange ett tidkonto eller radera grupperingsmallen.'
      },
      affectedTemplates: {
        caption: 'Grupperingsmallar påverkas',
        message:
          'Tidkonto "{{name}}" används i följande grupperingsmallar:\n\n\t{{templateNames}}\n\n' +
          'Flyttande av dess position i hierarkin kommer att leda till att den tas bort från grupperingsmallarna nämnda ovan.\nFortsätt?'
      },
      emptyTemplates: {
        caption: 'Grupperingsmallar är tomma',
        message:
          'Följande grupperingsmallar:\n\n\t{{templateNames}}\n\ninnehåller inga tidkonton och kommer att raderas.\nFortsätt?'
      }
    }
  },
  aggregationTemplates: {
    caption: 'Grupperingsmallar',
    name: 'Namn',
    description: 'Beskrivning',
    rulesList: 'Grupperingsmallsinställningar:',
    setupRulesTip: 'Bygg upp en grupperingsmall genom att välja tidkonton i trädstrukturen',
    buttons: {
      add: 'Lägg till mall',
      edit: 'Redigera mall',
      delete: 'Radera mall',
      ok: 'OK',
      cancel: 'Avbryt'
    }
  },
  tluCodes: {
    showExtCode: 'Visa kostnadsställe',
    showName: 'Visa endast benämning',
    showSpeedCode: 'Visa kortnummer'
  },
  sort: {
    sortOrder: 'Sorteringsordning',
    sortBySpeedCode: 'Kortnummer',
    sortByFirstNameAndLastName: 'Förnamn, Efternamn',
    sortByLastNameAndFirstName: 'Efternamn, Förnamn',
    sortByEmployeeNumber: 'Anställningsnummer',
    sortByDepartmentAndSpeedCode: 'Avdelning, Kortnummer',
    sortByCostAccountAndSpeedCode: 'Kostnadsställe, Kortnummer',
    sortByHourlyWage: 'Timlön',
    sortByDepartmentAndFirstName: 'Avdelning, Förnamn',
    sortByDepartmentAndLastName: 'Avdelning, Efternamn',
    sortByDepartmentAndEmployeeNumber: 'Avdelning, Anställningsnummer',
    sortByDepartmentAndCostAccountAndSpeedCode: 'Avdelning, Kostnadsställe, Kortnummer',
    sortByDepartmentAndCostAccountAndName: 'Avdelning, Kostnadsställe, Namn',
    sortByDepartmentAndCostAccountAndEmployeeNumber:
      'Avdelning, Kostnadsställe, Anställningsnummer',
    sortByCostAccountAndLastName: 'Kostnadsställe, Efternamn',
    sortByCostAccountAndFirstName: 'Kostnadsställe, Förnamn',
    sortByCostAccountAndEmployeeNumber: 'Kostnadsställe, Anställningsnummer',
    sortByCostAccountAndDepartmentAndSpeedCode: 'Avdelning, Kostnadsställe, Kortnummer',
    sortByCostAccountAndDepartmentAndName: 'Kostnadsställe, Avdelning, Namn',
    sortByCostAccountAndDepartmentAndEmployeeNumber:
      'Kostnadsställe, Avdelning, Anställningsnummer',
    sortByBirthday: 'Ålder',
    sortByBirthdayNoYear: 'Födelsedatum'
  },
  planning: {
    ok: 'OK',
    nav: 'Planering',
    modalHeader: 'Planering, filter',
    date: 'Datum',
    numberOfWeeks: 'Antal veckor',
    selection: 'Urval',
    allEmployees: 'Samtliga personer',
    dimensionCodes: 'Tidkontotyp',
    dimensionValues: 'Tidkonto',
    allDimensionCodes: 'Alla tidkontotyper',
    allDimensionValues: 'Alla tidkonton',
    employee: 'Person',
    all: 'Alla personer',
    sortingScheme: 'Sorteringsordning',
    cardNumber: 'Kortnummer',
    from: 'Från',
    to: 'Till',
    sortAgain: 'Sortera om',
    changeWeek: 'Byt vecka',
    employeeSubTitle: 'Arb: {{ workTime }} Rast: {{ breakTime }} Betald tid: {{ paidTime }}',
    back: 'Föreg.',
    next: 'Nästa',
    scheduleSettingHeader: 'Inställning för schema',
    interval: 'Intervall',
    min: 'min',
    zoom: 'Zoom',
    searchPerson: 'Sök person',
    costAccounts: 'Planera tidkonton',
    allCostAccounts: 'Alla tidkonton',
    weeklyTime: 'Veckoarb tid',
    chooseDate: 'Val av dag',
    week: `Vecka: {{ weekNumber }}`,
    address: 'Adress',
    postNumber: 'Postnummer',
    county: 'Ort',
    telephone: 'Telephonenummer',
    mobile: 'Mobiltele',
    email: 'E-Post adress',
    employment: 'Anställningsform',
    employmentDate: 'Anställningsdatum',
    showEmployeeDetails: 'Visa extra personinfo',
    relatives: 'Närmast anhöriga',
    hourWeek: 'Tim/vecka',
    showStaffingTable: 'Bemanning',
    staffingTable: 'Bemanningstabeller',
    fullTimeEmployment: 'Heltid',
    partTimeEmployment: 'Deltid',
    totalForUnit: 'Totalt för enheten',
    totalPaidTime: 'Totalt betald tid',
    actualTable: 'Faktisk Tabell',
    wantedTable: 'Önskad Tabell',
    contextMenu: {
      fullAbsence: 'Heldagsfrånvaro',
      partAbsence: 'Deltidsfrånvaro',
      displaySettings: 'Visningsinställningar',
      showApprovedTime: 'Visa godkänd extratid',
      temporaryEmployment: 'Tillfälligt anst.kontrakt',
      manageDocuments: 'Hantera dokument',
      showContractsInfo: 'Visa kontraktsinformation',
      hideContractsInfo: 'Göm kontraktsinformation',
      planLock: 'Planeringslås',
      insertPlanLock: 'Sätt ett planeringslås',
      changePlanLock: 'Ändra planeringslåset',
      deletePlanLock: 'Ta bort planeringslås',
      planLockLog: 'Planeringslåslogg',
      getEmployees: 'Hämta anställda',
      cut: 'Klipp ut',
      copy: 'Kopiera',
      paste: 'Klistra in',
      undo: 'Ångra',
      redo: 'Gör om',
      showPasteOptions: 'Visa alternativ vid klistra in',
      hideCreatedMessages: 'Dolj automatisk skapade meddelanden',
      deleteMessages: 'Radera meddelanden',
      setDynamicBorder: 'Använd dynamisk gräns'
    },
    settings: {
      startTime: 'Starttid',
      endTime: 'Sluttid',
      sorting: 'Sortering',
      costCenterOrder: 'Kostnadsställesordning',
      nameOrder: 'Namnordning',
      cardNumberOrder: 'Kortnummerordning',
      externalIdOrder: 'Ordning enligt Externt ID',
      earlyStartTime: 'Tidiga starttider först',
      withoutSchedule: 'Personer utan schema sist',
      alphabeticalOrder: 'Bokstavsordning',
      hideUnscheduled: 'Dölj schemalösa personer',
      hideAbsence: 'Dölj frånvarokodade personer',
      extraTimeAccount: 'Visa extra tidkonto',
      weather: 'Visa väder'
    },
    errors: {
      saveFailed: 'Det gick inte att spara ändringar: {{ reason }}',
      lockFailed: 'Det gick inte att låsa schemat för uppdatering: {{ reason }}'
    },
    alert: {
      undoFailedDueAuth: {
        caption: 'Ångra/gör om-kommandot misslyckades',
        message: 'Det är inte möjligt att ångra/göra om på grund av att behörighet för detta saknas'
      },
      undoFailedDueLock: {
        caption: 'Ångra/gör om-kommandot misslyckades',
        message:
          'Det är inte möjligt att sätta lås på alla scheman då vissa redan är låsta av andra användare.\n\n' +
          'För att försöka igen - svara nej på nedanstående fråga och försök sedan Ångra/Gör om igen.\n' +
          'För att ta bort denna återställningspunkt och öppna för möjligheten att försöka återställa övriga, ' +
          'nu blockerade, förändringar - svara ja på nedanstående fråga och gör sedan ett nytt försök med Ångra/Gör om.\n\n' +
          'Ska återställningspunkten tas bort?'
      }
    },
    staffing: {
      totals: 'Totalt',
      actual: 'Faktisk',
      orgUnitTotals: 'Totalt för enheten',
      totalTime: 'Tot tid',
      totalPaidTime: 'Tot tid inkl OB'
    },
    paste: {
      title: 'Alternativ för klistra in',
      selectOption: 'Välj alternativ för klistra in',
      mergeOverride: 'Slå ihop schema från klippbordet med befintligt schema',
      mergeExtend: 'Fyll ut befintligt schema med schema från klippbordet',
      replace: 'Ersätt befintligt schema',
      swap: 'Byt schema',
      copyMessage: '* Arbetspass kopierat fr.',
      mergeMessage: '* Arbetspass sammanslaget med'
    }
  },
  widgets: {
    add: 'Lägg till widget',
    edit: 'Redigera/flytta/ta bort widgets',
    ok: 'OK',
    chooseWidgets: 'Välj widgets',
    attendanceTable: 'Närvarotablå',
    weather: 'Väder',
    calendar: 'Kalender',
    higher: 'Higher',
    orgUnitLogo: 'Enhets logotyp',
    attendanceTableDescription: 'Visar information om anställdas närvarostatus',
    weatherDescription: 'Visar aktuellt väder och prognos',
    calendarDescription: 'Visar kalender tillsammans med aviseringar och påminnelser',
    higherDescription:
      'Higher är nästa generations rekryteringsverktyg där kandidaten söker jobb direkt i mobilen via tester och video istället för CV och personligt brev',
    orgUnitLogoDescription: 'Visar logotypen för den aktuella organisationsenheten',
    readOnlyMode: 'Dashboardvy',
    editWidget: 'Flytta eller ändra storlek på widget',
    deleteWidget: 'Ta bort widget',
    restoreDefaults: 'Återställ till default',
    saveChanges: 'Spara förändringar',
    discardChanges: 'Ångra ändringar',
    dashboardConfiguration: 'Dashboard-inställningar',
    alert: {
      restoreDefaults: {
        caption: 'Återställ till default',
        message: 'Nuvarande uppsättning kommer att återställas till default. Fortsätt?'
      },
      discardChanges: {
        caption: 'Ångra ändringar',
        message: 'Är det säkert att gjorda förändringar ska ångras?'
      },
      removeWidget: {
        message: 'Är du säker på att du vill ta bort widgeten {{ name }} permanent?'
      }
    }
  },
  admin: {
    name: 'Namn',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    customerId: 'Kundnummer',
    id: 'ID',
    customerName: 'Kundnamn',
    customerDescription: 'Kundbeskrivning',
    fullName: 'Fullständiga namn',
    userName: 'Användarnamn',
    phone: 'Telefon',
    email: 'E-post',
    validFrom: 'Giltig från',
    validTo: 'Giltig till',
    description: 'Beskrivning',
    from: 'Från',
    to: 'Till',
    search: 'Sök',
    channel: 'Kanal',
    timeFormat: 'HH:MM',
    areYouSure: 'Fortsätt radera?',
    addNew: 'Lägg till ny',
    disable: 'Inaktivera',
    enable: 'Aktivera',
    message: 'Meddelande',
    status: 'Status',
    itsYou: 'det är du',
    total: 'Total',
    deviceName: 'Enhetsnamn',
    deviceId: 'Enhets ID',
    timestamp: 'Tidsstämpel'
  },
  errors: {
    pleaseSelectChannelLevel: 'Välj en kanalnivå',
    pleaseSelectPlatformVersion: 'Välj en plattformsversion',
    pleaseSelectHostPort: 'Välj en serverport',
    pleaseSelectCurrency: 'Välj valuta',
    pleaseSelectClientType: 'Välj klienttyp',
    pleaseSelectFilePath: 'Välj sökväg',
    pleaseSelectCustomerInstance: 'Välj kundinstans',
    invalidDate: 'Ogiltigt datum'
  },
  sessions: {
    systemHost: '[Serversystem]',
    systemInstance: '[Systeminstans]',
    sessionStart: 'Session start',
    sessionEnd: 'Session slut',
    sessionLogs: 'Sessionsloggar',
    host: 'Server',
    hosts: 'Servrar',
    userSessions: 'Användarsessioner',
    supportSessions: 'Supportsessioner',
    adminSessions: 'Adminsessioner',
    clientType: 'Klienttyp'
  },
  userManagement: {
    users: 'Användare',
    admin: 'Administrerare',
    password: 'Lösenord',
    devices: 'Enheter',
    deviceName: 'Enhetsnamn',
    deactivateUser: 'Inaktivera användare',
    userWasDeactivated: 'Användaren inaktiverades',
    noDevices: 'Användaren har inga enheter',
    deviceWasDeleted: 'Den här enheten togs bort',
    scanCode: 'Skanna koden med enhet och ställ in önskat enhetsnamn',
    adminUsers: 'Adminanvändare',
    externalSystems: 'Externt system',
    accessKey: 'Global åtkomstnyckel',
    generateNewAccessKey: 'Generera ny global åtkomstnyckel',
    confirmOperation: 'Godkänn åtgärd',
    confirmKeyGenerationMessage: 'Nuvarande nyckel kommer att ersättas med den nya. Fortsätt?',
    confirmExtSysRemovalMessage:
      'Det externa systemet <b>{{name}}</b> kommer att raderas. Fortsätt?',
    usersCount: 'Antal kopplade kundmiljöer',
    accessOverview: 'Åtkomstöversikt',
    expirationDate: 'Utgångsdatum',
    extend: 'Förläng',
    noActiveUsers: 'Det finns inga aktiva kopplingar till kundmiljöer',
    statuses: {
      externalSystemAdded: 'Ett externt system har lagts till',
      externalSystemUpdated: 'Ett externt system har uppdaterats',
      externalSystemDeleted: 'Ett externt system har raderats',
      expirationDateExtended: 'Utgångsdatumet för det externa systemet har förlängts'
    }
  },
  packageManagement: {
    path: 'Sökväg',
    invalidFilePath: 'Ogiltig sökväg.',
    bootstrap: 'Bootstrapper (TIL-Tid-Starter.exe)',
    file: 'Fil',
    filePath: 'Filsökväg',
    clientVersion: 'Klientversion',
    requireAuth: 'Kräv autentisering',
    chooseHosts: 'Välj servrar',
    uploadWasFailed:
      'Uppladdning av filen <b>{{fileName}}</b> till servern <b>{{server}}</b> misslyckades på grund av felen:',
    errors: {
      notAllRequiredParams: 'Fyll i alla obligatoriska fält och försök igen',
      directoryNotFound: 'Katalogen som angavs i sökvägen kunde inte hittas',
      versionAndPathIdAreNotUnique:
        'TTClient med den här versionen och filtillägget finns redan. Ändra klientversion eller filtillägg och försök igen'
    }
  },
  serverManagement: {
    hosts: 'Servrar',
    localPcName: 'Lokalt PC-namn',
    customerInstancesCount: 'Antal kundinstanser',
    number: 'Antal'
  },
  customerInstances: {
    noData: 'Det finns inga kundfall',
    id: 'Kundinstans-ID',
    instanceName: 'Instansnamn',
    platformVersion: 'Plattformsversion',
    channelLevel: 'Kanalnivå',
    selectHosts: 'Välj Servrar',
    selectPlatforms: 'Välj plattformar',
    selectPlatformVersions: 'Välj plattformsversioner',
    selectVersions: 'Välj versionskanaler',
    selectReleaseVersions: 'Välj Releaseversioner',
    taskName: 'Arbetsnamn',
    lastRun: 'Senaste körning',
    maitenanceMessage: 'Meddelande',
    maitenanceMessageText: 'Underhållsmeddelande',
    requireTwoFactorAuth: 'Tvåfaktorsautentisering',
    port: 'Port',
    manageOrgUnits: 'Hantera org enheter',
    manageServices: 'Hantera tjänster',
    instanceWasPublished: 'Denna kundinstans är publicerad',
    services: 'Tjänster',
    dataphor: 'Dataphor',
    tTService: 'TTService',
    noInfo: 'Informationen är inte tillgänglig',
    serviceStart: 'Starta',
    serviceStop: 'Stoppa',
    refreshServices: 'Uppdatera tjänster',
    dataphorServiceIsStopped: 'Dataforservice har stoppats. Den kan startas i Hantera tjänster',
    chooseToDelete: 'Välj vad som ska raderas:',
    deleteInCustomerDB: 'CustomerDB Dataphor',
    deleteInPostgreDB: 'Postgre DB',
    enterPasswordToDelete: 'Ange lösenord för att verifiera den här åtgärden',
    scripting: 'Skript',
    noScripts: 'Det finns inga skript för {{ version }} version',
    maintenanceWasDeleted: 'Underhållsfönstret har raderats',
    orgUnits: {
      orgNumber: 'Organisationsnummer',
      currency: 'Valuta',
      manageHierarchy: 'Hantera hierarki',
      hierarchy: 'Hierarki',
      street: 'Gatuadress',
      zipCode: 'Postnummer',
      pyramidNo: 'PyramidNummer',
      orgUnitWasDeleted: 'Organisationsenheten har tagits bort'
    },
    publishLog: {
      notPublished: 'Ej publicerad',
      status: 'Publiceringsstatus',
      publish: 'Publicera',
      publishing: 'Publicerar',
      published: 'Publicerad',
      publishingLog: 'Publiceringslogg'
    },
    restore: {
      restoreAndConvert: 'Återställ & konvertera',
      restoreFromTemplate: 'Återställ från mall',
      convertFromTT1: 'Konvertera från TT1',
      restoreFromBackup: 'Återställ från säkerhetskopia',
      startTime: 'Starttid i format HH:MM',
      backup: 'Säkerhetskopieringsväg',
      restoreLog: 'Återställningslogg',
      templateRestoreLog: 'Mall återställningslogg',
      convertLog: 'Konverteringslogg',
      restored: 'Återställd',
      converted: 'Konverterad',
      template: 'Mall',
      pleaseEnterDataForTemplate:
        'Välj mall och ange ditt lösenord för att verifiera återställningen',
      pleaseEnterDataForRestoreBackup:
        'Ange starttid, sökväg och lösenord för att verifiera återställningen'
    },
    statuses: {
      failed: 'Misslyckades',
      success: 'Lyckades',
      running: 'Pågår',
      unknown: 'Okänd',
      inaccessible: 'Otillgänglig',
      refresh: 'Ladda om',
      seeStatus: 'Se status',
      status: 'Kundinstansstatus'
    },
    backups: {
      timeForExecution: 'Utförandestid (i minuter)',
      backupsShowAll: 'Visa allt',
      showWithBackups: 'Visa endast med säkerhetskopiering',
      showWithoutBackups: 'Visa endast utan säkerhetskopiering',
      ttBackups: 'Endast TT-säkerhetskopior',
      postgreBackups: 'Endast PostgreSQL Backup',
      archive: 'Arkiv',
      replace: 'Byt ut',
      repeatDaily: 'Upprepa dagligen',
      isPostgre: 'PostgreSQL-databas',
      backupTTTask: 'Backup TT-uppgift',
      backupPostgreTask: 'Backup PostgreSQL-uppgift',
      noRunTime: 'Den här säkerhetskopieringsuppgiften har inte körts ännu'
    },
    scripts: {
      status: 'Status',
      runScript: 'Kör',
      script: 'Välj skript',
      noPathError: 'Det går inte att köra skriptet utan en sökväg. Välj skript'
    },
    conversions: {
      taskName: 'Arbetsnamne',
      taskState: 'Jobbstatus',
      conversionState: 'Konverteringsstatus',
      conversionStart: 'Slutförd',
      conversionDescription: 'Konverteringsbeskrivning',
      lastRun: 'Senaste körningen',
      nextRun: 'Nästa körning',
      lastTaskResult: 'Senaste körresultatet',
      log: 'Logg',
      logFor: 'Logga in för en {{ name }}',
      statusUnknown: 'Okänd',
      statusCanceled: 'Avbruten',
      statusDisabled: 'Inaktiverad',
      statusQueued: 'Köad',
      statusReady: 'Redo',
      statusRunning: 'Kör',
      statusStart: 'Startar konverteringsjobbet',
      statusReadyForBackUp: 'Skapar backup',
      statusReadyForCopy: 'Kopierar backup',
      statusReadyForPublish: 'Publicerar instans',
      statusReadyForRestore: 'Läser in backup',
      statusReadyToComplete: 'Återställer',
      statusReadyToStartServices: 'Startar tjänster',
      statusReadyToCleanUp: 'Städar upp',
      statusConversionFinished: 'Konverteringsjobbet lyckades',
      dateAndTime: 'Konverteringens starttid',
      taskDeleted: 'Konverteringsjobbet har tagits bort',
      tasksDeleted: 'Konverteringsjobben har tagits bort',
      taskWasCreated: 'Konverteringsjobbet skapades',
      errorMessageReason:
        ' Konverteringsjobbet {{ id }} kan inte inte tas bort på grund av att det ligger för nära i tid eller redan körs {{ error }}',
      renameInstance: 'Byt namn på kunddatabas',
      enterInstanceName: 'Ange instansnamn'
    }
  },
  dateMessages: {
    caption: 'Dagmeddelanden',
    edit: 'Ändra',
    access: 'Åtkomst',
    delete: 'Radera',
    save: 'Spara',
    cancel: 'Avbryt',
    noMessages: 'Inga meddelanden ännu',
    messageType: 'Typ',
    add: 'Lägg till meddelande',
    close: 'Stäng',
    visibleTo: 'Synlig för',
    everyone: 'Alla',
    restrictedAccess: 'Begränsad åtkomst',
    note: 'Anteckning',
    campaign: 'Kampanj',
    reminder: 'Kom ihåg!',
    campaignDescription:
      ' - kommer att visas och vara tillgänglig i planeringsvyn och rätta tider och visas också i kalendern på startsidan.',
    reminderDescription:
      ' - kommer att visas och vara tillgänglig i planeringsvyn, rätta tider och även finnas under “Påminnelser” på startsidan.',
    messagePlaceholder: 'Skriv ett meddelande här',
    addFailed: 'Meddelandet kunde inte läggas till',
    updateFailed: 'Meddelandet kunder inte uppdateras',
    deleteFailed: 'Meddelandet kunde inte tas bort',
    accessBlock: {
      header: 'Meddelanden ska vara synliga för',
      everyone: 'Alla inom aktuell enhet',
      restrictedAccess: 'Begränsad åtkomst',
      costAccounts: 'Enligt kostnadsställe',
      users: 'Specifika användare',
      ok: 'OK',
      cancel: 'Avbryt'
    },
    alert: {
      caption: 'Bekräfta åtgärder',
      deleteConfirmation: 'Valt meddelande kommer att raderas. Fortsätt?'
    }
  },
  higher: {
    higherKPI: "Higher KPI'er",
    kpi: 'KPI',
    allActiveJobs: 'Alla jobb',
    aggregates: {
      newestApplications: 'Nya ansökningar',
      unhandledApplications: 'Ej hanterade ansökningar',
      totalApplications: 'Totalt antal ansökningar',
      totalJobs: 'Totalt antal jobb',
      numberOfHiredCandidates: 'Anställda',
      numberOfCandidatesExportedToTilTid: 'Exporterade till TIL-Tid'
    },
    period: {
      week: 'Senaste 7 dagarna',
      twoWeeks: 'Senaste 14 dagarna',
      month: 'Senaste 30 dagarna',
      twoMonths: 'Senaste 60 dagarna',
      allTime: 'Alla dagar'
    },
    slogan: 'Framtidens rekryteringsverktyg',
    signUp: 'Klicka för att få veta mer och ansluta till Higher',
    connect: 'Logga in för att visa upp nyckelvärden från Higher',
    signIn: 'Logga in i Higher',
    selectCompany: 'Välj ett företag från listan nedan',
    settings: {
      caption: 'Ändra visningsinställningar',
      aggregationPeriod: 'Sammanställningsperiod',
      visibleAggregates: 'Välj värden som ska vara synliga',
      company: 'Företag'
    }
  },
  employee: {
    title: 'TIL-Tid Anställd',
    header1: 'Komplettering av personuppgifter',
    addHeader: 'Personuppgifter avseende en ny närmast anhörig',
    editHeader: 'Redigering av personuppgifter avseende en närmast anhörig',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    ssn: 'Personnummer',
    birthdate: 'Födelsedag',
    gender: 'Kön',
    male: 'Man',
    female: 'Kvinna',
    none: 'Ingen',
    city: 'Postort',
    address: 'Adress',
    zipCode: 'Postnummer',
    country: 'Land',
    description: 'Beskrivning',
    emails: 'E-post',
    enterEmail: 'Mejladress',
    addMore: 'Lägg till fler',
    phones: 'Telefonnummer',
    enterPhoneNumber: 'Telefonnummer',
    photo: 'Foto',
    thankYou: 'Tack för att du fyller i din information!',
    addRelative: 'Lägg till en närmast anhörig',
    deleteRelative: 'Ta bort person ',
    relative: 'Närmast anhörig',
    add: 'Lägg till',
    phone: 'Telefon',
    mobile: 'Mobil',
    areYouSure: 'Är det säkert att den här personen ska tas bort?',
    additionalInfo: 'Tilläggsinformation',
    enterData: 'Text',
    accessWillBeInvalidated:
      'I samband med att data sparas kommer länken som användes för att nå denna sida att inaktiveras. Det kommer därför inte att vara möjligt att använda den igen för att komplettera med mer information.',
    errors: {
      invalidSsn: 'Personnumret är ogiltigt',
      invalidPhone: 'Telefonnumret {{ phone }} är ogiltigt',
      invalidEmail: 'Mejladressen {{ email }} är ogiltig',
      invalidAccessKey:
        'Åtkomstnyckeln har löpt ut och är inte längre giltig. Be administratören skicka en ny länk om behov att ändra på personinformation finns'
    },
    display: {
      showFirstLastName: 'Visa Förnamn Efternamn',
      showLastFirstName: 'Visa Efternamn Förnamn ',
      showSpeedCode: 'Visa kortnummer',
      showEmployeeNo: 'Visa anställningsnummer',
      showCostAccount: 'Visa kostnadsställesordning',
      showDepartment: 'Visa avdelningsordning'
    }
  }
};
