import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { showUnexpectedError } from './toast';
import { getPayrollFormat, HRPlus8_Id, ICustomerPayrollFormatsCurrent } from '../api/auth/users';
import { useSession } from './useSession';
import { checkFuncRights } from '../utils/common';
import { getUser, IUserInfo } from '../api/customer/user';
import UserPrivilegesContainer from './userPrivilegesContainer';
import { useLazyDataProvider } from './useLazyDataProvider';
import { useCallback } from 'react';

interface IAccessToApps {
  showWebClient: boolean;
  showPayroll: boolean;
  showDownload: boolean;
  hasAccessOnlyToOneApp: boolean;
}

interface ICurrentUserContext {
  currentUser: IUserInfo | null;
  hasAccessToApps: IAccessToApps;
  hasPayrollFormat(): boolean;
  isHRPlus8(): boolean | undefined;
  getPayrollCommand(): string;
  privilegesContainer: UserPrivilegesContainer;
}

const CurrentUserContext = createContext<ICurrentUserContext>(null as any);

export function CurrentUserContextProvider(props: { children: JSX.Element }) {
  const { session, token } = useSession();
  const [currentUser, setCurrentUser] = useState<IUserInfo | null>(null);
  const [privilegesContainer, setPrivilegesContainer] = useState(new UserPrivilegesContainer());

  useEffect(() => {
    if (session?.UserId) {
      getUser(session?.UserId)
        .then(data => setCurrentUser(data))
        .catch(error => showUnexpectedError(error));
    } else {
      setCurrentUser(null);
    }
  }, [session?.UserId]);

  const { getData: getPayrollInfo } = useLazyDataProvider<
    ICustomerPayrollFormatsCurrent | undefined
  >(
    undefined,
    async () => {
      let payrollFormat: ICustomerPayrollFormatsCurrent | undefined = undefined;
      try {
        const { data } = await getPayrollFormat();
        payrollFormat = data;
      } catch (e) {
        showUnexpectedError(e);
      }

      return payrollFormat;
    },
    [token]
  );

  const hasAccessToApps = useMemo(() => {
    const access = session ? checkFuncRights(session.FuncRights) : undefined;
    return {
      showPayroll: access?.showPayroll ?? false,
      showDownload: access?.showDownload ?? false,
      showWebClient: access?.showWebClient ?? false,
      hasAccessOnlyToOneApp: access?.hasAccessOnlyToOneApp ?? false
    };
  }, [session]);

  useEffect(() => {
    if (!session || !currentUser) return;

    const privileges = new UserPrivilegesContainer({
      userInfo: currentUser,
      funcRights: session.FuncRights,
      orgRights: session.CostAccountRights
    });

    setPrivilegesContainer(privileges);
  }, [currentUser, session?.FuncRights, session?.CostAccountRights]);

  const hasPayrollFormat = useCallback(() => {
    const info = getPayrollInfo();
    return info ? !!info.PayrollFormat : true;
  }, [getPayrollInfo]);

  const isHRPlus8 = useCallback(() => {
    return getPayrollInfo()?.PayrollFormat?.Id === HRPlus8_Id;
  }, [getPayrollInfo]);

  const getPayrollCommand = useCallback(() => {
    return getPayrollInfo()?.PayrollFormat?.Command ?? '';
  }, [getPayrollInfo]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        hasAccessToApps,
        hasPayrollFormat,
        isHRPlus8,
        getPayrollCommand,
        privilegesContainer
      }}
    >
      {props.children}
    </CurrentUserContext.Provider>
  );
}

export function useCurrentUserContext() {
  const data = useContext(CurrentUserContext);

  if (!data) {
    throw new Error(`You should use useCurrentUserContext only with CurrentUserContextProvider`);
  }

  return data;
}
