import { useCallback } from 'react';
import { useNavigate, Search, Hash } from 'react-router-dom';

export interface INavigateConfig {
  params?: (string | number)[];
  search?: Search | URLSearchParams;
  hash?: Hash;
}

/**
 * Base hook for navigation, should not be used directly form a component
 * Rather use corresponding application hook:
 *      useAuthNavigation
 *      useWebClientNavigation
 *      useAdminNavigation
 */
export function useNavigation() {
  const router_navigate = useNavigate();

  const navigate = useCallback(
    (args: { basePath: string; route: string; config?: INavigateConfig }) => {
      // make sure we have absolute base path
      let basePath = args.basePath;
      if (!basePath.startsWith('/')) {
        basePath = `/${basePath}`;
      }
      if (!basePath.endsWith('/')) {
        basePath = `${basePath}/`;
      }

      // Replace parameters in route url
      let route = args.route;
      if (args.config?.params?.length) {
        const params = args.config.params;
        let paramIndex = 0;
        const routeParts = args.route.split('/').map(part => {
          if (!part.startsWith(':') || paramIndex >= params.length) {
            return part;
          }

          return params[paramIndex++].toString();
        });

        route = routeParts.join('/');
      }

      const pathname = `${basePath}${route}`;
      const search = args.config?.search?.toString();
      const hash = args.config?.hash;

      router_navigate({ pathname, search, hash });
    },
    [router_navigate]
  );

  return navigate;
}
