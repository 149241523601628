// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo-module__root--N0qxW0eYLC6qiIIZBD5_{width:100%;text-align:center}.logo-module__img--pEjiYmzNmnU0G5mZY3Oy,.logo-module__root--N0qxW0eYLC6qiIIZBD5_{margin:0 auto}.logo-module__img--pEjiYmzNmnU0G5mZY3Oy{display:block;width:240px;height:66px}.logo-module__slogan--jmBJO3JEPosWdzY7CBB0{font-size:14px;color:rgb(var(--brand-color-white))}", "",{"version":3,"sources":["webpack://./packages/ui-components/src/logo/logo.module.css"],"names":[],"mappings":"AAAA,yCACE,UAAW,CAEX,iBACF,CACA,iFAHE,aAQF,CALA,wCACE,aAAc,CACd,WAAY,CACZ,WAEF,CACA,2CACE,cAAe,CACf,mCACF","sourcesContent":[".root {\r\n  width: 100%;\r\n  margin: 0 auto;\r\n  text-align: center;\r\n}\r\n.img {\r\n  display: block;\r\n  width: 240px;\r\n  height: 66px;\r\n  margin: 0 auto;\r\n}\r\n.slogan {\r\n  font-size: 14px;\r\n  color: rgb(var(--brand-color-white));\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "logo-module__root--N0qxW0eYLC6qiIIZBD5_",
	"img": "logo-module__img--pEjiYmzNmnU0G5mZY3Oy",
	"slogan": "logo-module__slogan--jmBJO3JEPosWdzY7CBB0"
};
export default ___CSS_LOADER_EXPORT___;
