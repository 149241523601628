// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".forgot-form__buttons--b2FASHmLBJ7mlBlLV32a{margin-top:15px;display:flex;align-items:center}.forgot-form__buttons--b2FASHmLBJ7mlBlLV32a>button{margin-right:15px}.forgot-form__blockSpinner--NjLzfrt1YfqjwkpN9qrx{height:auto;position:absolute;transform:translate(-50%,-50%);top:50%;left:50%;z-index:100}.forgot-form__blockText--VejSmUpIxooaX8HBnpeY{margin-bottom:10px;display:block}.forgot-form__form--yJHC1SrBF3sViGOJ1XHo>input{width:100%}.forgot-form__form--yJHC1SrBF3sViGOJ1XHo>*{margin-bottom:10px}", "",{"version":3,"sources":["webpack://./packages/auth-portal/src/components/forgot-form/forgot-form.css"],"names":[],"mappings":"AAAA,4CACE,eAAgB,CAChB,YAAa,CACb,kBACF,CAEA,mDACE,iBACF,CAEA,iDACE,WAAY,CACZ,iBAAkB,CAClB,8BAAgC,CAChC,OAAQ,CACR,QAAS,CACT,WACF,CAEA,8CACE,kBAAmB,CACnB,aACF,CAEA,+CACE,UACF,CAEA,2CACE,kBACF","sourcesContent":[".buttons {\r\n  margin-top: 15px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.buttons > button {\r\n  margin-right: 15px;\r\n}\r\n\r\n.blockSpinner {\r\n  height: auto;\r\n  position: absolute;\r\n  transform: translate(-50%, -50%);\r\n  top: 50%;\r\n  left: 50%;\r\n  z-index: 100;\r\n}\r\n\r\n.blockText {\r\n  margin-bottom: 10px;\r\n  display: block;\r\n}\r\n\r\n.form > input {\r\n  width: 100%;\r\n}\r\n\r\n.form > * {\r\n  margin-bottom: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "forgot-form__buttons--b2FASHmLBJ7mlBlLV32a",
	"blockSpinner": "forgot-form__blockSpinner--NjLzfrt1YfqjwkpN9qrx",
	"blockText": "forgot-form__blockText--VejSmUpIxooaX8HBnpeY",
	"form": "forgot-form__form--yJHC1SrBF3sViGOJ1XHo"
};
export default ___CSS_LOADER_EXPORT___;
