import {
  startOfISOWeek,
  endOfISOWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  addYears,
  getYear,
  setISOWeek,
  subYears,
  differenceInDays,
  getDaysInMonth
} from 'date-fns';

export enum DatePosition {
  StartOfWeek = 'start of week',
  EndOfWeek = 'end of week',
  StartOfMonth = 'start of month',
  EndOfMonth = 'end of month',
  StartOfYear = 'start of year',
  EndOfYear = 'end of year'
}

export enum ISODateFormat {
  FullYearHyphens = 'yyyy-MM-dd',
  FullYear = 'yyyyMMdd',
  ShortYearHyphens = 'yy-MM-dd',
  ShortYear = 'yyMMdd',
  YearMonth = 'yyMM',
  Week = 'I',
  Full = 'PP'
}

export enum TTDateFormat {
  DayMonthYearShort = 'DDMMYY',
  DayMonthYearFull = 'DDMMYYYY',
  MonthDayYearShort = 'MMDDYY',
  MonthDayYearFull = 'MMDDYYYY',
  YearMonthDayShort = 'YYMMDD',
  YearMonthDayFull = 'YYYYMMDD'
}

export interface IDateParseOptions {
  dateFormat?: TTDateFormat;
  futureLookupYearsCount?: number;
  pastLookupYearsCount?: number;
}

const MAX_WEEKS_FUTURE = 40;
const MAX_WEEKS_PAST = MAX_WEEKS_FUTURE / 2;
export const DEFAULT_DATE_FORMAT = TTDateFormat.YearMonthDayFull;
export const DEFAULT_DATE_SEPARATOR = '-';
const FORMATS_BREAKDOWN: {
  [key in `${TTDateFormat}`]: ['day' | 'month' | 'year', number][];
} = {
  [TTDateFormat.DayMonthYearShort]: [
    ['day', 2],
    ['month', 2],
    ['year', 2]
  ],
  [TTDateFormat.DayMonthYearFull]: [
    ['day', 2],
    ['month', 2],
    ['year', 4]
  ],
  [TTDateFormat.MonthDayYearShort]: [
    ['month', 2],
    ['day', 2],
    ['year', 2]
  ],
  [TTDateFormat.MonthDayYearFull]: [
    ['month', 2],
    ['day', 2],
    ['year', 4]
  ],
  [TTDateFormat.YearMonthDayShort]: [
    ['year', 2],
    ['month', 2],
    ['day', 2]
  ],
  [TTDateFormat.YearMonthDayFull]: [
    ['year', 4],
    ['month', 2],
    ['day', 2]
  ]
};

export function repositionDate(date: Date, position?: DatePosition) {
  switch (position) {
    case DatePosition.StartOfWeek:
      return startOfISOWeek(date);
    case DatePosition.EndOfWeek:
      return endOfISOWeek(date);
    case DatePosition.StartOfMonth:
      return startOfMonth(date);
    case DatePosition.EndOfMonth:
      return endOfMonth(date);
    case DatePosition.StartOfYear:
      return startOfYear(date);
    case DatePosition.EndOfYear:
      return endOfYear(date);
    default:
      return date;
  }
}

/** Converts `TTDateFormat` into `ISODateFormat`
 * @param dateFormat `TTDateFormat`, optional, default format used if not provided
 * @param dateSeparator The separator string, optional, default separator is used if not provided
 */
export function getISODateFormat(dateFormat?: TTDateFormat | string, dateSeparator?: string) {
  let format = dateFormat ?? DEFAULT_DATE_FORMAT;
  const separator = dateSeparator ?? DEFAULT_DATE_SEPARATOR;

  if (dateFormat?.includes('-') || dateFormat?.includes('/')) {
    //non-ISO format
    format = dateFormat.replace(/[\/-]+/g, '').toUpperCase() as TTDateFormat;
  }

  if (!(format in FORMATS_BREAKDOWN)) {
    format = DEFAULT_DATE_FORMAT;
  }

  return splitFormat(format as TTDateFormat)
    .map(v => v.replace(/Y/g, 'y').replace(/D/g, 'd'))
    .join(separator);
}

/**
 * Parses date assuming it's a birthday date, so input in format YYMMDD won't be parsed as a future date
 * @param dateString A date string
 * @param options Options to be used during parsing
 * @returns Parsed `Date` or `undefined` if parsing failed
 */
export function parseDate(dateString: string, options?: IDateParseOptions): Date | undefined {
  const trimmed = dateString.trim();
  if (!trimmed) return undefined;

  const digitsOnly = Array.from(trimmed).every(char => char >= '0' && char <= '9');

  if (digitsOnly) {
    const date = translateWeekDate(trimmed, options);
    if (date) return date;

    return translateUnseparatedDate(trimmed, options);
  }

  return translateSeparatedDate(trimmed, options);
}

/**
 * Parses date assuming it's a birthday date, so input in format YYMMDD won't be parsed as a future date
 * @param dateString A date string
 * @returns Parsed `Date` or `undefined` if parsing failed
 */
export function parseBirthday(dateString: string): Date | undefined {
  return parseDate(dateString, { futureLookupYearsCount: 0, pastLookupYearsCount: 120 });
}

function splitFormat(dateFormat: TTDateFormat) {
  const parts = FORMATS_BREAKDOWN[dateFormat];
  let start = 0;
  return parts.map(([_, length]) => {
    const part = dateFormat.substring(start, start + length);
    start += length;
    return part;
  });
}

function translateWeekDate(dateString: string, options?: IDateParseOptions): Date | undefined {
  const number = parseInt(dateString);
  if (number < 1 || number > 9952) return;

  const week = number % 100;
  if (week < 1 || week > 53) return;

  let year: number | undefined = undefined;
  if (dateString.length === 3 || dateString.length === 4) {
    // 2 digits year was passed
    year = shortYearToFullYear(Math.floor(number / 100), options);
    if (year === undefined) return;
  }

  if (year === undefined) {
    // Year was not provided, we need to decide which year to use, current year is used by default
    const today = new Date();
    year = getYear(today);

    // We need to decide which year to use for the specified week number
    const dateLastYear = startOfISOWeek(setISOWeek(subYears(today, 1), week));
    const dateNextYear = startOfISOWeek(setISOWeek(addYears(today, 1), week));

    const daysDiffLastYear = differenceInDays(today, dateLastYear);
    const maxDiffLastYear = 7 * MAX_WEEKS_PAST;

    const daysDiffNextYear = differenceInDays(dateNextYear, today);
    const maxDiffNextYear = 7 * MAX_WEEKS_FUTURE;

    if (daysDiffLastYear <= maxDiffLastYear)
      // It's probably a week from the last year
      year--;
    else if (daysDiffNextYear <= maxDiffNextYear)
      // It's probably a week from the next year
      year++;
  }

  if (year === undefined) return;

  return startOfISOWeek(setISOWeek(new Date(year, 1), week));
}

function translateUnseparatedDate(strDate: string, options?: IDateParseOptions): Date | undefined {
  const format = options?.dateFormat ?? DEFAULT_DATE_FORMAT;
  const partsOrder = FORMATS_BREAKDOWN[format];
  const date = { day: -1, month: -1, year: -1 };

  if (strDate.length === 6) {
    date[partsOrder[0][0]] = parseInt(strDate.substring(0, 2));
    date[partsOrder[1][0]] = parseInt(strDate.substring(2, 4));
    date[partsOrder[2][0]] = parseInt(strDate.substring(4));

    const year = shortYearToFullYear(date.year, options);
    if (!year) return;
    date.year = year;
  } else if (strDate.length === 8) {
    let part = partsOrder[0][0];
    const partLength = () => (part === 'year' ? 4 : 2);

    let start = 0;
    date[part] = parseInt(strDate.substring(start, start + partLength()));

    start += partLength();
    part = partsOrder[1][0];
    date[part] = parseInt(strDate.substring(start, start + partLength()));

    start += partLength();
    part = partsOrder[2][0];
    date[part] = parseInt(strDate.substring(start, start + partLength()));
  } else if (strDate.length < 6) {
    // assuming the DDMM or MMDD format
    const dayFirst =
      format === TTDateFormat.DayMonthYearFull || format === TTDateFormat.DayMonthYearShort;

    const number = parseInt(strDate);
    const first = number % 100;
    const last = Math.floor(number / 100);
    date.day = dayFirst ? first : last;
    date.month = dayFirst ? last : first;
    date.year = getYear(new Date());
  }

  if (date.month < 1 || date.month > 12 || date.year < 1 || date.year > 9999) return;
  const tempDate = new Date(date.year, date.month - 1);
  if (date.day < 1 || date.day > getDaysInMonth(tempDate)) return;

  return new Date(date.year, date.month - 1, date.day);
}

function translateSeparatedDate(strDate: string, options?: IDateParseOptions): Date | undefined {
  const blocks: [number, number][] = [];
  let blockStart = -1;
  Array.from(strDate).forEach((char, index) => {
    if (char < '0' || char > '9') {
      if (blockStart !== -1) {
        blocks.push([blockStart, index - 1]);
        blockStart = -1;
      }
    } else if (blockStart === -1) {
      blockStart = index;
    }
  });

  if (blockStart !== -1) blocks.push([blockStart, strDate.length - 1]);

  const format = options?.dateFormat ?? DEFAULT_DATE_FORMAT;
  const partsOrder = FORMATS_BREAKDOWN[format];
  const date = { day: -1, month: -1, year: -1 };

  if (blocks.length >= 3) {
    let block = blocks[0];
    let part = partsOrder[0][0];
    date[part] = parseInt(strDate.substring(block[0], block[1] + 1));

    block = blocks[1];
    part = partsOrder[1][0];
    date[part] = parseInt(strDate.substring(block[0], block[1] + 1));

    block = blocks[2];
    part = partsOrder[2][0];
    date[part] = parseInt(strDate.substring(block[0], block[1] + 1));

    if (date.year < 100) {
      const year = shortYearToFullYear(date.year, options);
      if (!year) return;
      date.year = year;
    }
  } else if (blocks.length === 2) {
    // assuming the DDMM or MMDD format
    const first = parseInt(strDate.substring(blocks[0][0], blocks[0][1] + 1));
    const last = parseInt(strDate.substring(blocks[1][0], blocks[1][1] + 1));

    const dayFirst =
      format === TTDateFormat.DayMonthYearFull || format === TTDateFormat.DayMonthYearShort;
    date.day = dayFirst ? first : last;
    date.month = dayFirst ? last : first;
    date.year = getYear(new Date());
  } else {
    // At least 2 blocks of numbers should be present
    return undefined;
  }

  if (date.month < 1 || date.month > 12 || date.year < 1 || date.year > 9999) return;
  const tempDate = new Date(date.year, date.month - 1);
  if (date.day < 1 || date.day > getDaysInMonth(tempDate)) return;

  return new Date(date.year, date.month - 1, date.day);
}

function shortYearToFullYear(year: number, options?: IDateParseOptions): number | undefined {
  const today = new Date();
  const currentYear = getYear(today);
  const century = Math.floor(currentYear / 100);

  const minYear = getYear(subYears(today, options?.pastLookupYearsCount ?? 30));
  const maxYear = getYear(addYears(today, options?.futureLookupYearsCount ?? 20));

  let fullYear = century * 100 + year;
  if (fullYear <= maxYear && fullYear >= minYear) return fullYear;

  fullYear = (century + 1) * 100 + year;
  if (fullYear <= maxYear && fullYear >= minYear) return fullYear;

  fullYear = (century - 1) * 100 + year;
  if (fullYear <= maxYear && fullYear >= minYear) return fullYear;

  return undefined;
}
