// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alert-confirmation-module__alertButtons--kuDw02UFdbXIAPIQTmWA{text-align:center;padding:1rem}.alert-confirmation-module__button--XIZEvpzOQ7ELkQd9Bgqz{margin:0 5px;width:20%;min-width:-webkit-fit-content;min-width:-moz-fit-content;min-width:fit-content}.alert-confirmation-module__description--Yw6ioPuAAWKt1I0t7C8e{white-space:pre-wrap;padding:1rem}.alert-confirmation-module__dialog--knrkpButBTbRRe0b8RRE{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:min(500px,100% - 2em)}", "",{"version":3,"sources":["webpack://./packages/ui-components/src/alert-confirmation/alert-confirmation.module.css"],"names":[],"mappings":"AAAA,+DACE,iBAAkB,CAClB,YACF,CAEA,yDACE,YAAe,CACf,SAAU,CACV,6BAAsB,CAAtB,0BAAsB,CAAtB,qBACF,CAEA,8DACE,oBAAqB,CACrB,YACF,CAEA,yDACE,yBAAkB,CAAlB,sBAAkB,CAAlB,iBAAkB,CAClB,+BACF","sourcesContent":[".alertButtons {\r\n  text-align: center;\r\n  padding: 1rem;\r\n}\r\n\r\n.button {\r\n  margin: 0px 5px;\r\n  width: 20%;\r\n  min-width: fit-content;\r\n}\r\n\r\n.description {\r\n  white-space: pre-wrap;\r\n  padding: 1rem;\r\n}\r\n\r\n.dialog {\r\n  width: fit-content;\r\n  min-width: min(500px, 100% - 2em);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertButtons": "alert-confirmation-module__alertButtons--kuDw02UFdbXIAPIQTmWA",
	"button": "alert-confirmation-module__button--XIZEvpzOQ7ELkQd9Bgqz",
	"description": "alert-confirmation-module__description--Yw6ioPuAAWKt1I0t7C8e",
	"dialog": "alert-confirmation-module__dialog--knrkpButBTbRRe0b8RRE"
};
export default ___CSS_LOADER_EXPORT___;
