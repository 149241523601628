import { apiUrl1, authHTTPClient } from './auth-client';

export interface ICurrentUser {
  UserId: string;
  FirstName: string;
  LastName: string;
  StreetAddress: string;
  ZipCode: string;
  City: string;
  Country: string;
  Email: string;
  Username: string;
  IsSupportContact: boolean;
  IsSupport: boolean;
}

export interface ICurrentUserSetting {
  Filename: string | null;
  DateFormat: string | null;
  DateSeparator: string | null;
}

export enum UserLanguage {
  EN = 'en',
  SV = 'sv',
  NO = 'no',
  FI = 'fi'
}

export interface ICustomerInstance {
  Description: string;
  Id: number;
  Name: string;
}

export const HRPlus8_Id = 7;

export interface IPayrollFormat {
  Id: number;
  Name: string;
  Description: string;
  Command: string;
}

export interface ICustomerPayrollFormatsCurrent {
  CustomerInstance: ICustomerInstance;
  PayrollFormat: IPayrollFormat | null;
}

export async function getCurrentUser() {
  return authHTTPClient.get<ICurrentUser>(`${apiUrl1}/users/current`);
}

export async function getCurrentUserSettings() {
  const {data} = await authHTTPClient.get<ICurrentUserSetting>(`${apiUrl1}/users/current/regionalSettings`);

  return data;
}

export async function getPayrollFormat() {
  return await authHTTPClient.get<ICustomerPayrollFormatsCurrent>(
    `${apiUrl1}/customerPayrollFormats/users/current`
  );
}
