import React from 'react';
import cx from 'classnames';
import styles from './logo.module.css';

interface ILogoProps {
  src: string;
  slogan?: string;
  rootClassName?: string;
  imageClassName?: string;
  sloganClassName?: string;
  onClick?(): void;
}

export function Logo(props: ILogoProps) {
  const { src, slogan, rootClassName, imageClassName, sloganClassName } = props;

  return (
    <div className={cx(styles.root, rootClassName || '')} onClick={props?.onClick}>
      <img src={src} className={cx(styles.img, imageClassName || '')} />
      {slogan ? <span className={cx(styles.slogan, sloganClassName || '')}>{slogan}</span> : ''}
    </div>
  );
}
