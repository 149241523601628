import { apiUrl1, authHTTPClient } from './auth-client';
import { ExtendableError } from 'ts-error';
import { IAdminIntermediateSession } from '../admin/admin-auth';
import { IOrgUnit } from './ttclient';
import { AxiosError } from 'axios';

export class InvalidLoginError extends ExtendableError {}
export class InvalidConfirmationError extends ExtendableError {}
export class InvalidDeviceError extends ExtendableError {}

export interface IIntermediateSession {
  PhoneNumber: string;
  Customers: ICustomer[];
  Devices: IDevice[];
}

export interface IDevice {
  DeviceId: number;
  DeviceName: string;
}

export interface ICustomerBase {
  PlatformVersion: string;
  Id: number;
  Name: string;
  Description: string;
}

export interface ICustomer extends ICustomerBase {
  ValidFrom: Date;
  ValidTo: Date;
}

export interface IMaintenance {
  StartTime: string;
  EndTime: string;
  Description: string;
}

export interface ISession {
  PasswordExpired: boolean;
  SessionInfo: ISessionInfo;
  ApiBaseUri: string;
  ApiCustomerUri: string;
  IsEmployee: boolean;
  ActiveDirectoryLogin: boolean;
  UserId: string;
  FuncRights: number[];
  OrgUnits: IOrgUnit[];
  CostAccountRights: ICostAccountRight[];
  LatestSuccessfulLoginAttempts: ILatestSuccessfulLoginAttempt[];
  Authorization: string;

  PlatformVersion: string;
  PackageVersion: string;
  DatabaseInstanceUrl: string;
  PublicDatabaseInstanceUrl: string;
  TTServiceInstanceUrl: string;
  TTServiceHostLocalName: string;
  AppBindingUrl: string;
  CustomerId: string;
  CustomerName: string;
  CustomerDescription: string;
  WebClientUri: string;
}

export interface ICostAccountRight {
  CostAccountId: number;
  OrgUnitId: number;
  ModifyRight: boolean;
}

export interface ILatestSuccessfulLoginAttempt {
  Date: string;
  ClientType: string;
}

export interface ISessionInfo {
  EndOfLifeTimestamp: string;
  EndOfLifeRelative: number;
  FullTimeAssigned: boolean;
  MaintenancePlanned: boolean;
  MaintenanceStartTime: string;
  MaintenanceEndTime: string;
  MaintenanceMessage: string;
}

export interface IAuthRequest {
  CustomerId: string;
  LoginName: string;
  Password: string;
}

export interface IBankIDAuthRequest {
  PersonalNumber: string;
}

export interface IConfirmationRequest {
  CustomerId: number;
  LoginName: string;
  ConfirmationCode: string;
  IsGoogleAuth: boolean;
  DeviceId: number;
}

export interface IBankIDAuthFinishRequst {
  SignInId: string;
  CustomerId: number;
}

export interface IClientType {
  clientType: string;
}

export const enum LoginErrorCodes {
  InvalidCredentials = 'invalidCredentials',
  InvalidLoginName = 'invalidLoginName',
  InvalidDeviceId = 'invalidDeviceId',
  InvalidConfirmationCode = 'invalidConfirmationCode',
  Forbidden = 'Forbidden',
  BadRequest = 'Bad Request',
  NotFound = 'Not Found'
}

interface IInvokeSMSRequest {
  LoginName: string;
  CustomerId: string;
}

const TOKEN_LENGTH = 36 /* guid length */ + 3; /* `TT ` token prefix */

export const SESSION_RENEW_URL = `${apiUrl1}/auth/renew`;

export const SESSION_HEADERS = {
  'Content-Type': 'application/json',
  'TT-ClientType': 'WebPortal'
};

function extractSessionId(token: string) {
  return token && token.length === TOKEN_LENGTH ? token.slice(3) : null;
}

export function isSession(data: ISession | IIntermediateSession | IMaintenance): data is ISession {
  return 'SessionInfo' in data;
}

export function isIntermediateSession(
  data: ISession | IIntermediateSession | IAdminIntermediateSession | IMaintenance
): data is IIntermediateSession {
  return 'PhoneNumber' in data;
}

export function isMaintenance(
  data: ISession | IIntermediateSession | IMaintenance
): data is IMaintenance {
  return 'StartTime' in data;
}

export async function createSession(payload: IAuthRequest) {
  return await authHTTPClient.post<ISession | IIntermediateSession>(`${apiUrl1}/auth`, payload, {
    headers: SESSION_HEADERS
  });
}

export async function invokeSMSSend(payload: IInvokeSMSRequest) {
  return await authHTTPClient.post(`${apiUrl1}/auth/sms`, payload);
}

export async function getSession(authorization?: string) {
  const config = authorization ? { headers: { Authorization: authorization } } : undefined;
  return await authHTTPClient.get<ISession>(`${apiUrl1}/auth`, config);
}

export async function extendSession() {
  return await authHTTPClient.post<ISessionInfo>(`${apiUrl1}/auth/extend`);
}

export async function logout() {
  return await authHTTPClient.delete(`${apiUrl1}/auth`);
}

export async function confirmation(payload: IConfirmationRequest) {
  return await authHTTPClient.post<ISession>(`${apiUrl1}/auth/confirmation`, payload, {
    headers: SESSION_HEADERS
  });
}

export async function sendConfirmation(payload: IConfirmationRequest) {
  try {
    return await confirmation(payload);
  } catch (error) {
    const e = error as AxiosError;
    if (e?.response?.data.Code === LoginErrorCodes.InvalidConfirmationCode) {
      throw new InvalidConfirmationError();
    }

    if (
      e?.response?.data.Code ===
      (LoginErrorCodes.InvalidCredentials || LoginErrorCodes.InvalidLoginName)
    ) {
      throw new InvalidConfirmationError();
    }

    if (e?.response?.data.Code === LoginErrorCodes.InvalidDeviceId) {
      throw new InvalidDeviceError();
    }

    if (e?.response?.status === 403) {
      throw e;
    }

    throw new Error(e?.response?.data.Message);
  }
}

export async function renewSession(authorization: ISession['Authorization']) {
  const sessionId = extractSessionId(authorization);

  return await authHTTPClient.post<ISession>(
    SESSION_RENEW_URL,
    { SessionId: sessionId },
    { headers: SESSION_HEADERS }
  );
}

export async function verifySession(authorization?: string) {
  const config = authorization
    ? { headers: { ...SESSION_HEADERS, Authorization: authorization } }
    : undefined;
  return await authHTTPClient.post<IClientType>(`${apiUrl1}/auth/verify`, null, config);
}

export async function nextToExternal() {
  return await authHTTPClient.get<ISession>(`${apiUrl1}/auth/nextToExternal`, {
    withCredentials: true
  });
}

export async function login(payload: IAuthRequest) {
  try {
    const res = await createSession(payload);

    return res;
  } catch (error) {
    const e = error as AxiosError;
    if (
      e?.response?.data.Code ===
      (LoginErrorCodes.InvalidCredentials || LoginErrorCodes.InvalidLoginName)
    ) {
      throw new InvalidLoginError();
    }

    if (e?.response?.status === 403) {
      throw e;
    }

    throw new Error(e?.response?.data.Message);
  }
}
