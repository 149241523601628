export interface RGB {
  /** [0 - 255] */
  r: number;
  /** [0 - 255] */
  g: number;
  /** [0 - 255] */
  b: number;
}

export interface RGBA extends RGB {
  /** [0 - 255] */
  a: number;
}

export interface HSL {
  /** [0 - 360] */
  h: number;
  /** [0.0 - 1.0] */
  s: number;
  /** [0.0 - 1.0] */
  l: number;
}

export type HEX = `#${string}`;

export type Color = RGB | RGBA | HSL | HEX;

export function hex2rgb(hex: string): RGB {
  const [r, g, b] = (hex.match(/\w\w/g) || []).map((x: any) => parseInt(x, 16));
  return { r, g, b };
}

export function rgb2hex({ r, g, b }: RGB): HEX {
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
}

/**
 * Converts a HSL color to an RGB color.
 * Returns an RGB color object equivalent to the HSL color object passed in
 */
export function hsl2rgb(hsl: HSL): RGB {
  const { h, s, l } = hsl;
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let _r = 0;
  let _g = 0;
  let _b = 0;
  if (h >= 0 && h < 60) {
    [_r, _g, _b] = [c, x, 0];
  } else if (h >= 60 && h < 120) {
    [_r, _g, _b] = [x, c, 0];
  } else if (h >= 120 && h < 180) {
    [_r, _g, _b] = [0, c, x];
  } else if (h >= 180 && h < 240) {
    [_r, _g, _b] = [0, x, c];
  } else if (h >= 240 && h < 300) {
    [_r, _g, _b] = [x, 0, c];
  } else if (h >= 300 && h < 360) {
    [_r, _g, _b] = [c, 0, x];
  }

  const r = Math.round((_r + m) * 255);
  const g = Math.round((_g + m) * 255);
  const b = Math.round((_b + m) * 255);

  return { r, g, b };
}

/**
 * Converts a RGB color to an HSL color.
 * Returns an HSL color object equivalent to the RGB color object passed in
 */
export function rgb2hsl(rgb: RGB) {
  const _r = rgb.r / 255;
  const _g = rgb.g / 255;
  const _b = rgb.b / 255;

  const max = Math.max(_r, _g, _b);
  const min = Math.min(_r, _g, _b);
  const delta = max - min;

  const l = (max + min) / 2;
  const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  let h = 0;
  if (delta !== 0) {
    switch (max) {
      case _r:
        h = 60 * (((_g - _b) / delta) % 6);
        break;
      case _g:
        h = 60 * ((_b - _r) / delta + 2);
        break;
      case _b:
        h = 60 * ((_r - _g) / delta + 4);
        break;
    }
  }

  if (h < 0) h += 360;

  return { h, s, l };
}

export function int2rgb(color: number): RGB {
  return { r: color & 0xff, g: (color >> 8) & 0xff, b: (color >> 16) & 0xff };
}

export function rgb2int(color: RGB): number {
  return color.r | (color.g << 8) | (color.b << 16);
}

export function getSOWGradientColors(baseColor: RGB): [RGB, RGB] {
  const hslStart = rgb2hsl(baseColor);
  const hslStop = { ...hslStart };

  let lightnessShift = 0;
  if (hslStart.l > 0.7) lightnessShift = 0.1;
  else if (hslStart.l > 0.4) lightnessShift = 0.075;
  else lightnessShift = 0.05;

  hslStart.l = Math.min(1.0, hslStart.l + lightnessShift);
  if (hslStart.l > 0.98) hslStart.l = 0.98;

  hslStop.l = hslStop.l - lightnessShift;
  if (hslStop.l < 0.0) {
    hslStart.l += Math.abs(hslStop.l);
    hslStop.l = 0.02;
  }

  const startColor = hsl2rgb(hslStart);
  const stopColor = hsl2rgb(hslStop);

  return [startColor, stopColor];
}

export function getColorLuminance(rgb: RGB) {
  // Counting the perceptive luminance - human eye favors green color...
  return (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
}

export function getLighterColor(rgb: RGB) {
  const hsl = rgb2hsl(rgb);
  if (hsl.l <= 0.05) {
    hsl.l += 0.35;
  } else if (hsl.l <= 0.5) {
    hsl.l += -0.04 / (hsl.l - 0.62) + 0.07;
  } else {
    hsl.l += 0.021 / (hsl.l - 0.427) + 0.114;
  }

  if (hsl.l > 0.98) hsl.l = 0.98;

  const lightColor = hsl2rgb(hsl);

  return lightColor;
}

export function getContrastTextColor(bkColor: RGB, light?: RGB, dark?: RGB): RGB {
  const isLight = getColorLuminance(bkColor) > 0.57;
  if (isLight) {
    return dark ?? { r: 80, g: 80, b: 80 };
  } else {
    return light ?? { r: 245, g: 245, b: 245 };
  }
}
