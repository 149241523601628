export type Routes = { [key: string]: string };
export type FullRoutes<Parent extends string | null, Child extends Routes> = Parent extends string
  ? {
      [key in keyof Child]: `${Parent}/${Child[key]}`;
    }
  : {
      [key in keyof Child]: `${Child[key]}`;
    };

export function createRoutes<Parent extends string | null, Child extends Routes>(
  parent: Parent,
  child: Child
): FullRoutes<Parent, Child> {
  const prefix = parent === null ? "" : parent + "/";

  let result: any = {};
  Object.keys(child).map((key) => {
    result[key] = `${prefix}${child[key]}`;
  });
  return result as FullRoutes<Parent, Child>;
}
