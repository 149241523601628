import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Id as ToastId } from 'react-toastify';
import { showSuccess, showUnexpectedError } from '@lib/toast';
import { useSession } from '@lib/useSession';
import { changePassword, getPasswordRules } from '@api/auth/account';
import { Input } from '@ui/input';
import { Button, ButtonType } from '@ui/button';
import { Modal } from '../modal/modal';
import { useForm } from 'react-hook-form';
import { FormError } from '@ui/form-error';
import cx from 'classnames';
import styles from './change-password-component.module.css';

interface IChangePasswordProps {
  open: boolean;
  onClose?: (passwordChanged: boolean) => void;
}

interface IPasswordData {
  newPassword: string;
  newPasswordConfirm: string;
  error: string;
}

export default function ChangePasswordModal(props: IChangePasswordProps) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
    setError,
    clearErrors
  } = useForm<IPasswordData>({ shouldUseNativeValidation: true });
  const watchFields = watch(['newPassword', 'newPasswordConfirm']);
  const [isSaving, setIsSaving] = useState(false);
  const currentToast = useRef<ToastId>('');
  const submitButton = useRef<HTMLButtonElement | null>(null);
  const { session, updateSession } = useSession();
  const [passwordRulesText, setPasswordRulesText] = useState('');
  const [passwordPattern, setPasswordPattern] = useState('');

  useEffect(() => {
    let ignore = false;

    async function getRulesForPassword() {
      if (session) {
        try {
          const { data } = await getPasswordRules();
          const res = data.PasswordRules;
          if (ignore) return;

          setPasswordPattern(data.RegexPattern);
          setPasswordRulesText(
            t('password:passwordRulesText', {
              length: res.Length,
              capLetters: res.CapLetters,
              lowLetters: res.SmallLetters,
              numbers: res.Numbers,
              chars: res.SpecialChars
            })
          );
        } catch (e) {
          currentToast.current = showUnexpectedError(e);
        }
      }
    }

    getRulesForPassword();

    return () => {
      ignore = true;
    };
  }, [props.open]);

  const onClose = (passwordChanged: boolean) => {
    if (session?.PasswordExpired && !passwordChanged) {
      if (!window.confirm(t('password:areYouSure'))) return;
    }
    props.onClose?.(passwordChanged);
  };

  useEffect(() => {
    setFocus('newPassword');
  }, []);

  useEffect(() => {
    const pass = watchFields[0];
    const passConfirm = watchFields[1];
    if (!pass && !passConfirm) {
      return;
    }
    if (pass === passConfirm && submitButton.current) {
      submitButton.current.focus();
    }
  }, [submitButton, watchFields[0], watchFields[1]]);

  async function onSubmit(formData: IPasswordData) {
    if (formData.newPassword !== formData.newPasswordConfirm) {
      setError('error', { type: 'validate', message: t('password:errors:passwordsNotMatch') });
      return;
    }

    setIsSaving(true);

    try {
      await changePassword({ Password: formData.newPassword });
      currentToast.current = showSuccess(t('password:passwordHasBeenChanged'));

      const newSession = Object.assign({}, session, { PasswordExpired: false });
      setPasswordPattern('');
      setPasswordRulesText('');

      setIsSaving(false);
      onClose(true);
      updateSession(newSession);
    } catch (e) {
      currentToast.current = showUnexpectedError(e);
      setIsSaving(false);
      onClose(false);
    }
  }

  const modalBody = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cx(styles.wrapper, styles.scrollable)}>
        {session && session.PasswordExpired && (
          <div>
            <h3 style={{ marginTop: 0 }}>{t('login:updateTemporaryPassword')}</h3>
            <p cy-id="rules-text">{passwordRulesText}</p>
          </div>
        )}
        <Input
          id="newPassword"
          label={t('password:newPassword')}
          autoComplete="new-password"
          cy-id="password"
          type="password"
          required
          pattern={passwordPattern}
          placeholder={t('password:newPassword')}
          onInvalid={(e: React.InvalidEvent<HTMLInputElement>) => {
            e.target.setCustomValidity(t('password:errors:passwordInvalidFormat'));
          }}
          {...register('newPassword', {
            pattern: new RegExp(passwordPattern),
            required: true,
            onChange: e => {
              clearErrors('newPassword');
              e.target.setCustomValidity('');
            }
          })}
        />

        <Input
          label={t('password:newPassword2')}
          autoComplete="new-password-confirm"
          cy-id="confirm-password"
          type="password"
          required
          pattern={passwordPattern}
          placeholder={t('password:newPassword2')}
          onInvalid={(e: React.InvalidEvent<HTMLInputElement>) => {
            e.target.setCustomValidity(t('password:errors:passwordInvalidFormat'));
          }}
          {...register('newPasswordConfirm', {
            pattern: new RegExp(passwordPattern),
            required: true,
            onChange: e => {
              clearErrors('newPasswordConfirm');
              e.target.setCustomValidity('');
            }
          })}
        />

        {errors.error && errors.error.message && <FormError text={errors.error.message} />}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{ marginRight: 10 }}
          value={t('common:close')}
          onClick={() => {
            onClose(false);
          }}
          type={ButtonType.button}
        />
        <Button
          ref={(e: HTMLButtonElement) => {
            submitButton.current = e;
          }}
          name="change-modal-btn"
          cy-id="update-password"
          disabled={isSaving}
          value={isSaving ? t('common:pleaseWait') : t('password:save')}
          type={ButtonType.submit}
          tabIndex={-1}
          onClick={() => clearErrors('error')}
        />
      </div>
    </form>
  );

  return (
    <>
      <Modal
        cyId="change-password-modal"
        title={t('common:changePassword')}
        body={modalBody}
        onHide={() => {
          onClose(false);
        }}
        hidesOnBackdropClick={false}
        show={props.open}
      />
    </>
  );
}
