import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  Suspense
} from 'react';
import { AxiosRequestConfig, AxiosInstance } from 'axios';
import { authHTTPClient } from '../api/auth/auth-client';
import { customerHTTPClient } from '../api/customer/customer-client';
import {
  getSession,
  ISession,
  renewSession,
  verifySession,
  ISessionInfo,
  logout as apiLogout,
  SESSION_RENEW_URL
} from '../api/auth/session';
import { useStorage } from './useStorage';
import { useFeatureFlagsContext, Feature } from './feature-flags-context';
import { redirectToAuthPortal } from './redirect-to-app';
import SessionLifeTimeManager from './sessionLifeTimeManager';
import moment from 'moment';
import { IOrgUnit } from '../api/auth/ttclient';
import { Spinner } from '@ui/spinner';
import ChangePasswordModal from '@ui/user-menu-components/change-password-modal';
import { CURRENT_ORG_UNIT_KEY } from './constants/storage-keys';

export const AUTH_KEY = 'auth-data';

interface ISessionProvider {
  session: ISession | null;
  token: string;
  sessionInfo?: ISessionInfo;
  isLoading: boolean;
  orgUnits: IOrgUnit[];
  updateSession(session: ISession | null): void;
  updateSessionInfo(sessionInfo: ISessionInfo): void;
  logout(onLogout?: () => void): Promise<any>;
}

function isSessionExpired({ SessionInfo }: ISession) {
  const now = moment();
  const sessionEndOfLife = moment(SessionInfo.EndOfLifeTimestamp);
  return sessionEndOfLife < now;
}

function verifyApplicationVersion(appUrl: string): boolean {
  // Ignore this logic if it's not a production build
  if (process.env.NODE_ENV !== 'production') return true;

  const appUrlObject = new URL(appUrl);
  const appPathEntries = appUrlObject.pathname.split('/');

  const location = window.location;
  const locationPathEntries = location.pathname.split('/');

  if (
    appUrlObject.protocol !== location.protocol ||
    appUrlObject.host !== location.host ||
    appPathEntries.length > locationPathEntries.length ||
    appPathEntries.some((entry, index) => locationPathEntries[index] !== entry)
  ) {
    // we're on a wrong url, navigate to a correct one trying to maintain the rest of url
    const pathEnding = locationPathEntries.slice(appPathEntries.length);
    const urlEnding = (appUrl.endsWith('/') ? '' : '/') + pathEnding.join('/') + location.search;
    const url = appUrl + urlEnding;

    // Navigate here
    window.location.assign(url);
    return false;
  }

  // Looks we're on a correct url
  return true;
}

export const SessionContext = createContext<ISessionProvider>(null as any);

interface ISessionProps {
  isAuthPortal?: boolean;
  children: JSX.Element;
}

async function tryRenewSession(session: ISession) {
  if (!session?.Authorization) return null;
  const { data }: { data: ISession | null } = await renewSession(session.Authorization).catch(
    () => {
      return { data: null };
    }
  );
  return data;
}

async function tryGetSessionFromQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');

  if (token) {
    return await tryGetSession(token);
  }

  return null;
}

async function tryGetSession(token?: string) {
  const { data } = await getSession(token).catch(() => {
    return { data: null };
  });
  return data;
}

export function SessionProvider(props: ISessionProps) {
  const { isAuthPortal } = props;
  const { getItem, setItem, getFullKey } = useStorage();
  const [session, setSession] = useState<ISession | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { hasFeature } = useFeatureFlagsContext();
  const autoAuthenticated = hasFeature(Feature.AutoAuthenticated);

  useEffect(() => {
    // Here we try to obtain session

    let subscribed = false;

    if (!autoAuthenticated) {
      if (isAuthPortal) {
        // Auth-portal case

        // Auth portal only looks for valid session in local storage,
        // if there's no - continues rendering with session = null

        tryGetSessionFromLocalStorage().then(session => {
          finishSessionLoading(session);
        });
      } else {
        // Web-client case

        // Web-client at first looks for ticket in query params, then in local storage
        // + tries to renew session if local storage contains expired or invalid session.
        // If session was not obtained - redirect to login page

        tryGetSessionFromQueryParams().then(session => {
          if (session) {
            finishSessionLoading(session);
            return;
          }

          tryGetSessionFromLocalStorage().then(session => {
            if (session) {
              finishSessionLoading(session);
            } else {
              // pass current location so after login it's will redirect back
              redirectToAuthPortal(window.location.href);
            }
          });
        });
      }
    } else {
      // PC client case

      // PC client looks for session in local storage,
      // if there's nothing or session is invalid then makes 'GET session' call

      tryGetSessionFromLocalStorage().then(session => {
        if (session) {
          finishSessionLoading(session);
          return;
        }

        tryGetSession().then(session => {
          finishSessionLoading(session);
        });
      });
    }

    async function tryGetSessionFromLocalStorage() {
      let session = getItem<ISession>(AUTH_KEY);
      if (!session) return null;

      if (autoAuthenticated || !isAuthPortal) {
        // No additional checks required by web-client and pc-client.
        // Expired session will be renewed by axios interceptors
        return session;
      }

      if (!isSessionExpired(session)) {
        // Auth client needs to make sure that session is valid
        const response = await verifySession(session?.Authorization).catch(() => {
          return null;
        });

        if (response) {
          return session;
        }
      }

      setItem(AUTH_KEY, null);
      return null;
    }

    function finishSessionLoading(session: ISession | null) {
      updateSession(session);
      setLoading(false);
      if (!subscribed) {
        // Subscribe to storage update event
        window.addEventListener('storage', onStorageUpdated);
        subscribed = true;
      }
    }

    return () => {
      // Unsubscribe from storage update event
      if (subscribed) window.removeEventListener('storage', onStorageUpdated);
    };
  }, []);

  function updateSession(newSession: ISession | null) {
    // First store session in local storage
    if (!autoAuthenticated) setItem(AUTH_KEY, newSession);

    if (!isAuthPortal && newSession) {
      // Now check that user has fetched correct version
      const appUrl = autoAuthenticated ? newSession.AppBindingUrl : newSession.WebClientUri;
      if (!verifyApplicationVersion(appUrl)) {
        // We're on the incorrect url now and getting redirected to the proper one
        // No further handling is needed
        return;
      }
    }

    // Now we can set session
    setSession(newSession);
  }

  function updateSessionInfo(newSessionInfo?: ISessionInfo) {
    if (!newSessionInfo || !session) return;
    updateSession({ ...session, SessionInfo: { ...newSessionInfo } });
  }

  function onStorageUpdated(e: StorageEvent) {
    if (e.key === getFullKey(AUTH_KEY)) {
      const session = getItem<ISession>(AUTH_KEY);
      setSession(session);

      if (!session && !autoAuthenticated) {
        // needs to omit cypress
        if (Object.prototype.hasOwnProperty.call(window, 'Cypress')) {
          return;
        }
        redirectToAuthPortal();
      }
    }
  }

  function logout(onLogout?: () => void) {
    setLoading(true);
    return apiLogout().finally(() => {
      setSession(null);
      setItem(AUTH_KEY, null);
      setItem(CURRENT_ORG_UNIT_KEY, null);
      onLogout?.();
    });
  }

  useLayoutEffect(() => {
    if (!session) {
      return;
    }

    let renewPromise: Promise<string> | undefined = undefined;

    function getValidTicket(config: AxiosRequestConfig, forced?: boolean) {
      if (!session) return Promise.reject();

      if (window.document.hidden) {
        // There's no guarantee that we have valid session data and there's no guarantee
        // that we'll be able to finish session renew in case if such request starts.
        // So just go with the ticket that we have, it will be renewed once tab gets activated
        return Promise.resolve(session.Authorization);
      }

      if (config.url?.endsWith(SESSION_RENEW_URL) || isAuthPortal || autoAuthenticated) {
        return Promise.resolve(session.Authorization);
      }

      if (renewPromise !== undefined) {
        // the promise that renews session already exists,
        // all further requests should get valid ticket after renewing
        // so just chain those requests to the existing promise
        return renewPromise;
      }

      if (!forced) {
        // In general we need to avoid renewing every time, so unless renew is forced
        // we need to check whether session is expired or not
        if (!isSessionExpired(session)) {
          return Promise.resolve(session.Authorization);
        }
      }

      // ticket is expired, call for renewing
      renewPromise = tryRenewSession(session)
        .then(renewedSession => {
          if (!renewedSession) return Promise.reject();
          updateSession(renewedSession);
          renewPromise = undefined; // important to set promise to "undefined" so no other call will chain
          return Promise.resolve(renewedSession.Authorization);
        })
        .catch(error => Promise.reject(error));

      return renewPromise;
    }

    async function assignAuthHeader(url: string, config: AxiosRequestConfig) {
      return getValidTicket(config).then(ticket => {
        config.baseURL = url;
        Object.assign(config.headers, { Authorization: ticket });

        return Promise.resolve(config);
      });
    }

    async function removeSession(error: any, instance: AxiosInstance) {
      console.error(error);

      if (window.document.hidden) {
        // No special handling is needed while window is hidden
        return Promise.reject(error);
      }

      const isUnauthorized = error?.response?.status === 401;

      if (!isUnauthorized || !session || autoAuthenticated) return Promise.reject(error);

      if (isAuthPortal) {
        setSession(null);
        return;
      }

      // We got 401 in web-client, before logging out we can try to renew ticket
      if (error.config.url.endsWith(SESSION_RENEW_URL) || error.config.isRepeatedCall) {
        // we got 401 during renew request or repeated call, nothing can be done more, logout
        logout(redirectToAuthPortal);
      } else {
        // force renew here and if it succeeds then repeat original request
        // however if it fails then we have no other option except logging out
        return getValidTicket(error.config, true)
          .catch(e => {
            logout(redirectToAuthPortal);
          })
          .then(() => {
            return instance.request({ isRepeatedCall: true, ...error.config });
          });
      }
      return;
    }

    // on each request -> attach headers
    const axiosAuthInterceptorRequestRef = authHTTPClient.interceptors.request.use(
      config => assignAuthHeader(session.ApiBaseUri, config),
      error => Promise.reject(error)
    );

    const axiosCustomerInterceptorRequestRef = customerHTTPClient.interceptors.request.use(
      config => {
        Object.assign(config.headers, {
          'TT-ResourceUri': session.DatabaseInstanceUrl.replace('net.tcp', 'customerdb')
        });
        return assignAuthHeader(session.ApiCustomerUri, config);
      },
      error => Promise.reject(error)
    );

    // on each 401 -> remove session
    const axiosAuthInterceptorResponseRef = authHTTPClient.interceptors.response.use(
      r => r,
      error => removeSession(error, authHTTPClient)
    );

    const axiosCustomerInterceptorResponse = customerHTTPClient.interceptors.response.use(
      r => r,
      error => removeSession(error, customerHTTPClient)
    );

    return () => {
      authHTTPClient.interceptors.request.eject(axiosAuthInterceptorRequestRef);
      customerHTTPClient.interceptors.request.eject(axiosCustomerInterceptorRequestRef);

      authHTTPClient.interceptors.response.eject(axiosAuthInterceptorResponseRef);
      customerHTTPClient.interceptors.response.eject(axiosCustomerInterceptorResponse);
    };
  }, [session]);

  // only web-client should have session life-time management enabled
  const enableLifeTimeManagement = !isAuthPortal && !autoAuthenticated && session;

  const children = (
    // TODO: shouldn't this be a part of login procedure?
    //       we probably should not enter web client before user changed password
    <>
      {props.children}
      {!isAuthPortal && !autoAuthenticated && session?.PasswordExpired ? (
        <Suspense fallback={<Spinner isOverlapped={true} />}>
          <ChangePasswordModal
            open={true}
            onClose={passwordChanged => {
              if (!passwordChanged) logout(redirectToAuthPortal);
            }}
          />
        </Suspense>
      ) : null}
    </>
  );

  // render the application
  return (
    <SessionContext.Provider
      value={{
        session,
        token: session?.Authorization ?? '',
        sessionInfo: session?.SessionInfo,
        isLoading: loading,
        orgUnits: session?.OrgUnits ?? [],
        updateSession,
        updateSessionInfo,
        logout
      }}
    >
      {enableLifeTimeManagement ? (
        <SessionLifeTimeManager>{children}</SessionLifeTimeManager>
      ) : (
        children
      )}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const data = useContext(SessionContext);

  if (!data) {
    throw new Error(
      'useSession should be used with SessionProvider only and after StorageProvider'
    );
  }

  return data;
}
