import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch
} from 'react';
import { getCurrentUserSettings, UserLanguage } from '../api/auth/users';
import { useStorage } from './useStorage';
import { Feature, useFeatureFlagsContext } from './feature-flags-context';
import i18n from './i18n/i18n';
import moment from 'moment';
import { format } from 'date-fns';
import { useSession } from './useSession';
import { REGIONAL_SETTINGS_KEY } from './constants/storage-keys';
import UserSettingsContainer from './userSettings/userSettingsContainer';
import { TTDateFormat, getISODateFormat } from '@utils/date-utils';

interface ILocalDayName {
  day: string;
  ind: number;
}
export interface IUserSettingsContext {
  currentLanguage: string | null;
  currentDateFormat: string | null;
  currentFullDateFormat: string;
  currentISODateFormat: string;
  dateSeparator: string;
  shortDateString(date: Date): string;
  fullDateString(date: Date): string;
  getLocalDayNames(): ILocalDayName[];

  userSettings: UserSettingsContainer;
  setUserSettings: Dispatch<SetStateAction<UserSettingsContainer>>;
}

interface IRegionalSettings {
  language?: string;
  dateFormat?: TTDateFormat | string;
  dateSeparator?: string;
  fullDateFormat?: string;
}

enum fullUserDateFormat {
  Day = 'DD MMMM YYYY',
  Month = 'MMMM DD YYYY',
  Year = 'YYYY MMMM DD'
}

const UserSettingsContext = React.createContext<IUserSettingsContext>(null as any);

const defaultSettings = {
  dateFormat: TTDateFormat.YearMonthDayFull,
  language: process.env.NODE_ENV !== 'production' ? UserLanguage.EN : UserLanguage.SV,
  dateSeparator: '-',
  fullDateFormat: fullUserDateFormat.Day
};

function getRevertedDateFormat(format: string) {
  const dateKey = format.charAt(0);

  switch (dateKey) {
    case 'D':
      return fullUserDateFormat.Day;
    case 'M':
      return fullUserDateFormat.Month;
    case 'Y':
      return fullUserDateFormat.Year;
    default:
      return fullUserDateFormat.Year;
  }
}

function normalizeDateFormats(settings: IRegionalSettings | null): IRegionalSettings | null {
  if (!settings) return settings;

  if (settings.dateFormat) {
    settings.fullDateFormat = getRevertedDateFormat(settings.dateFormat);
    settings.dateFormat = getISODateFormat(
      settings.dateFormat,
      settings.dateSeparator ?? defaultSettings.dateSeparator
    );
  } else {
    // use default format and separator
    settings.dateFormat = getISODateFormat(
      defaultSettings.dateFormat,
      defaultSettings.dateSeparator
    );
  }

  return settings;
}

export function UserSettingsContextProvider(props: { children: JSX.Element }) {
  const { getItem, setItem, getFullKey } = useStorage();
  const { token } = useSession();
  const [userSettings, setUserSettings] = useState(new UserSettingsContainer());

  // Always initialize settings with value from the local storage
  const [regionalSettings, setRegionalSettings] = useState<IRegionalSettings | null>(
    normalizeDateFormats(getItem<IRegionalSettings>(REGIONAL_SETTINGS_KEY))
  );

  const { hasFeature } = useFeatureFlagsContext();
  const autoAuthenticated = hasFeature(Feature.AutoAuthenticated);

  useEffect(() => {
    // Apply language here
    moment.locale(regionalSettings?.language ?? defaultSettings.language);
    i18n.changeLanguage(regionalSettings?.language ?? defaultSettings.language);
  }, [regionalSettings?.language]);

  useEffect(() => {
    if (autoAuthenticated && regionalSettings) {
      // if pc client provided information about regional settings then we can simply stick to it
      return;
    }

    if (!token) return;

    // Fetch user's actual regional settings
    let ignore = false;

    async function fetchSettings() {
      try {
        const res = await getCurrentUserSettings();
        if (ignore) {
          return;
        }

        const settings: IRegionalSettings = {};

        // LANGUAGE SETTING
        if (res && res.Filename) {
          // get language from the lang file or use default language (SV)
          let langFileName = res.Filename.toLowerCase().match(/.{1,2}/g);
          settings.language = langFileName ? langFileName[0] : UserLanguage.SV;
        } else {
          // use default language if user doesn't have any lang file
          settings.language = defaultSettings.language;
        }

        settings.dateSeparator = res.DateSeparator ?? defaultSettings.dateSeparator;
        settings.dateFormat = res.DateFormat ?? defaultSettings.dateFormat;
        normalizeDateFormats(settings);

        if (
          regionalSettings?.language !== settings.language ||
          regionalSettings?.dateFormat !== settings.dateFormat ||
          regionalSettings?.fullDateFormat !== settings.fullDateFormat ||
          regionalSettings?.dateSeparator !== settings.dateSeparator
        ) {
          // Make update only of anything differs from teh current settings
          setRegionalSettings(settings);
          setItem(REGIONAL_SETTINGS_KEY, settings);
        }
      } catch (e) {
        console.error(e);
      }
    }

    fetchSettings();

    return () => {
      ignore = true;
    };
  }, [token]);

  useEffect(() => {
    const onStorageUpdated = (e: StorageEvent) => {
      if (e.key === getFullKey(REGIONAL_SETTINGS_KEY)) {
        const settings = getItem<IRegionalSettings>(REGIONAL_SETTINGS_KEY);
        normalizeDateFormats(settings);
        setRegionalSettings(settings);
      }
    };

    window.addEventListener('storage', onStorageUpdated);
    return () => {
      window.removeEventListener('storage', onStorageUpdated);
    };
  }, []);

  function getLocalDayNames() {
    return moment.weekdays(true).map((d, i) => {
      return {
        day: d.charAt(0).toUpperCase() + d.slice(1),
        ind: i
      };
    });
  }

  const shortDateString = useCallback(
    (date: Date) => {
      const dateFormat = regionalSettings?.dateFormat ?? defaultSettings.dateFormat;
      return format(date, dateFormat);
    },
    [regionalSettings?.dateFormat]
  );

  const fullDateString = useCallback(
    (date: Date) => {
      const dateFormat = regionalSettings?.fullDateFormat ?? defaultSettings.fullDateFormat;
      return format(date, dateFormat);
    },
    [regionalSettings?.fullDateFormat]
  );

  return (
    <UserSettingsContext.Provider
      value={{
        currentLanguage: regionalSettings?.language ?? defaultSettings.language,
        currentDateFormat: regionalSettings?.dateFormat ?? defaultSettings.dateFormat,
        currentISODateFormat:
          regionalSettings?.dateFormat ??
          getISODateFormat(defaultSettings.dateFormat, defaultSettings.dateSeparator),
        currentFullDateFormat: regionalSettings?.fullDateFormat ?? defaultSettings.fullDateFormat,
        dateSeparator: regionalSettings?.dateSeparator ?? defaultSettings.dateSeparator,
        shortDateString,
        fullDateString,
        getLocalDayNames,

        userSettings,
        setUserSettings
      }}
    >
      {props.children}
    </UserSettingsContext.Provider>
  );
}

export function useUserSettingsContext(args?: { safe: boolean }) {
  const data = useContext(UserSettingsContext);

  if (!data && !args?.safe) {
    throw new Error(`You should use useUserSettingsContext only with UserSettingsContextProvider`);
  }

  return data;
}
