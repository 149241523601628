// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".label-module__root--MBMTr_pimCc5u5ihO545{font:inherit}.label-module__withChildren--S0OB_Dh28ElEVqNKYETZ{display:block;margin-bottom:5px}", "",{"version":3,"sources":["webpack://./packages/ui-components/src/label/label.module.css"],"names":[],"mappings":"AAAA,0CACE,YACF,CAEA,kDACE,aAAc,CACd,iBACF","sourcesContent":[".root {\r\n  font: inherit;\r\n}\r\n\r\n.withChildren {\r\n  display: block;\r\n  margin-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "label-module__root--MBMTr_pimCc5u5ihO545",
	"withChildren": "label-module__withChildren--S0OB_Dh28ElEVqNKYETZ"
};
export default ___CSS_LOADER_EXPORT___;
