import React, { useEffect, useRef, useState } from 'react';
import { Input } from '@ui/input';
import { Button, ButtonType } from '@ui/button';
import { Link, LinkType } from '@ui/link';
import { AxiosError } from 'axios';
import { FormError } from '@ui/form-error';
import { useTranslation } from 'react-i18next';
import { Id as ToastId } from 'react-toastify';
import {
  login,
  IMaintenance,
  isIntermediateSession,
  isMaintenance,
  isSession,
  InvalidLoginError
} from '@api/auth/session';
import { hideToast, showUnexpectedError } from '@lib/toast';
import { useSession } from '@lib/useSession';
import { useLoginContext } from '../routes/login/login.context';
import { useForm } from 'react-hook-form';
import { MaintenanceMessage } from '@ui/planned-maintenance/maintenance-message';
import { checkFuncRights } from '@utils/common';
import { LoginTabs } from '../routes/login/login';
import styles from '../routes/login/login.css';

export interface ILoginUserPassForm {
  onForgotClick(): void;
  requestConfirm(): void;
}

interface ILoginUserPassData {
  customerId: string;
  username: string;
  password: string;
  error: string;
}

export function LoginUserPassForm(props: ILoginUserPassForm) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setError,
    clearErrors
  } = useForm<ILoginUserPassData>();
  const { processing, setProcessing, setIntermediateSession } = useLoginContext();
  const { updateSession } = useSession();
  const [maintenanceInfo, setMaintenanceInfo] = useState<IMaintenance | null>(null);
  const currentToast = useRef<ToastId>('');

  useEffect(() => {
    if (!processing) setFocus('customerId');
  }, [processing]);

  async function onSubmit(formData: ILoginUserPassData) {
    hideToast(currentToast.current);
    setMaintenanceInfo(null);
    if (processing) {
      return;
    }

    setProcessing(true);
    const customerId = formData.customerId;
    const username = formData.username;
    const password = formData.password;

    try {
      const data = await login({
        CustomerId: customerId,
        LoginName: username,
        Password: password
      });

      if (!data) {
        return;
      }

      const res = data.data;

      if (isSession(res)) {
        const platformsToShow = checkFuncRights(res ? res.FuncRights : []);

        if (!platformsToShow.showWebClient || !res?.OrgUnits.length) {
          setError('error', { type: 'validate', message: t('login:errors:noAccessToWebclient') });
          return;
        }
        updateSession(res);
        return;
      }
      if (isIntermediateSession(res)) {
        setIntermediateSession({ ...res, username, customerId });
        props.requestConfirm();
      }
    } catch (e) {
      const error = e as AxiosError;

      if (error?.response && isMaintenance(error?.response.data)) {
        setMaintenanceInfo(error?.response.data);
        return;
      }

      if (e instanceof InvalidLoginError) {
        setError('error', { type: 'validate', message: t('login:errors:incorrectCredentials') });
        return;
      }

      currentToast.current = showUnexpectedError(e);
    } finally {
      setProcessing(false);
    }
  }

  return (
    <>
      <div>
        <h4>{processing ? t('common:processing') : t('login:signIn')}</h4>
        <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
          <Input
            disabled={processing}
            cy-id="customerId"
            autoComplete="customer-id"
            placeholder={t('login:customerId')}
            autoFocus={true}
            {...register('customerId', {
              onChange: () => {
                setMaintenanceInfo(null);
              },
              disabled: processing
            })}
          />
          <Input
            required
            disabled={processing}
            cy-id="username"
            autoComplete="username"
            placeholder={t('login:username')}
            {...register('username', { required: true, disabled: processing })}
          />
          <Input
            type="password"
            required
            disabled={processing}
            cy-id="password"
            autoComplete="password"
            placeholder={t('login:password')}
            {...register('password', { required: true, disabled: processing })}
          />
          <Button
            disabled={processing}
            value={t('login:logIn')}
            cy-id="submitForm"
            type={ButtonType.submit}
            style={{ marginTop: '15px' }}
            onClick={() => clearErrors('error')}
          />
        </form>
        {errors.error && errors.error.message && <FormError text={errors.error.message} />}
        <MaintenanceMessage maintenance={maintenanceInfo} className={styles.loginMaintenance} />
      </div>
      <div className={styles.topMargin}>
        <Link
          cyId="forgot-password"
          disabled={processing}
          text={t('login:forgotPassword')}
          type={LinkType.link}
          onClick={props.onForgotClick}
        />
      </div>
    </>
  );
}
