import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from '../modal/modal';
import { useTranslation } from 'react-i18next';
import { Button } from '@ui/button';
import { Label } from '@ui/label';
import styles from './alert-confirmation.module.css';

export enum AlertButton {
  None = 0,
  OK,
  Cancel,
  Abort,
  Retry,
  Ignore,
  Yes,
  No
}

export enum AlertOptions {
  OK = 0,
  OKCancel,
  AbortRetryIgnore,
  YesNoCancel,
  YesNo,
  RetryCancel
}

export interface IButtonInfo {
  id: number;
  text: string;
}

export interface IAlertInfoPropsBase {
  caption: string;
  text: string | JSX.Element;
  buttons?: AlertOptions | IButtonInfo[];
  defaultButtonId?: number;
}

export interface IAlertInfoProps extends IAlertInfoPropsBase {
  onClose?(buttonId: number): void;
}

interface IAlertInfoPropsEx extends IAlertInfoPropsBase {
  resolve(button?: number): void;
  id?: string | number;
}

export function AlertConfirmation(props: IAlertInfoProps) {
  const { caption, text, buttons, defaultButtonId, onClose } = props;
  const { t } = useTranslation();
  const defaultButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    defaultButtonRef.current?.focus();
  }, [defaultButtonRef.current]);

  function getButtonInfo(alertButton: AlertButton): IButtonInfo {
    let text = '';
    switch (alertButton) {
      case AlertButton.OK:
        text = t('alert:btnOk');
        break;
      case AlertButton.Cancel:
        text = t('alert:btnCancel');
        break;
      case AlertButton.Abort:
        text = t('alert:btnAbort');
        break;
      case AlertButton.Retry:
        text = t('alert:btnRetry');
        break;
      case AlertButton.Ignore:
        text = t('alert:btnIgnore');
        break;
      case AlertButton.Yes:
        text = t('alert:btnYes');
        break;
      case AlertButton.No:
        text = t('alert:btnNo');
        break;
    }

    return { id: alertButton, text };
  }

  function mapButtons() {
    const buttonsInfo: IButtonInfo[] = [];

    let focusedButtonId = defaultButtonId;
    if (buttons instanceof Array) {
      buttonsInfo.concat(buttonsInfo);
    } else {
      let alertButtons: AlertButton[] = [];

      switch (buttons) {
        case AlertOptions.OK:
          alertButtons.push(AlertButton.OK);
          focusedButtonId = focusedButtonId ?? AlertButton.OK;
          break;
        case AlertOptions.OKCancel:
          alertButtons.push(AlertButton.OK, AlertButton.Cancel);
          focusedButtonId = focusedButtonId ?? AlertButton.Cancel;
          break;
        case AlertOptions.AbortRetryIgnore:
          alertButtons.push(AlertButton.Abort, AlertButton.Retry, AlertButton.Ignore);
          focusedButtonId = focusedButtonId ?? AlertButton.Retry;
          break;
        case AlertOptions.YesNoCancel:
          alertButtons.push(AlertButton.Yes, AlertButton.No, AlertButton.Cancel);
          focusedButtonId = focusedButtonId ?? AlertButton.Cancel;
          break;
        case AlertOptions.YesNo:
          alertButtons.push(AlertButton.Yes, AlertButton.No);
          focusedButtonId = focusedButtonId ?? AlertButton.No;
          break;
        case AlertOptions.RetryCancel:
          alertButtons.push(AlertButton.Retry, AlertButton.Cancel);
          focusedButtonId = focusedButtonId ?? AlertButton.Retry;
          break;
        default:
          break;
      }

      buttonsInfo.push(...alertButtons.map(getButtonInfo));
    }

    if (!buttonsInfo.length) return null;

    return buttonsInfo.map(buttonInfo => {
      return (
        <Button
          value={buttonInfo.text}
          className={styles.button}
          key={buttonInfo.id}
          onClick={() => {
            onClose?.(buttonInfo.id);
          }}
          ref={buttonInfo.id === focusedButtonId ? defaultButtonRef : null}
          cyId={`alert-dialog-button-${AlertButton[buttonInfo.id]}`}
        />
      );
    });
  }

  return (
    <Modal
      show
      className={{ modal: styles.dialog }}
      cyId="alert-dialog"
      hidesOnBackdropClick={false}
      title={caption}
      body={
        <>
          <div className={styles.description}>
            {typeof text === 'string' ? <Label value={text} /> : text}
          </div>
          <div className={styles.alertButtons}>{mapButtons()}</div>
        </>
      }
      onHide={() => {
        onClose?.(AlertButton.None);
      }}
    />
  );
}

export function useConfirmation() {
  const [dialogProps, setDialogProps] = useState<IAlertInfoPropsEx[]>([]);

  const showDialog = useCallback((props: IAlertInfoPropsBase, id?: string | number) => {
    return new Promise<number | undefined>(resolve => {
      setDialogProps(current => [...current, { ...props, resolve, id }]);
    });
  }, []);

  const closeDialog = useCallback(
    (id: string | number) => {
      const filtered = dialogProps.filter(p => {
        if (p.id === id) {
          p.resolve(AlertButton.None);
          return false;
        }
        return true;
      });

      setDialogProps(filtered);
    },
    [dialogProps]
  );

  const dialog = useMemo(() => {
    const handleClose = (button: number, props: IAlertInfoPropsEx) => {
      setDialogProps(current => current.filter(p => p !== props));
      props.resolve(button);
    };

    return (
      <>
        {dialogProps.map((props, i) => (
          <AlertConfirmation key={i} onClose={button => handleClose(button, props)} {...props} />
        ))}
      </>
    );
  }, [dialogProps]);

  return { dialog, showDialog, closeDialog };
}
