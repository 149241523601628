import { createSearchParams } from 'react-router-dom';

export function redirectToWebClient(props: {
  path?: string;
  host?: string;
  version?: string;
  token?: string;
  redirectDestination?: string;
}) {
  const version = props.version ?? `${process.env.APP_VERSION}.${process.env.REVISION_NUMBER}`;
  const defaultUrl = `${process.env.WEBCLIENT_HOST}/${version}`;

  let baseUrl = process.env.NODE_ENV === 'production' && props.host ? props.host : defaultUrl;
  if (!baseUrl.endsWith('/')) baseUrl += '/';

  let path = props.path ?? '';
  let query = props.token ? `?${createSearchParams({ token: props.token })}` : '';

  let pageUrl = `${baseUrl}${path}${query}`;

  if (props.redirectDestination) {
    try {
      const baseWebClientUrl = new URL(baseUrl);
      const baseWebClientPath = baseWebClientUrl.pathname.split('/')[1];

      const destinationUrl = new URL(props.redirectDestination);
      const destinationPath = destinationUrl.pathname.split('/')[1];
      if (
        destinationUrl.hostname === baseWebClientUrl.hostname &&
        baseWebClientPath === destinationPath
      ) {
        destinationUrl.searchParams.append('token', props.token ?? '');
        pageUrl = destinationUrl.toString();
      }
    } catch {}
  }

  window.location.assign(pageUrl);
}

export function redirectToAuthPortal(destination?: string) {
  const url = `${process.env.AUTH_PORTAL_HOST}`;
  const query = destination
    ? `?${createSearchParams({ dest: encodeURIComponent(destination) })}`
    : '';

  window.location.assign(`${url}${query}`);
}

export function redirectToAdminPortal(query: string = '') {
  const url = `${process.env.ADMIN_HOST}`;

  window.location.assign(`${url}${query}`);
}
