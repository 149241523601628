import React, { useEffect, useRef, useState } from 'react';
import { Input } from '@ui/input';
import { Button, ButtonType } from '@ui/button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Id as ToastId } from 'react-toastify';
import { showUnexpectedError } from '@lib/toast';
import { Spinner } from '@ui/spinner';
import { FormError } from '@ui/form-error';
import {
  reset,
  InvalidPasswordError,
  InvalidResetADPasswordError,
  InvalidCustomerError,
  InvalidUserError
} from '@api/auth/account';
import styles from './forgot-form.css';

interface IForgotFormProps {
  onBackClick(): void;
}

interface IForgotPassData {
  customerId: string;
  username: string;
  error: string;
}

export function ForgotForm(props: IForgotFormProps) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setError,
    clearErrors
  } = useForm<IForgotPassData>();
  const currentToast = useRef<ToastId>('');
  const [positiveStatus, setPositiveStatus] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setFocus('customerId');
  }, []);

  async function onSubmit(formData: IForgotPassData) {
    try {
      setProcessing(true);
      await reset({ CustomerId: formData.customerId, LoginName: formData.username });
      setPositiveStatus(true);
    } catch (e) {
      if (e instanceof InvalidCustomerError) {
        setError('error', { type: 'validate', message: t('login:errors:invalidCustomer') });
        return;
      }

      if (e instanceof InvalidUserError) {
        setError('error', { type: 'validate', message: t('login:errors:invalidUser') });
        return;
      }

      if (e instanceof InvalidPasswordError) {
        setError('error', { type: 'validate', message: t('login:errors:unableToResetPassword') });
        return;
      }

      if (e instanceof InvalidResetADPasswordError) {
        setError('error', { type: 'validate', message: t('login:errors:unableToResetADPassword') });
        return;
      }
      currentToast.current = showUnexpectedError(e);
    } finally {
      setProcessing(false);

      setFocus('customerId');
    }
  }

  return (
    <div>
      {processing && <Spinner isUnauthenticated={true} />}
      {positiveStatus ? (
        <div cy-id="forgot-success">
          <h3 style={{ marginTop: 0 }}>{t('login:passwordRecovery:newPassSent')}</h3>
          <Button onClick={props.onBackClick} value={t('common:back')} type={ButtonType.submit} />
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          <h4>{t('login:passwordRecovery:forgotPassword')}</h4>
          <span className={styles.blockText}>{t('login:passwordRecovery:enterDetails')}</span>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              cy-id="customerId"
              required
              minLength={1}
              placeholder={t('login:customerId')}
              autoFocus={true}
              {...register('customerId', { required: true, minLength: 1 })}
            />
            <Input
              cy-id="username"
              required
              minLength={1}
              placeholder={t('login:username')}
              autoFocus={false}
              {...register('username', { required: true, minLength: 1 })}
            />
            {errors.error && errors.error.message && <FormError text={errors.error.message} />}
            <div className={styles.buttons}>
              <Button onClick={props.onBackClick} value={t('common:back')} />
              <Button cy-id="submitForm" value={t('common:send')} type={ButtonType.submit} />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
