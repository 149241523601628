export const APP_NAME = 'tiltid';
export const CURRENT_ORG_UNIT_KEY = 'cur-org-unit-id';
export const PLANNING_FILTER_KEY = 'planning-filter';
export const BRAND_COLORS_KEY = 'colors';
export const THEMES_KEY = 'themes';
export const CURRENT_THEME_KEY = 'currentTheme';
export const CURRENT_THEME_COLORS_KEY = 'currentThemeColors';
export const REGIONAL_SETTINGS_KEY = 'regional-settings';
export const AUTH_KEY = 'admin-auth-data';
export const PLAN_CLIPBOARD_BUFFER = 'plan-clipboard-buffer';
