import React from 'react';
import styles from './spinner.module.css';
import cx from 'classnames';

interface ISpinnerProps {
  color?: string;
  className?: string;
  isOverlapped?: boolean;
  isUnauthenticated?: boolean;
  fullBg?: boolean;
}

export function Spinner(props: ISpinnerProps) {
  if (props.isOverlapped) {
    return (
      <>
        <div className={styles.overlappedBg}>
          <div />
        </div>
        <div className={cx([styles.root, styles.overlapped, styles.noBg, props?.className])}>
          <div className={styles.loader} />
        </div>
      </>
    );
  }
  return (
    <div
      className={cx(
        [
          styles.root,
          {
            [styles.overlapped]: props?.isUnauthenticated,
            [styles.noFilter]: !props?.fullBg
          }
        ],
        props?.className
      )}
    >
      <div className={styles.loader} />
    </div>
  );
}
