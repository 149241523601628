import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import React from 'react';
import { ToastContainer, toast, Id as ToastId, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function DefaultToastContainer() {
  return <ToastContainer hideProgressBar transition={Flip} theme={'colored'} />;
}

function isAxiosError(error: AxiosError | Error): error is AxiosError {
  return 'response' in error;
}

interface IToastProps {
  text: string;
  error?: Error;
  closeToast?: () => void;
}

function Toast(props: IToastProps) {
  return (
    <span
      onClick={event => {
        event.preventDefault();
        if (props.error) {
          Sentry.withScope(scope => {
            scope.setExtras({ text: props.text });
            const eventId = Sentry.captureException(props.error);
            Sentry.showReportDialog({ eventId });
          });
        }
        if (props.closeToast) {
          props.closeToast();
        }
      }}
    >
      {props.text}
      <br />
      {props.error ? (
        <span style={{ textDecoration: 'underline' }}>
          (click here if you think this is an error)
        </span>
      ) : null}
    </span>
  );
}

export function showUnexpectedError(error: AxiosError | Error | any): ToastId {
  let msg: string = '';

  if (isAxiosError(error)) {
    // It might be better to not show Axios errors while window is in "hidden" state
    // since it's more or less expected to have errors due to suspended timers or no internet connection
    if (window.document.hidden) return '';

    if (error.response && error.response.data) {
      if (error.response.data.Message) {
        msg = error.response.data.Message;
      }
    }
  }

  return toast(<Toast text={msg || error.message || error.name} error={error} />, {
    position: toast.POSITION.BOTTOM_RIGHT,
    type: toast.TYPE.ERROR,
    closeOnClick: false
  });
}

export function hideToast(toasId: ToastId) {
  toast.dismiss(toasId);
}

export function showWarning(msg: string): ToastId {
  return toast(<Toast text={msg} />, {
    position: toast.POSITION.BOTTOM_RIGHT,
    type: toast.TYPE.WARNING,
    closeOnClick: false
  });
}

export function showSuccess(msg: string): ToastId {
  return toast(<Toast text={msg} />, {
    position: toast.POSITION.BOTTOM_RIGHT,
    type: toast.TYPE.SUCCESS,
    closeOnClick: false
  });
}
