import { IUserSetting, SettingType, SettingTypeMap } from './types';

export default class UserSettingsContainer {
  settings: { [key in SettingType]: Map<string, IUserSetting<key>> };

  constructor(other?: UserSettingsContainer) {
    this.settings = {
      bool: other?.settings.bool ?? new Map<string, IUserSetting<'bool'>>(),
      int: other?.settings.int ?? new Map<string, IUserSetting<'int'>>(),
      color: other?.settings.color ?? new Map<string, IUserSetting<'color'>>(),
      guid: other?.settings.guid ?? new Map<string, IUserSetting<'guid'>>(),
      string: other?.settings.string ?? new Map<string, IUserSetting<'string'>>()
    };
  }

  hasSetting<T extends SettingType>(type: T, key: string): boolean {
    return this.settings[type].has(key);
  }

  getSetting<T extends SettingType>(
    type: T,
    key: string,
    defaultValue?: SettingTypeMap[T]
  ): IUserSetting<T> | undefined {
    let setting = this.settings[type].get(key);
    if (setting === undefined && defaultValue !== undefined) {
      setting = { key, defaultValue };
    }

    return setting;
  }

  setSetting<T extends SettingType>(
    type: T,
    key: string,
    defaultValue: SettingTypeMap[T],
    userValue?: SettingTypeMap[T]
  ): void {
    const setting = this.settings[type].get(key);
    if (setting?.userValue !== userValue) {
      this.settings[type].set(key, { key, defaultValue, userValue });
    }
  }
}
