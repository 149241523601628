import * as Sentry from '@sentry/browser';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { register } from 'register-service-worker';
import i18n from '@lib/i18n/i18n';
import { SentryErrorBoundary } from '@lib/sentry-error-boundary';
import { PlatformDataProvider } from '@lib/usePlatform';
import { SessionProvider } from '@lib/useSession';
import { StorageProvider } from '@lib/useStorage';
import { App } from './app';
import { ThemeContextProvider } from '@lib/theme/useTheme';
import { CurrentUserContextProvider } from '@lib/current-user-context';
import { FeatureFlagsContextProvider } from '@lib/feature-flags-context';
import { SERVER_ERRORS_REGEXP } from '@utils/common';
import { UserSettingsContextProvider } from '@lib/user-settings.context';
import { DefaultToastContainer } from '@lib/toast';
import '@utils/styles';

if (process.env.NODE_ENV === 'production') {
  // service worker
  register(`${process.env.BASE_URL}service-worker.js`);
  // sentry
  Sentry.init({
    dsn: 'https://09f051bb85414044bbec5ef4f4857869@sentry.io/1425934',
    ignoreErrors: [SERVER_ERRORS_REGEXP]
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // TODO: wrap in <React.StrictMode>
  <SentryErrorBoundary>
    <Suspense fallback={null}>
      <>
        <StorageProvider>
          <FeatureFlagsContextProvider>
            <I18nextProvider i18n={i18n}>
              <SessionProvider isAuthPortal={true}>
                <UserSettingsContextProvider>
                  <PlatformDataProvider>
                    <ThemeContextProvider>
                      <CurrentUserContextProvider>
                        <App />
                      </CurrentUserContextProvider>
                    </ThemeContextProvider>
                  </PlatformDataProvider>
                </UserSettingsContextProvider>
              </SessionProvider>
            </I18nextProvider>
          </FeatureFlagsContextProvider>
        </StorageProvider>

        <DefaultToastContainer />
      </>
    </Suspense>
  </SentryErrorBoundary>
);
