export const baseColors = [
  {
    Name: 'Dark',
    Colors: [
      {
        Name: 'body_bkgnd',
        Value: '28, 26, 26'
      },
      {
        Name: 'body_bkgnd2',
        Value: '39, 40, 40'
      },
      {
        Name: 'panel_bkgnd',
        Value: '42, 42, 42'
      },
      {
        Name: 'panel_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'active-row_txt',
        Value: '84, 157, 204'
      },
      {
        Name: 'stepper_bkgnd',
        Value: '42, 42, 42'
      },
      {
        Name: 'stepper_txt',
        Value: '42, 42, 42'
      },
      {
        Name: 'stepper_passed_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'stepper-circle_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'panel_border',
        Value: '110, 110, 118'
      },
      {
        Name: 'panel_modal_border',
        Value: '110, 110, 118'
      },
      {
        Name: 'overlay_bkgnd',
        Value: '93, 92, 92'
      },
      {
        Name: 'dropdown_bkgnd',
        Value: '68, 68, 72'
      },
      {
        Name: 'date_border',
        Value: '241, 241, 241'
      },
      {
        Name: 'progress_bkgnd',
        Value: '49, 179, 95'
      },
      {
        Name: 'table_absent_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'tablerow_odd_bkgnd',
        Value: '42, 42, 42'
      },
      {
        Name: 'tablerow_active_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'calendar_bkgnd',
        Value: '42, 42, 42'
      },
      {
        Name: 'calendar_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'weather_bkgnd',
        Value: '42, 42, 42'
      },
      {
        Name: 'weather_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'weather_current_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'dimensiontree_line_bkgnd',
        Value: '255, 255, 255'
      },
      {
        Name: 'dimensiontree_label_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'employee_details_txt',
        Value: '51, 51, 51'
      },
      {
        Name: 'employee-avatar-bkgnd',
        Value: '0, 0, 0'
      },
      {
        Name: 'contextmenu_bkgnd',
        Value: '88, 87, 87'
      },
      {
        Name: 'greeting_main_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'greeting_secondary_txt',
        Value: '241, 241, 241'
      },
      {
        Name: 'attendance_table_away_clr',
        Value: '251, 6, 5'
      },
      {
        Name: 'dropdown_txt_active',
        Value: '0, 0, 0'
      }
    ]
  },
  {
    Name: 'Light',
    Colors: [
      {
        Name: 'body_bkgnd',
        Value: '195, 195, 195'
      },
      {
        Name: 'body_bkgnd2',
        Value: '239, 239, 239'
      },
      {
        Name: 'panel_bkgnd',
        Value: '255, 255, 255'
      },
      {
        Name: 'panel_txt',
        Value: '255, 255, 255'
      },
      {
        Name: 'active-row_txt',
        Value: '216, 238, 252'
      },
      {
        Name: 'stepper_bkgnd',
        Value: '255, 255, 255'
      },
      {
        Name: 'stepper_txt',
        Value: '255, 255, 255'
      },
      {
        Name: 'stepper-circle_txt',
        Value: '175, 175, 175'
      },
      {
        Name: 'stepper_passed_txt',
        Value: '255, 255, 255'
      },
      {
        Name: 'panel_border',
        Value: '255, 255, 255'
      },
      {
        Name: 'panel_modal_border',
        Value: '211, 211, 212'
      },
      {
        Name: 'overlay_bkgnd',
        Value: '56, 56, 56'
      },
      {
        Name: 'dropdown_bkgnd',
        Value: '255, 255, 255'
      },
      {
        Name: 'date_border',
        Value: '255, 255, 255'
      },
      {
        Name: 'progress_bkgnd',
        Value: '49, 179, 95'
      },
      {
        Name: 'table_absent_txt',
        Value: '255, 255, 255'
      },
      {
        Name: 'tablerow_odd_bkgnd',
        Value: '242, 242, 242'
      },
      {
        Name: 'tablerow_active_txt',
        Value: '51, 51, 55'
      },
      {
        Name: 'calendar_bkgnd',
        Value: '255, 255, 255'
      },
      {
        Name: 'calendar_txt',
        Value: '80, 80, 80'
      },
      {
        Name: 'weather_bkgnd',
        Value: '255, 255, 255'
      },
      {
        Name: 'weather_txt',
        Value: '103, 103, 103'
      },
      {
        Name: 'weather_current_txt',
        Value: '255, 255, 255'
      },
      {
        Name: 'dimensiontree_line_bkgnd',
        Value: '56, 56, 56'
      },
      {
        Name: 'dimensiontree_label_txt',
        Value: '63, 148, 191'
      },
      {
        Name: 'employee_details_txt',
        Value: '51, 51, 51'
      },
      {
        Name: 'employee-avatar-bkgnd',
        Value: '175, 175, 175'
      },
      {
        Name: 'contextmenu_bkgnd',
        Value: '255, 255, 255'
      },
      {
        Name: 'greeting_main_txt',
        Value: '103, 103, 103'
      },
      {
        Name: 'greeting_secondary_txt',
        Value: '50, 50, 50'
      },
      {
        Name: 'attendance_table_away_clr',
        Value: '200, 10, 0'
      },
      {
        Name: 'dropdown_txt_active',
        Value: '0, 0, 0'
      }
    ]
  }
];
