import React, { useContext, useState, useEffect, useCallback } from 'react';
import { fetchBrandColors as apiFetchBrandColors, IBrandColor } from '../../api/auth/brand-colors';
import { fetchThemeColors as apiFetchThemeColors, IThemeColors } from '../../api/auth/theme-colors';
import { useStorage } from '../useStorage';
import { useSession, AUTH_KEY } from '../useSession';
import { baseColors } from '../../utils/base-colors';
import { Feature, useFeatureFlagsContext } from '../feature-flags-context';
import {
  getSOWGradientColors,
  HEX,
  hex2rgb,
  getContrastTextColor as getContrastTextColorUtil,
  rgb2hex
} from '../../utils/color-utils';
import { BrandColor, BrandColorDefaults } from '../theme/brand-colors';
import { BRAND_COLORS_KEY, CURRENT_THEME_KEY, THEMES_KEY } from '../constants/storage-keys';
import { Control } from './control';
import { State } from './state';
import { Area } from './area';

export type Theme = 'Light' | 'Dark';
export type ThemeColorKey = { control: Control; area: Area; state: State };

interface IColors {
  brandColors: { [key: string]: HEX };
  themeColors: { [key: string]: { [key: string]: HEX } };
}

interface IThemeContext {
  currentTheme: string;
  colors: IColors;
  getBrandColor(key: string | BrandColor): HEX | undefined;
  getThemeColor(key: string | { control: Control; area: Area; state: State }): HEX | undefined;
  getContrastTextColor(bkColor: HEX | Partial<ThemeColorKey>, light?: HEX, dark?: HEX): HEX;
  setCurrentTheme(theme: Theme): void;
}

const ThemeContext = React.createContext<IThemeContext>(null as any);

const re = /([A-Z])/g;

const sowBkColors = new Set([
  'planview_bkmon_norm',
  'planview_bktue_norm',
  'planview_bkwed_norm',
  'planview_bkthu_norm',
  'planview_bkfri_norm',
  'planview_bksat_norm',
  'planview_bksun_norm'
]);

export function ThemeContextProvider(props: { children: JSX.Element }) {
  const { getItem, setItem, getFullKey } = useStorage();
  const { token } = useSession();
  const [colors, setColors] = useState<IColors>({
    brandColors: BrandColorDefaults,
    themeColors: { Light: {}, Dark: {} }
  });
  const [currentTheme, setCurrentTheme] = useState<string>(getItem(CURRENT_THEME_KEY) ?? 'Light');
  const { hasFeature } = useFeatureFlagsContext();

  const autoAuthenticated = hasFeature(Feature.AutoAuthenticated);

  useEffect(() => {
    // Sets `--app-..` colors

    const storageToken: { Authorization: string } | null = !autoAuthenticated
      ? getItem(AUTH_KEY)
      : null;

    if (!token && storageToken?.Authorization === '') {
      const docColors = document.documentElement.style.cssText
        .split(';')
        .filter(el => el.match(/--theme/));

      if (docColors.length) {
        docColors.forEach(color => {
          const clr = color.split(':')[0].replace(/ /g, '');
          document.documentElement.style.removeProperty(clr);
        });
      }

      return;
    }

    function setBaseColors() {
      const colors = baseColors.filter(thm => {
        return thm.Name.toLowerCase() === currentTheme.toLowerCase();
      });

      colors[0].Colors.forEach(clr => {
        document.documentElement.style.setProperty(`--app-${clr.Name}`, clr.Value);
      });
    }

    setBaseColors();
  }, [token, currentTheme]);

  useEffect(() => {
    // Sets `--brand-color-...` colors
    Object.keys(colors.brandColors).forEach(colorKey => {
      const rgb = hex2rgb(colors.brandColors[colorKey]);

      document.documentElement.style.setProperty(
        `--${colorKey.toString().replace(re, '-$1').toLowerCase()}`,
        `${rgb.r},${rgb.g},${rgb.b}`
      );
    });
  }, [colors.brandColors]);

  useEffect(() => {
    // Sets `--theme-` colors
    if (!token) {
      return;
    }

    const currentThemeColors = colors.themeColors[currentTheme];
    if (!currentThemeColors || !Object.keys(currentThemeColors).length) return;

    Object.keys(currentThemeColors).forEach(colorKey => {
      const rgb = hex2rgb(currentThemeColors[colorKey]);
      document.documentElement.style.setProperty(
        `--theme-${colorKey.toLowerCase()}`,
        `${rgb.r},${rgb.g},${rgb.b}`
      );

      if (sowBkColors.has(colorKey.toLowerCase())) {
        const [grStart, grEnd] = getSOWGradientColors(rgb);

        document.documentElement.style.setProperty(
          `--theme-${colorKey.toLowerCase()}_grstart`,
          `${grStart.r},${grStart.g},${grStart.b}`
        );

        document.documentElement.style.setProperty(
          `--theme-${colorKey.toLowerCase()}_grend`,
          `${grEnd.r},${grEnd.g},${grEnd.b}`
        );
      }
    });
  }, [token, colors.themeColors, currentTheme]);

  const updateBrandColors = useCallback((colors: IBrandColor[] | null) => {
    const brandColorsObject =
      colors?.reduce(
        (prev, curr) => {
          return { ...prev, [curr.Name]: curr.Value as HEX };
        },
        { ...BrandColorDefaults }
      ) ?? BrandColorDefaults;

    setColors(old => ({ ...old, brandColors: brandColorsObject }));
  }, []);

  const updateThemes = useCallback((themesColors: IThemeColors[] | null) => {
    const themes: { [key: string]: { [colorKey: string]: HEX } } = {};

    themesColors?.forEach(theme => {
      const initial: { [key: string]: HEX } = {};

      const themeColorsObject =
        theme.Colors?.reduce(
          (prev, curr) => {
            return { ...prev, [curr.Name]: curr.Value as HEX };
          },
          { ...initial }
        ) ?? initial;

      if (theme.IsCurrent) {
        setCurrentTheme(theme.Name);
      }

      themes[theme.Name] = themeColorsObject;
    });

    setColors(old => ({ ...old, themeColors: themes }));
  }, []);

  useEffect(() => {
    let ignore = false;

    async function fetchBrandColors() {
      const brandColors = getItem<IBrandColor[]>(BRAND_COLORS_KEY);
      updateBrandColors(brandColors);

      if (autoAuthenticated && brandColors?.length) return;

      try {
        const result = await apiFetchBrandColors();

        if (ignore) {
          return;
        }

        updateBrandColors(result.data);
        setItem(BRAND_COLORS_KEY, result.data);
      } catch (e) {}
    }

    async function fetchThemeColors() {
      const themeColors = getItem<IThemeColors[]>(THEMES_KEY);
      updateThemes(themeColors);

      if (autoAuthenticated && themeColors?.length) return;

      try {
        const res = await apiFetchThemeColors();

        if (ignore) {
          return;
        }

        updateThemes(res.data);

        setItem(THEMES_KEY, res.data);
        const current = res.data?.find(thm => thm.IsCurrent);
        if (current) {
          setItem(CURRENT_THEME_KEY, current.Name);
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (token) {
      fetchBrandColors();
      fetchThemeColors();
    }

    return () => {
      ignore = true;
    };
  }, [token]);

  useEffect(() => {
    if (!autoAuthenticated) return;

    window.addEventListener('storage', onStorageUpdated);
    return () => {
      window.removeEventListener('storage', onStorageUpdated);
    };
  }, []);

  function onStorageUpdated(e: StorageEvent) {
    if (!autoAuthenticated) return;

    if (e.key === getFullKey(BRAND_COLORS_KEY)) {
      const colors = getItem<IBrandColor[]>(BRAND_COLORS_KEY);
      updateBrandColors(colors ?? null);
    } else if (e.key === getFullKey(THEMES_KEY)) {
      const themes = getItem<IThemeColors[]>(THEMES_KEY);
      updateThemes(themes ?? null);
    } else if (e.key === getFullKey(CURRENT_THEME_KEY)) {
      const currentTheme = getItem<string>(CURRENT_THEME_KEY);
      if (currentTheme) {
        setCurrentTheme(currentTheme);
      }
    }
  }

  const getBrandColor = useCallback(
    (key: BrandColor): HEX | undefined => {
      const result = colors.brandColors[key];
      return result;
    },
    [colors.brandColors]
  );

  const getThemeColor = useCallback(
    (key: string | { control: Control; area: Area; state: State }): HEX | undefined => {
      const colorKey = typeof key === 'string' ? key : `${key.control}_${key.area}_${key.state}`;
      const result = colors.themeColors[currentTheme]?.[colorKey];
      return result;
    },
    [colors.themeColors, currentTheme]
  );

  const getContrastTextColor = useCallback(
    (bkColor: HEX | Partial<ThemeColorKey>, light?: HEX, dark?: HEX): HEX => {
      let bkColorHex: HEX = '#000000';
      if (typeof bkColor === 'string') {
        bkColorHex = bkColor;
      } else {
        const color = getThemeColor({
          control: bkColor.control ?? Control.Dialog,
          area: bkColor.area ?? Area.Background,
          state: bkColor.state ?? State.Normal
        });

        bkColorHex = color ?? (currentTheme == 'Dark' ? '#ffffff' : '#000000');
      }

      const lightHex: HEX =
        light ??
        getThemeColor({
          control:
            typeof bkColor !== 'string' && bkColor.control ? bkColor.control : Control.Dialog,
          area: Area.Text,
          state: State.ContrastLight
        }) ??
        '#505050';

      const darkHex =
        dark ??
        getThemeColor({
          control:
            typeof bkColor !== 'string' && bkColor.control ? bkColor.control : Control.Dialog,
          area: Area.Text,
          state: State.ContrastDark
        }) ??
        '#f5f5f5';

      const rgb = getContrastTextColorUtil(
        hex2rgb(bkColorHex),
        hex2rgb(lightHex),
        hex2rgb(darkHex)
      );
      const hex = rgb2hex(rgb);
      return hex;
    },
    [getThemeColor]
  );

  return (
    <ThemeContext.Provider
      value={{
        colors,
        currentTheme,
        setCurrentTheme,
        getBrandColor,
        getThemeColor,
        getContrastTextColor
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  const data = useContext(ThemeContext);

  if (!data) {
    throw new Error(`You should use useThemeContext only with ThemeContextProvider`);
  }

  return data;
}
