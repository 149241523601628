import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

/**
 * Provides a possibility to delay retrieving of data till the data is first time requested
 *
 * @param initialState initial data value
 * @param getter function that retrieves data, it gets invoked after the first request for getting data.
 * @param deps additional dependencies that should trigger getter function.
 *
 */
export function useLazyDataProvider<T>(
  initialState: T,
  getter: () => Promise<T>,
  deps?: React.DependencyList
) {
  const [requested, setRequested] = useState(false);
  const [retrieved, setRetrieved] = useState(false);
  const [data, setData] = useState(initialState);
  const timeoutRef = useRef(0);

  useEffect(() => {
    if (!requested) {
      // Wait till data get requested
      return;
    }

    let unmounted = false;

    getter()
      .then(result => {
        if (unmounted) return;
        setData(result);
      })
      .finally(() => {
        if (unmounted) return;
        setRetrieved(true);
      });

    return () => {
      unmounted = true;
    };
  }, deps?.concat(requested) ?? [requested]);

  const getData = useMemo(() => {
    const getData = () => {
      if (!requested && !timeoutRef.current) {
        // Set "requested" state on the first call for getting data
        // Wrap it into setTimeout so React won't complain in case when "getData" is called in a render phase
        timeoutRef.current = window.setTimeout(() => {
          timeoutRef.current = 0;
          setRequested(true);
        }, 0);
      }

      // Return current value
      return data;
    };

    return getData;
  }, [data]);

  useEffect(() => {
    return () => {
      // This is just a safety measure for the case when component gets unmounted
      // before timeout handler is called
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = 0;
      }
    };
  }, []);

  const updateData = useCallback((newData: T) => {
    setData(newData);
  }, []);

  return {
    retrieved,
    getData,
    setData: updateData
  };
}
