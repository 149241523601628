import React, { LabelHTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './label.module.css';

interface ILabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  value: string;
  bold?: boolean;
}

export function Label(props: ILabelProps) {
  const { value, bold, className, children, ...rest } = props;

  return (
    <label className={cx(styles.root, className ?? '')} {...rest}>
      <span className={children ? styles.withChildren : undefined}>
        {bold ? <b>{value}</b> : value}
      </span>
      {children}
    </label>
  );
}
