import React, {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useState,
  useId,
  LabelHTMLAttributes
} from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '../label';
import cx from 'classnames';
import styles from './input.module.css';

type InputType = 'text' | 'password' | 'number' | 'email' | 'tel' | 'file';

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: InputType;
  label?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  stretch?: boolean;
}

export const Input = forwardRef((props: IInputProps, ref?: ForwardedRef<HTMLInputElement>) => {
  const { type = 'text', label, labelProps, stretch, id, className, onChange, ...rest } = props;

  const [fileInputText, setFileInputText] = useState('');
  const { t } = useTranslation();

  const generated_id = useId();
  const inputId = id ?? generated_id;

  let input = (
    <input
      ref={ref}
      className={cx(styles.input, { [styles.stretch]: !!label || stretch }, className || '')}
      type={type}
      id={inputId}
      onChange={e => {
        if (type === 'file') {
          const files = e.currentTarget.files;
          let fileNames = '';
          if (files?.length) {
            for (let i = 0; i < files.length; i++) {
              if (fileNames) fileNames += ', ';
              fileNames += files[i].name;
            }
          }

          setFileInputText(fileNames);
        }

        onChange?.(e);
      }}
      {...rest}
    />
  );

  if (type === 'file') {
    // Wrap and associate file input with empty label so pointer events will be raised on the input itself
    input = (
      <label className={styles.fileInputWrapper} htmlFor={inputId}>
        {input}
        <div className={cx(styles.input, styles.fileInput, className)}>
          <span className={styles.fileSelectButton}>{`${t('fileUpload:chooseFile')}...`}</span>
          <span className={styles.fileName}>
            {fileInputText || props.placeholder || t('fileUpload:noFileChosen')}
          </span>
        </div>
      </label>
    );
  }

  return label ? (
    <Label value={label} htmlFor={inputId} bold {...labelProps}>
      {input}
    </Label>
  ) : (
    input
  );
});
