import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorFallback } from '@ui/error-fallback';

export function SentryErrorBoundary(props: { children: ReactNode }) {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
