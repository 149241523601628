import { Link, LinkType } from '@ui/link';
import React, { ReactNode } from 'react';
import styles from './tabs.module.css';

interface ITabItem {
  id: string;
  route: string;
  name: string;
  content: React.ReactNode;
}

interface ITabsProps {
  tabs: ITabItem[];
  activeId: string;
  disabled?: boolean;
  fallback?: ReactNode;

  onClick(id: ITabItem): void;
}

export function Tabs(props: ITabsProps) {
  const { tabs, onClick, activeId, disabled, fallback } = props;
  const tab = tabs.find(el => el.id === activeId);

  return (
    <div className={styles.root}>
      <nav>
        {tabs.map(item => (
          <Link
            cyId={item.name}
            key={item.id}
            disabled={disabled}
            id={item.id}
            activeId={activeId}
            text={item.name}
            type={LinkType.tab}
            onClick={() => onClick(item)}
          />
        ))}
      </nav>
      {tab ? (
        <div key={tab.id} cy-id={tab.name} className={styles.content} hidden={false}>
          {tab.content}
        </div>
      ) : (
        fallback
      )}
    </div>
  );
}
